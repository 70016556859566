import moment from "moment";
import { createReducer } from "utils/redux";
import { getTimeZone } from "utils/time";
import types from "./types";

const initialLoadingState = {
  page: false,
  postJob: false,
};
const initialDialogState = {
  profileDialog: false,
  companyDialog: false,
  postJobDialog: false
};
const initialFormState = {
  workType: { value: 0 },
  requiredGear: [],
  skills: [],
  description: '',
  address: '',
  unit: '',
  floor: '',
  locationInstruction: '',
  street: '',
  city: '',
  region: '',
  latitude: '',
  longitude: '',
  postalCode: '',
  isPrivate: false,
  tags: [],
  country: '',
  timezone: getTimeZone(),
  isRecurring: 'false',
  isFixedTerm: false,
  recurringType: 'Daily',
  weekDays: [],
  repeatEvery: 1,
  repeatType: 'day',
  monthDate: [],
  repeatDays: [],
  recurringEndDate: moment().add(1, 'months'),
  date: '',
  startTime: '',
  endTime: '',
  duration: {
    hours: 8,
    minutes: 30,
  },
  breakDuration: 30,
  breakType: 'unpaid',
  noOfWorkers: 1,
  siteManager: {},
  checkInManager: {},
  checkOutManager: {},
  announcement: [
    {
      title: '',
      description: '',
      isRecurring: 'false',
      time: moment(),
      repeatType: 'Once',
      before: 15,
      days: [],
    }
  ],
  templateName: ''
};
const initialState = {
  workTypeList: [],
  canTemplateSelected: false,
  gearList: [],
  skillList: [],
  loading: { ...initialLoadingState },
  dialog: { ...initialDialogState },
  form: { ...initialFormState },
  calculatedJobBill: {
    hourlyPay: 0
  }
};

const actionsMap = {
  [types.SET_POST_JOB_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      [key]: value
    };
  },
  [types.SET_WORK_TYPE_LIST]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      workTypeList: data
    };
  },
  [types.SET_GEAR_LIST]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      gearList: data
    };
  },
  [types.SET_SKILLS_LIST]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      skillList: data
    };
  },
  [types.SET_LOADING_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: value,
      },
    };
  },
  [types.SET_DIALOG_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      dialog: {
        ...state.dialog,
        [key]: value,
      },
    };
  },
  [types.SET_FORM_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      form: {
        ...state.form,
        [key]: value,
      },
    };
  },
  [types.RESET_FORM_DATA]: (state) => {
    return {
      ...state,
      canTemplateSelected: false,
      form: {
        ...initialFormState,
        announcement: [
          {
            title: '',
            description: '',
            isRecurring: 'false',
            time: moment(),
            repeatType: 'Once',
            before: 15,
            days: [],
          }
        ]
      },
    };
  },
  [types.SET_CALCULATED_JOB_AMOUNT]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      calculatedJobBill: data
    };
  },
};

export default createReducer(initialState, actionsMap);
