import ApolloClient from "utils/apollo";
import { actions as MetaActions } from "store/modules/meta";
import { logout } from "../login/actions";
import { getMe } from "../login/selectors";
import { getEmployerDetail } from "./selectors";
import updateProfileMutation, {
  updateManagerProfileImageMutation,
  updateManagerProfileMutation,
  uploadCustomFieldFileToS3Mutation,
  uploadEmployerFileToS3Mutation,
} from "./graphql/mutations/update-profile";
import addTimeBasePremiumQuery from "./graphql/mutations/add-time-base-premium";
import updateTimeBasePremiumQuery from "./graphql/mutations/update-time-base-premium";
import removeTimeBasePremiumQuery from "./graphql/mutations/remove-time-base-premium";
import GetEmployerQuery from "./graphql/queries/get-employer";
import GetTimeBasePremium from "./graphql/queries/get-time-base-premium-list";
import GetManagerQuery from "./graphql/queries/get-manager";
import types from "./types";
import i18n from "i18n";
import { getSelectedTimeBasePremium, getTimeBasePremiumForm, getTimeBasePremiumListData } from "./selectors";
import moment from "moment";
import GetWorkTypeRatesQuery from "./graphql/queries/get-work-type-rates";

const setLoadingState = key => value => ({
  type: types.SET_LOADING_STATE,
  payload: { key, value },
});
const setDialogState = key => value => ({
  type: types.SET_DIALOG_STATE,
  payload: { key, value },
});
const setIsUpdateProfileLoading = setLoadingState("updateProfile");
const setIsAddTimeBasePremiumLoading = setLoadingState("addTimeBasePremium");
const setIsChangeCompanyLoading = setLoadingState("changeCompany");
const setTimeBasePremiumListLoading = setLoadingState("timeBasePremiumList");
const setRemoveTimeBasePremiumLoading = setLoadingState("removeTimeBasePremium");
const setWorkTypeRatesListLoading = setLoadingState("isDynamicWorkTypeRateList");
export const setIsProfileDialog = setDialogState("profileDialog");
export const setIsCompanyDialog = setDialogState("companyDialog");
export const setIsBillingInfoDialog = setDialogState("billingInfoDialog");
export const setNewTimeBasePremiumDialog = setDialogState("newTimeBasePremium");
export const setRemoveTimeBasePremiumDialog = setDialogState("removeTimeBasePremium");

export const setTimeBasePremiumForm = ({ key, value } = {}) => ({
  type: types.SET_TIME_BASE_PREMIUM_FORM,
  payload: { key, value }
});
export const setEditTimeBasePremiumForm = (timebasePremium) => ({
  type: types.SET_EDIT_TIME_BASE_PREMIUM_FORM,
  payload: { data: timebasePremium }
});

export const clearTimeBasePremiumForm = () => ({
  type: types.CLEAR_TIME_BASE_PREMIUM_FORM,
});

const setTimeBasePremiumList = (data) => ({
  type: types.SET_TIME_BASE_PREMIUM_LIST,
  payload: {
    data
  }
});

export const setSelectedTimeBasePremium = (data) => ({
  type: types.SET_SELECTED_TIMEBASE_PREMIUM,
  payload: {
    data: data
  }
});


const setWorkTypeRatesList = (dynamicWorkTypes) => ({ type: types.SET_DYANMIC_WORK_TYPES_DATA, payload: { dynamicWorkTypes } })

export const fetchProfile = () => (dispatch, getState) => {
  const state = getState();
  const { accountType, accountId } = getMe(state);
  if (accountType === 'employermanager') {
    dispatch(fetchManagerProfile(accountId))
  } else if (accountType === 'employer') {
    dispatch(fetchEmployerProfile(accountId))
  }
}
export const fetchEmployerProfile = (id) => (dispatch, getState) => {
  return ApolloClient.query({
    query: GetEmployerQuery,
    variables: {
      id,
    },
  })
    .then(({ data, errors }) => {
      if ((!data || !data.employer) && errors.length > 0) {
        throw new Error(errors[0].message);
      }
      dispatch({
        type: types.SET_EMPLOYER_PROFILE_DATA,
        payload: {
          data: data.employer,
        },
      });
    })
    .catch(e => {
      dispatch(logout());
      console.log(e);
    });
};
export const fetchEmployerDetail = () => (dispatch, getState) => {
  const state = getState();
  const { employerId } = getMe(state);

  return ApolloClient.query({
    query: GetEmployerQuery,
    variables: {
      id: employerId,
    },
  })
    .then(({ data, errors }) => {
      if ((!data || !data.employer) && errors.length > 0) {
        throw new Error(errors[0].message);
      }

      dispatch({
        type: types.SET_EMPLOYER_DETAIL_DATA,
        payload: {
          data: data.employer,
        },
      });
    })
    .catch(e => {
      dispatch(logout());
      console.log(e);
    });
};
export const fetchManagerProfile = (id) => (dispatch, getState) => {
  const state = getState();
  const { accountId } = getMe(state);

  return ApolloClient.query({
    query: GetManagerQuery,
    variables: {
      id
    },
  })
    .then(({ data, errors }) => {
      if ((!data || !data.getManager) && errors.length > 0) {
        throw new Error(errors[0].message);
      }

      dispatch({
        type: types.SET_EMPLOYER_PROFILE_DATA,
        payload: {
          data: data.getManager,
        },
      });
    })
    .catch(e => {
      dispatch(logout())
      console.log(e);
    });
};

export const uploadEmployerProfileImage = file => (dispatch, getState) => {
  const { employerId, accountType, accountId } = getMe(getState());
  dispatch(setIsUpdateProfileLoading(true));
  return ApolloClient.mutate({
    mutation: (accountType === "employermanager" ? updateManagerProfileImageMutation : uploadEmployerFileToS3Mutation),
    variables:
      accountType === "employermanager" ? {
        id: accountId,
        file,
      } : {
        data: { employerId: employerId },
        isProfile: true,
        file,
      },
  })
    .then(({ errors }) => {
      if (errors && errors.length) throw new Error(errors[0].message)
      dispatch(fetchProfile());
      dispatch(MetaActions.successToast(i18n.t("toast_success_managers_profile_photo_update")));
      dispatch(setIsUpdateProfileLoading(false));
    })
    .catch(e => {
      dispatch(setIsUpdateProfileLoading(false));
      dispatch(MetaActions.errorToast(i18n.t("toast_error_managers_profile_photo_update", { error: e })));
      return e;
    });
};

export const uploadCustomFieldFile = (file, callback) => (dispatch, getState) => {
  const { employerId } = getMe(getState());

  return ApolloClient.mutate({
    mutation: uploadCustomFieldFileToS3Mutation,
    variables: {
      employerId: employerId,
      file,
    },
  })
    .then((res) => {
      dispatch(MetaActions.successToast(i18n.t("toast_success_custom_field_update")));
      callback(res?.data?.employerCustomFileUpload);
    })
    .catch(e => {
      dispatch(MetaActions.errorToast(i18n.t("toast_error_custom_field_update", { error: e })));
    });
};

export const uploadCompanyLogoImage = file => (dispatch, getState) => {
  const { employerId } = getMe(getState());
  dispatch(setIsUpdateProfileLoading(true));
  return ApolloClient.mutate({
    mutation: uploadEmployerFileToS3Mutation,
    variables: {
      data: { employerId: employerId },
      isProfile: false,
      file,
    },
  })
    .then(({ errors }) => {
      if (errors && errors.length) throw new Error(errors[0].message)
      dispatch(MetaActions.successToast(i18n.t("toast_success_profile_logo_update")));
      dispatch(fetchEmployerDetail());
      dispatch(setIsUpdateProfileLoading(false));
    })
    .catch(e => {
      dispatch(setIsUpdateProfileLoading(false));
      dispatch(MetaActions.errorToast(i18n.t("toast_error_profile_logo_update", { error: e })));
      return e;
    });
};

export const updateProfile = (data, callback) => (dispatch, getState) => {
  const { employerId } = getMe(getState());
  const { accountType, accountId } = getMe(getState());
  dispatch(setIsUpdateProfileLoading(true));

  return ApolloClient.mutate({
    mutation:
      accountType === "employermanager" ? updateManagerProfileMutation : updateProfileMutation,
    variables: {
      data: {
        ...data,
        id: accountType === "employermanager" ? accountId : employerId,
      },
    },
  })
    .then(() => {
      dispatch(fetchProfile());
      if (accountType === "employer") {
        dispatch(fetchEmployerDetail());
      }
      dispatch(MetaActions.successToast(i18n.t("toast_success_profile_update")));
      dispatch(setIsUpdateProfileLoading(false));
      dispatch(setIsProfileDialog(false));
      dispatch(setIsCompanyDialog(false));
      dispatch(setIsBillingInfoDialog(false));
      callback && callback();
    })
    .catch(e => {
      dispatch(setIsUpdateProfileLoading(false));
      dispatch(MetaActions.errorToast(i18n.t("toast_error_profile_update", { error: e })));
    });
};

export const changeCompany = defaultEmployerId => (dispatch, getState) => {
  const { accountId } = getMe(getState());
  dispatch(setIsChangeCompanyLoading(true));
  return ApolloClient.mutate({
    mutation: updateManagerProfileMutation,
    variables: {
      data: {
        defaultEmployerId,
        id: accountId,
      },
    },
  })
    .then(() => {
      dispatch(MetaActions.successToast(`Company has been changed.`));
      dispatch(setIsChangeCompanyLoading(false));
      window.location.replace("/");
    })
    .catch(e => {
      dispatch(setIsChangeCompanyLoading(false));
      dispatch(MetaActions.errorToast(`Changing company has failed. ${e}`));
    });
};

export const fetchTimeBasePremiumList = () => (dispatch, getState) => {
  const { employerId } = getMe(getState());
  dispatch(setTimeBasePremiumListLoading(true));
  return ApolloClient.query({
    query: GetTimeBasePremium,
    variables: { employerId: employerId }
  })
    .then(({ data, errors }) => {
      if (data && data.getTimeBasePremium) {
        dispatch(setTimeBasePremiumList(data.getTimeBasePremium));
      } else if (errors && errors.length) {
        dispatch(MetaActions.errorToast(errors[0].message));
      }
      dispatch(setTimeBasePremiumListLoading(false));
    })
    .catch((e) => {
      dispatch(MetaActions.errorToast(e));
      dispatch(setTimeBasePremiumListLoading(false));
    });
};

export const addTimeBasePremium = () => (dispatch, getState) => {
  const { employerId } = getMe(getState());
  const data = getTimeBasePremiumForm(getState());
  const timeBasePremium = getSelectedTimeBasePremium(getState());
  const isEdit = Boolean(timeBasePremium && timeBasePremium.id);
  let query = addTimeBasePremiumQuery;
  if (isEdit) {
    query = updateTimeBasePremiumQuery;
  }
  dispatch(setIsAddTimeBasePremiumLoading(true));
  return ApolloClient.mutate({
    mutation: query,
    variables: {
      data: {
        ...(isEdit ? { id: timeBasePremium.id } : {}),
        ...data,
        startTime: moment(data.startTime).set("second", 0).format("HH:mm:ss"),
        endTime: moment(data.endTime).set("second", 0).format("HH:mm:ss"),
        employerId,
      },
    },
  })
    .then(({ errors }) => {
      if (errors && errors.length) {
        dispatch(setIsAddTimeBasePremiumLoading(false));
        dispatch(MetaActions.errorToast(errors[0].message));
      } else {
        if (isEdit) {
          dispatch(setSelectedTimeBasePremium(null));
          dispatch(MetaActions.successToast(i18n.t("toast_success_time_base_premium_updated")));
        } else
          dispatch(MetaActions.successToast(i18n.t("toast_success_time_base_premium_added")));
        dispatch(setIsAddTimeBasePremiumLoading(false));
        dispatch(setNewTimeBasePremiumDialog(false));
        dispatch(clearTimeBasePremiumForm());
        dispatch(fetchTimeBasePremiumList());
      }
    })
    .catch(e => {
      console.log(e?.message)
      dispatch(setIsAddTimeBasePremiumLoading(false));
      dispatch(MetaActions.errorToast(e?.message));
    });
};
export const removeTimeBasePremium = () => (dispatch, getState) => {
  const timeBasePremium = getSelectedTimeBasePremium(getState());

  dispatch(setRemoveTimeBasePremiumLoading(true));
  return ApolloClient.mutate({
    mutation: removeTimeBasePremiumQuery,
    variables: { removeTimeBasePremiumId: timeBasePremium.id }
  })
    .then(({ errors }) => {
      if (errors && errors.length) throw new Error(errors[0].message);
      dispatch(MetaActions.successToast(i18n.t("toast_success_time_base_premium_deleted")));
      dispatch(setSelectedTimeBasePremium(null));
      dispatch(setRemoveTimeBasePremiumLoading(false));
      dispatch(setRemoveTimeBasePremiumDialog(false));
      dispatch(fetchTimeBasePremiumList());
    })
    .catch(e => {
      console.log(e)
      dispatch(setRemoveTimeBasePremiumLoading(false));
      dispatch(MetaActions.errorToast(e));
    });
};
export const fetchDynamicWorkTypeRate = () => (dispatch, getState) => {
  const { employer } = getEmployerDetail(getState());
  dispatch(setWorkTypeRatesListLoading(true));
  return ApolloClient.query({
    query: GetWorkTypeRatesQuery,
    variables: { employerId: employer?.id }
  })
    .then(({ data, errors }) => {
      if (data && data.getWorkTypeRate) {
        dispatch(setWorkTypeRatesList(data.getWorkTypeRate));
      } else if (errors && errors.length) {
        dispatch(MetaActions.errorToast(errors[0].length));
      }
      dispatch(setWorkTypeRatesListLoading(false));
    })
    .catch((e) => {
      dispatch(MetaActions.errorToast(i18n.t("dynamic_work_type_error")));
      console.log(e)
      dispatch(setWorkTypeRatesListLoading(false));
    });
};
