import Dialog from 'components/Dialog/Dialog'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as ProfileActions, selectors as ProfileSelectors } from 'store/modules/profile'
import MobileTimePicker from "components/DateTimePicker/MobileTimePicker";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CardContent, Checkbox, DialogActions, FormControlLabel, FormGroup, Grid, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { weekDaysList } from 'utils/constant';
import Card from 'components/Card/Card';
import { makeStyles } from '@mui/styles';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import { formatMoney } from 'utils/stringUtils';
import { find, isEqual } from 'lodash';
import moment from "moment";
import Button from 'components/Button/Button';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
    weekDays: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    days: {
        padding: '14px 0',
        border: '1px solid',
        borderRadius: '100px',
        width: '50px',
        height: '50px',
        textAlign: 'center',
        marginRight: '10px',
        fontWeight: '400',
        borderColor: '#b2b2c4',
        color: '#848499',
        cursor: 'pointer',
        marginBottom: '10px'
    },
    selectedDays: {
        borderColor: '#00c8a9 !important',
        color: '#00c8a9 !important',
        background: '#FFF',
        fontWeight: '400'
    },
    disabledDay: {
        cursor: "not-allowed",
        pointerEvents: "none",
        touchAction: "none",
        color: "#84849985"
    },
    card: {
        boxShadow: "none",
        marginBottom: `${theme.spacing(1)} !important`,
    },
    jobTypeCard: {
        display: "flex",
        flexWrap: "wrap",
        gap: theme.spacing(3),
        "& $card": {
            minWidth: 320,
            width: "calc(50% - 24px)",
            flex: 1,
            cursor: "pointer",
            background: "transparent",
            transition: "1s all",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            "& .MuiCardContent-root": {
                padding: `${theme.spacing(1.8)} !important`
            }
        },
        "& .MuiFormControlLabel-label": {
            color: theme.palette.text.primary,
        },
        "& .MuiCheckbox-root": {
            paddingTop: 0,
            paddingBottom: 0,
        },
        "& .desc": {
            fontSize: 10,
            marginLeft: theme.spacing(3.6)
        }
    },
    footer: {
        marginTop: theme.spacing(2)
    }
}));

export default function AddTimeBasePremiumDialog({ ...props }) {
    const classes = useStyles();
    const open = useSelector(ProfileSelectors.GetNewTimeBasePremiumDialog);
    const formData = useSelector(ProfileSelectors.getTimeBasePremiumForm);
    const canPost = useSelector(ProfileSelectors.canAddUpdateTimeBasePremium);
    const isLoading = useSelector(ProfileSelectors.getIsAddTimeBasePremiumListLoading);
    const dispatch = useDispatch();
    const timeBasePremium = useSelector(ProfileSelectors.getSelectedTimeBasePremium);
    const { t } = useTranslation();
    useEffect(() => {
        if (open && !timeBasePremium) {
            dispatch(ProfileActions.setTimeBasePremiumForm({ key: "startTime", value: moment() }));
            dispatch(ProfileActions.setTimeBasePremiumForm({ key: "endTime", value: moment().add(4, "hours") }));
        };
    }, [open, timeBasePremium]);

    const onCancel = () => {
        dispatch(ProfileActions.setNewTimeBasePremiumDialog(false));
        dispatch(ProfileActions.clearTimeBasePremiumForm());
    };

    const handleChange = (value, key) => {
        dispatch(ProfileActions.setTimeBasePremiumForm({ key, value }));
    };

    const handleChangeWeekDays = (e) => {
        if (find(formData.days, n => isEqual(n, e))) {
            dispatch(ProfileActions.setTimeBasePremiumForm({ key: "days", value: formData.days.filter(n => n != e) }));
        } else {
            dispatch(ProfileActions.setTimeBasePremiumForm({ key: "days", value: [...formData.days, e] }));
        }
    };

    const onConfirm = () => {
        dispatch(ProfileActions.addTimeBasePremium());
    };

    return (
        <Dialog
            open={open}
            title={t(timeBasePremium ? "time_base_premium_update_dialog_title" : "time_base_premium_add_dialog_title")}
            onClose={onCancel}
            onCancel={onCancel}
            alertType={'form'}
            hideActions
            size="sm"
            modalType="scrollable"
        >
            <LocalizationProvider locale={i18n.language} dateAdapter={AdapterMoment}  localeText={{cancelButtonLabel:t("common_labels_cancel"),okButtonLabel:t("common_labels_ok")}}>
                <div style={{ marginTop: 24 }}>
                    <Grid container spacing={2} textAlign="left">
                        <Grid item md={12} sm={12} xs={12}>
                            <label className={classes.jobTypeCard}>
                                <Card style={{ margin: 0 }} className={classes.card} >
                                    <CardContent>
                                        <FormControlLabel control={
                                            <Checkbox
                                                color="secondary"
                                                onChange={({ target: { checked } }) => handleChange(checked, "isForFullShift")}
                                                checked={formData.isForFullShift} />
                                        } label={t("time_base_premium_full_shift_label")} />
                                        {/* <Typography className="desc" color="text.secondary">
                                            {"When you create full shift time base premium"}
                                        </Typography> */}
                                    </CardContent>
                                </Card>
                            </label>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <MobileTimePicker
                                label={`${t("time_base_premium_start_time_label")}`}
                                value={formData.startTime}
                                onChange={(e) => handleChange(moment(e), "startTime")}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <MobileTimePicker
                                label={`${t("time_base_premium_end_time_label")}`}
                                value={formData.endTime}
                                minTime={moment(formData.startTime).add(1, "hour")}
                                onChange={(e) => handleChange(moment(e), "endTime")}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </Grid>
                        <Grid item md={6} xs={12} sm={6} style={{ marginTop: "8px" }}>
                            <TextField
                                select
                                fullWidth
                                label={t("time_base_premium_type_label")}
                                name="type"
                                value={formData.type}
                                onChange={({ target: { name, value } }) => handleChange(value, name)}
                                margin="none"
                            >
                                <MenuItem value="PERCENTAGE">{t("time_base_premium_type_1")}</MenuItem>
                                <MenuItem value="VALUE">{t("time_base_premium_type_2")}</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item md={6} xs={12} sm={6} style={{ marginTop: "8px" }}>
                            <CurrencyInput
                                label="Value"
                                type={formData.type}
                                minValue={0}
                                minValueHelperText={t("time_base_premium_value_min_help_text", { value: formData.type === "PERCENTAGE" ? "0%" : "$0" })}
                                maxValue={formData.type === "PERCENTAGE" ? 100 : 1000}
                                maxValueHelperText={t("time_base_premium_value_max_help_text", { value: formData.type === "PERCENTAGE" ? "100%" : `$1000` })}
                                value={formData.value}
                                onChange={(value) => handleChange(value, "value")}
                            />
                        </Grid>
                        <Grid item md={12} xs={12} sm={12} style={{ marginTop: "8px" }} display="flex">
                            {weekDaysList.map(n => <div key={n.value} onClick={() => handleChangeWeekDays(n.value)} className={`${classes.days} ${formData.days.indexOf(n.value) > -1 ? classes.selectedDays : ''}`}>{n.label}</div>)}
                        </Grid>
                    </Grid>
                </div>
            </LocalizationProvider>
            <DialogActions className={classes.footer}>
                <Button
                    label={t("common_labels_close")}
                    onClick={onCancel}
                    disabled={isLoading}
                    color="transparent" />
                <Button
                    label={t(timeBasePremium ? "common_labels_update" : "common_labels_add")}
                    onClick={onConfirm}
                    isLoading={isLoading}
                    color="secondary"
                    disabled={!canPost || isLoading}
                />
            </DialogActions>
        </Dialog >
    )
};
