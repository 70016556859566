import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import Button from "components/Button/Button";
import MainContent from "components/Containers/MainContent";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";
import { convertUTCToTimezoneWithOffset } from "utils/time";
import { actions as ManagerActions, selectors as ManagerSelectors } from "store/modules/managers";
import AddManagerDialog from "./components/AddManagerDialog";
import ManagerMenu from "./components/ManagerMenu";
import { useTranslation } from "react-i18next";

function Managers() {
  const dispatch = useDispatch();
  const managerData = useSelector(ManagerSelectors.getManagersListAllData)
  const isOpenAddManagerDialog = useSelector(ManagerSelectors.getManagersDialogState)
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(ManagerActions.fetchManagers(true));
  }, []);
  const showDialog = () => {
    dispatch(ManagerActions.setIsAddManagerDialogState(true))
  }
  const hideDialog = () => {
    dispatch(ManagerActions.setIsAddManagerDialogState(false))
  }

  const saveManager = (data) => {
    if (data) {
      data.mobilePhone = data.mobilePhone.toString()
      dispatch(ManagerActions.addManager(data))
    }
  }

  const columns = [
    {
      Header: "",
      id: "signedProfileImageUrl",
      disableSortBy: true,
      accessor: ({ manager }) => {
        return manager.signedProfileImageUrl;
      },
      Cell: ({ row: { original: { manager } } }) => (
        <>
          <Avatar alt={manager.firstName} src={manager.signedProfileImageUrl} />
        </>
      ),
    },
    {
      Header: t("common_table_column_firstName"),
      id: "firstName",
      accessor: ({ manager }) => {
        if (!manager) {
          return '--';
        }
        return manager.firstName;
      },
    },
    {
      Header: t("common_table_column_lastName"),
      id: "lastName",
      accessor: ({ manager }) => {
        if (!manager) {
          return '--';
        }
        return manager.lastName;
      },
    },
    {
      Header: t("common_table_column_email"),
      id: "email",
      accessor: ({ manager }) => {
        if (!manager) {
          return '--';
        }
        return manager.email;
      },
    },
    {
      Header: t("common_table_column_mobilePhone"),
      id: "mobilePhone",
      accessor: ({ manager }) => {
        if (!manager) {
          return '--';
        }
        return manager.mobilePhone;
      },
    },
    {
      Header: t("common_table_column_status"),
      id: "isActive",
      accessor: ({ isActive }) => {
        return isActive ? t('common_labels_enable') : t('common_labels_disable');
      },
    },
    {
      Header: t("common_table_column_createdAt"),
      id: "createdAt",
      accessor: ({ manager }) => {
        return manager.createdAt;
      },
      Cell: ({ row: { original: { manager } } }) => (
        convertUTCToTimezoneWithOffset(manager.createdAt,null,"MMM Do, YYYY h:mm a")
      ),
    },
    {
      Header: () => {
        return (
          <div>
            <Button
              label={t("common_labels_add")}
              size="small"
              icon={<Add />}
              alignIcon="left"
              onClick={showDialog}
            />
          </div>
        )
      },
      id: "id",
      accessor: "id",
      disableFilters: true,
      disableSortBy: true,
      styles: {
        textAlign: 'right'
      },
      Cell: ({ row: { original } }) => {
        return (
          <>
            <ManagerMenu
              data={original}
            />
          </>
        )
      }
    },
  ];


  return (
    <>
      <MainContent>
        <ReactTableSimple
          columns={columns}
          data={managerData}
          initialSort={[{ id: 'createdAt', desc: true }]}
        />

      </MainContent>
      <AddManagerDialog
        open={isOpenAddManagerDialog}
        onClose={hideDialog}
        onCancel={hideDialog}
        onConfirm={saveManager}
      />
    </>
  );
}

export default Managers;
