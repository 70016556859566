import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { MoreVert, Delete, Edit, ContentCopy } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import Dialog from "components/Dialog/Dialog";
import { actions as SettingsActions } from "store/modules/settings";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ spacing, typography }) => ({
  icon: {
    fontSize: typography.pxToRem(20),
    marginRight: spacing(2),
  },
}));

function ManagerMenu({ data, history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showRemoveManagerDialog, setShowRemoveManagerDialog] = useState(false);
  const { t } = useTranslation();

  const id = data.id;
  const menuId = `action-menu-${data.id}`;
  const openMenu = e => {
    setAnchorEl(e.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const hideRemoveManager = () => setShowRemoveManagerDialog(false);

  const showRemoveDialog = () => {
    closeMenu();
    setShowRemoveManagerDialog(true)
  }

  const handleRemoveManager = () => {
    dispatch(SettingsActions.removeJobTemplate(data.id))
    setShowRemoveManagerDialog(false)
  }

  const showDisableDialog = () => {
    closeMenu();
    history.push(`/edit-template?id=${id}&type=edit`);
  }

  const showDuplicateTemplate = () => {
    closeMenu();
    history.push(`/duplicate-template?id=${id}&type=duplicate`);
  }

  return (
    <>
      <IconButton aria-controls={menuId} aria-haspopup="true" onClick={openMenu} disableRipple>
        <MoreVert color="primary" />
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}>
        <MenuItem onClick={showDisableDialog}>
          <Edit className={classes.icon} color="action" />
          {t("common_labels_edit")}
        </MenuItem>
        <MenuItem onClick={showDuplicateTemplate}>
          <ContentCopy className={classes.icon} color="action" />
          {t("common_labels_duplicate")}
        </MenuItem>
        <MenuItem onClick={showRemoveDialog}>
          <Delete className={classes.icon} color="action" />
          {t("common_labels_delete")}
        </MenuItem>
      </Menu>
      <Dialog
        open={showRemoveManagerDialog}
        title={t("common_labels_are_you_sure")}
        onClose={hideRemoveManager}
        onConfirm={handleRemoveManager}
        onCancel={hideRemoveManager}
        alertType="warning"
        icon="remove_circle"
      />
    </>
  );
}

export default ManagerMenu;
