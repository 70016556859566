import {gql} from "@apollo/client";

export default gql`
  query getEmployer(
    $id: ID!
  ) {
    employer(
      id: $id
    ) {
      id
      companyName
      title
      firstName
      lastName
      email
      timezone
      countryCode
      mobilePhone
      businessPhone
      street
      city
      region
      country
      postalCode
      isActive
      isApproved
      createdAt
      approvedAt
      allowPreferredOnly
      isMassCheckinEnabled
      invoicingJDENumber
      companyJDENumber
      isAutoConfirmEnabled
      positionCount
      jobCount
      rating
      fillRate
      hoursWorkedCount
      billingRate
      createdAtUtc
      approvedAtUtc
      jobNotification
      signedProfileImageUrl
      signedLogoImageUrl
      workEmail
      billingFirstName
      billingLastName
      billingJobRole
      billingEmail
      canPostPrivateJob
      employerNote{
        note
        adminId
        adminName
        adminEmail
        createdAt
      }
      allowVaccinatedOnly
      customFieldValues {
        id
        employerId
        customFieldId
        value
        signedCustomFileUrl
        createdAt
        updatedAt
        customField {
          id
          name
          slug
          type
          isRequired
          isForAll
          createdBy
          updatedBy
          deletedBy
          createdAt
          updatedAt
          getCustomFieldsOptions {
            id
            customFieldId
            label
            value
            createdAt
            updatedAt
          }
          getEmployerCustomFieldsValues {
            id
            employerId
            customFieldId
            value
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
