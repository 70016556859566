import React, { useRef, useState } from "react";
import Datetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import isEmpty from "lodash/isEmpty";
import Dialog from "components/Dialog/Dialog";
import { convertLocalToUTC, convertUTCToTimezone } from "utils/time";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import { useTranslation } from "react-i18next";

function CheckInDialog(props) {
  const { open, onClose, onCancel, position } = props;
  const dispatch = useDispatch();
  const checkinPicker = useRef(null);
  const companyName = useSelector(JobDetailSelectors.getCompanyName);
  const jobStart = useSelector(JobDetailSelectors.getJobStartUtc);
  const jobTimezone = useSelector(JobDetailSelectors.getJobTimezone);
  const [checkinValue, setCheckinValue] = useState(convertUTCToTimezone((position.startShiftUtc || jobStart), jobTimezone));
  const updatedPosition = useSelector(JobDetailSelectors.getUpdatedPosition(position.id));
  const { t } = useTranslation();

  if (isEmpty(updatedPosition)) {
    return null;
  }
  const onChange = value => {
    setCheckinValue(moment(value));
  };

  const isValidDate = currentDate => {
    return moment(currentDate).isSameOrAfter(moment(jobStart).format("YYYY-MM-DD"));
  };

  const handleCheckin = () => {
    dispatch(JobDetailActions.updatePositionData("startShiftUtc")(position.id, convertLocalToUTC(checkinValue)));
    dispatch(JobDetailActions.checkinWorker(position.id, checkinValue));
  };

  return (
    <Dialog
      open={open}
      title={t("page_job_details_dialog_check_in_title", { name: position.fullName })}
      description={null}
      onClose={onClose}
      onConfirm={handleCheckin}
      onCancel={onCancel}
      alertType="question"
      disableBackdropClick
      icon="alarm">
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12}>
          <Typography align="center" variant="body1">
            {t("page_job_details_dialog_check_in_desc", { name: position.fullName, companyName })}
          </Typography>
        </Grid>
        <Grid container item xs={12} style={{ height: 50 }} justifyContent="center">
          <Datetime
            viewMode="time"
            input
            id="start"
            dateFormat="MMM Do"
            timeFormat="h:mm a"
            // timeConstraints={{ minutes: { step: 15 } }}
            onChange={onChange}
            style={{ position: "absolute" }}
            value={checkinValue}
            isValidDate={isValidDate}
            inputProps={{
              placeholder: t("common_labels_set_all"),
              ref: checkinPicker,
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}

CheckInDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  position: PropTypes.object,
  // onChange: PropTypes.func.isRequired,
  // value: PropTypes.string.isRequired,
};

export default CheckInDialog;
