import ApolloClient from "utils/apollo";
import { flattenGraphQLArray } from "utils/graphql";
import * as pagination from "utils/graphql-pagination";
import { actions as MetaActions } from "store/modules/meta";
import { getMe } from "../login/selectors";
import { paginationSettings } from "./constants";
import RemoveBanWorkerMutation from "./graphql/mutations/remove-ban-worker";
import FetchBannedWorkerQuery from "./graphql/queries/workers-bans";
import {
  getBannedListData,
  getBannedWorkersPaginationData,
  getPreferredWorkersPaginationData,
} from "./selectors";
import types from "./types";
import i18n from "i18n";

export const fetchBannedWorkers = pageIndex => (dispatch, getState) => {
  const state = getState();
  const { employerId } = getMe(state);
  const pageInfo = getBannedWorkersPaginationData(state);
  const pagingVars = dispatch(pagination.pagingVarsFactory(paginationSettings.bannedWorker)(pageInfo, pageIndex));
  let extraVars = { bannedBy: employerId };

  return ApolloClient.query({
    query: FetchBannedWorkerQuery,
    variables: {
      ...pagingVars,
      ...extraVars,
      employerId: employerId,
      order: getBannedListData({ part: "sort" })(state).map(sort => {
        return {
          field: sort.id,
          direction: sort.desc ? "DESC" : "ASC",
        };
      }),
      like: getBannedListData({ part: "filter" })(state).map(filter => {
        return {
          field: filter.id,
          value: filter.value,
        };
      }),
    },
  })
    .then(({ data: raw }) => {
      const { paginationData, data } = {
        paginationData: {
          ...raw.workers.pageInfo,
          totalCount: raw.workers.totalCount,
        },
        data: flattenGraphQLArray(raw.workers),
      }

      dispatch({
        type: types.SET_BANNED_WORKER_LIST_DATA,
        payload: {
          data,
        },
      });
      dispatch(pagination.updatePageInfo(paginationSettings.bannedWorker, paginationData));
      dispatch(pagination.doneLoading(paginationSettings.bannedWorker));
    })
    .catch(() => {
      dispatch(pagination.doneLoading(paginationSettings.bannedWorker));
    });
};

export const changeBannedWorkersSort = (sort) => dispatch => {
  dispatch({
    type: types.UPDATE_BANNED_WORKER_PAGE_SORT,
    payload: { sort },
  });
};

export const changeBannedWorkersFilter = (filter) => dispatch => {
  dispatch({
    type: types.UPDATE_BANNED_WORKER_PAGE_FILTER,
    payload: { filter },
  });
};

export const removeBanWorker = (workerId, type) => (dispatch, getState) => {
  const { employerId } = getMe(getState());
  return ApolloClient.mutate({
    mutation: RemoveBanWorkerMutation,
    variables: {
      data: {
        workerId,
        employerId: employerId,
      },
    },
  })
    .then(() => {
      if(type === 'ban') {
        dispatch(fetchBannedWorkers(0));
        dispatch(MetaActions.successToast(i18n.t("toast_success_worker_remove_from_ban")));
      } else {
        dispatch(fetchPreferredWorkers(0));
        dispatch(MetaActions.successToast(i18n.t("toast_success_worker_remove_from_ban_type",{type})));
      }
    })
    .catch(e => {
      dispatch(MetaActions.errorToast(`${e.message}`));
    });
};

// Preferred workers
export const fetchPreferredWorkers = pageIndex => (dispatch, getState) => {
  const state = getState();
  const { employerId } = getMe(state);
  const pageInfo = getPreferredWorkersPaginationData(state);
  const pagingVars = dispatch(pagination.pagingVarsFactory(paginationSettings.preferredWorker)(pageInfo, pageIndex));
  let extraVars = { preferredBy: employerId };

  return ApolloClient.query({
    query: FetchBannedWorkerQuery,
    variables: {
      ...pagingVars,
      ...extraVars,
      employerId: employerId,
      order: getBannedListData({ part: "sort" })(state).map(sort => {
        return {
          field: sort.id,
          direction: sort.desc ? "DESC" : "ASC",
        };
      }),
      like: getBannedListData({ part: "filter" })(state).map(filter => {
        return {
          field: filter.id,
          value: filter.value,
        };
      }),
    },
  })
    .then(({ data: raw }) => {
      const { paginationData, data } = {
        paginationData: {
          ...raw.workers.pageInfo,
          totalCount: raw.workers.totalCount,
        },
        data: flattenGraphQLArray(raw.workers),
      }
      dispatch({
        type: types.SET_PREFERRED_WORKER_LIST_DATA,
        payload: {
          data,
        },
      });
      dispatch(pagination.updatePageInfo(paginationSettings.preferredWorker, paginationData));
      dispatch(pagination.doneLoading(paginationSettings.preferredWorker));
    })
    .catch(() => {
      dispatch(pagination.doneLoading(paginationSettings.preferredWorker));
    });
};

export const changePreferredWorkersSort = (sort) => dispatch => {
  dispatch({
    type: types.UPDATE_PREFERRED_WORKER_PAGE_SORT,
    payload: { sort },
  });
};

export const changePreferredWorkersFilter = (filter) => dispatch => {
  dispatch({
    type: types.UPDATE_PREFERRED_WORKER_PAGE_FILTER,
    payload: { filter },
  });
};