/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react';
import { KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, Typography, MobileStepper, AppBar, Toolbar, CircularProgress, Grow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DocumentViewer from 'components/DocumentViewer/DocumentViewer';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions as ReviewDocActions, selectors as ReviewDocSelectors } from "store/modules/review-documents";
import { useSelector } from 'react-redux';
import { useBrowserDetection } from 'utils/browserDetection';

const useStyles = makeStyles(theme => ({
    bottomStepper: {
        backgroundColor: theme.palette.common.white,
        justifyContent: "flex-end",
        boxShadow: "0px 11px 13px #000000"
    },
    box: {
        width: "100%",
        height: "calc(100vh - 93px)",
        position: "relative"
    },
    loadingContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        background: "white",
        zIndex: 999
    },
    checkmark: {
        borderRadius: 200,
        height: 200,
        width: 200,
        background: "#F8FAF5",
        margin: "0 auto",
        "& i": {
            color: "#9ABC66",
            fontSize: "100px",
            lineHeight: "200px",
            marginLeft: - 15
        }
    },
    card: {
        background: "white",
        padding: 60,
        borderRadius: 4,
        boxShadow: "0 2px 3px #C8D0D8",
        display: " inline-block",
        margin: "0 auto",
    },
    container: {
        display: "flex",
        textAlign: "center",
        height: "100vh",
        width: "100vw",
        alignItems: "center"
    }
}));

export default function ReviewDocuments(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [isError, setIsError] = useState(false);
    const [isLoadingDoc, setIsLoadingDoc] = useState(false);
    const dispatch = useDispatch();
    const documents = useSelector(ReviewDocSelectors.getDocumentsData);
    const isAccepting = useSelector(ReviewDocSelectors.getIsAccepting);
    const isAccepted = useSelector(ReviewDocSelectors.getIsAccepted);
    const isLoading = useSelector(ReviewDocSelectors.getIsPageLoading);
    const maxSteps = documents?.length;
    const params = new URLSearchParams(props.location.search);
    const token = params.get("token");
    const positionId = params.get("positionId");
    const payload = token ? { token } : { positionId: +positionId };
    const userAgent = useBrowserDetection(token ? token : +positionId);

    useEffect(() => {
        setIsError(false);
        dispatch(ReviewDocActions.fetchDocuments(payload)).then(res => {
            if (!res.data || res.errors) setIsError(true);
        }).catch(er => {
            setIsError(true);
        });
    }, []);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleFinish = () => {
        dispatch(ReviewDocActions.acceptDocuments(payload))
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            {isAccepted ?
                <Grow in={isAccepted} timeout={1000}>
                    <div className={classes.container}>
                        <div className={classes.card}>
                            <div className={classes.checkmark}>
                                <i>✓</i>
                            </div>
                            <h1>Success</h1>
                            <p>
                                Thank you for accepting the required documents to work with this employer
                                {/* <br /> */}
                                {/* {"we'll be in touch shortly!"} */}
                            </p>
                        </div>
                    </div>
                </Grow>
                : <>
                    {/* Appbar */}
                    <AppBar component="nav" color='secondary' position="sticky">
                        <Toolbar variant="dense">
                            <Typography variant="h6" color="white">
                                {documents?.[activeStep]?.agreementDocument?.documentName}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    {isLoading && <div className={classes.loadingContainer}>
                        <CircularProgress size={20} />
                    </div>}

                    {/* Content */}
                    <Box className={classes.box} key={documents?.[activeStep]?.agreementDocument?.documentName}>
                        {documents.length && <DocumentViewer setIsLoadingDoc={setIsLoadingDoc} document={documents?.[activeStep]} />}
                        {!isLoading && !documents.length && (
                            <div className={classes.loadingContainer}>
                                <Typography variant="h6" color="red">
                                    {isError ? "Oops! Something went wrong." : "All documents are already accepted!"}
                                </Typography>
                            </div>
                        )}
                    </Box>

                    {/* Stepbar */}
                    <MobileStepper
                        variant="text"
                        steps={maxSteps}
                        className={classes.bottomStepper}
                        position="bottom"
                        activeStep={!maxSteps ? -1 : activeStep}
                        nextButton={
                            <Button
                                sx={{ ml: 5, lineHeight: 0, pl: 2 }}
                                size="small"
                                variant='contained'
                                color='secondary'
                                disabled={isLoadingDoc || isAccepting || isLoading || !documents?.length}
                                onClick={activeStep === maxSteps - 1 ? handleFinish : handleNext}
                            >
                                {activeStep === maxSteps - 1 ? (
                                    <>
                                        <div style={{ paddingTop: 10, paddingBottom: 10, marginRight: 5 }}>
                                            Finish
                                        </div>
                                        {isAccepting && <CircularProgress style={{ marginLeft: 8, marginRight: 8 }} size={16} />}
                                    </>
                                ) : (
                                    <>
                                        Accept
                                        <KeyboardArrowRight />
                                    </>
                                )}
                            </Button>
                        }
                    />
                </>}
        </Box>
    );
};