import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import { checkInOutColumn } from "../../helpers/tableProps";
import TableComponent from "../TableComponent";

const CompletedPage = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const pageInfo = useSelector(JobSelectors.getTodayCompletedPaginationData)
  const jobListData = useSelector(JobSelectors.getListData({ timeframe: 'today', status: 'completed', part: "data" }))
  const pageFilter = useSelector(JobSelectors.getListData({ timeframe: 'today', status: 'completed', part: "filter" }))
  const pageSort = useSelector(JobSelectors.getListData({ timeframe: 'today', status: 'completed', part: "sort" }))
  const fetchData = useCallback(({ pageSize, pageIndex, filters, sortBy }) => {
    dispatch(JobActions.setTodayCompletedPageSize(pageSize));
    dispatch(JobActions.setTodayCompletedFilter(filters));
    dispatch(JobActions.setTodayCompletedSort(sortBy));
    dispatch(JobActions.fetchTodayCompletedJobs(pageIndex));
  }, []);

  return (
    <TableComponent
      pageInfo={pageInfo}
      jobListData={jobListData}
      pageFilter={pageFilter}
      pageSort={pageSort}
      fetchData={fetchData}
      history={props.history}
      extraColumn={[
        checkInOutColumn(t),
        // createActionColumn(),
      ]}
    />
  )
}

export default CompletedPage