import {gql} from "@apollo/client";

export default gql`
  query calculateEmployerBill($data: CalculateEmployerBillInput!) {
    calculateEmployerBill(data: $data) {
      dailyDTRate
      dailyOTRate
      hourlyPay
      regularPayHours
      overtimePay
      overtimePayHours
      doublePay
      doublePayHours
      breakPay
      gfFee
      gstAmount
      gstPercentage
      total
      taxes{
        label
	      percentage
	      amount
      }
    }
  }
`;