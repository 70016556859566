import { CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react'

export default function PDFRenderer({ document,setIsLoadingDoc }) {
    const [loaded, setLoaded] = useState(false)
    const classes = useStyles();
    const iframeRef = useRef(null);
    const interval = useRef();
    const docURL = `https://docs.google.com/gview?url=${encodeURIComponent(document?.agreementDocument?.signedDocumentImageUrl)}&embedded=true`;


    const clearCheckingInterval = () => {
        clearInterval(interval.current);
    };

    const onIframeLoaded = () => {
        clearCheckingInterval();
        setLoaded(true);
        setIsLoadingDoc(false);
    };

    useEffect(() => {
        setIsLoadingDoc(true)
        interval.current = setInterval(() => {
            try {
                // google docs page is blank (204), hence we need to reload the iframe.
                if (iframeRef.current.contentWindow.document.body.innerHTML === '') {
                    iframeRef.current.src = docURL;
                }
            } catch (e) {
                // google docs page is being loaded, but will throw CORS error.
                // it mean that the page won't be blank and we can remove the checking interval.
                onIframeLoaded();
            }
        }, 4000); // 4000ms is reasonable time to load 2MB document

        return clearCheckingInterval;
    }, []);

    return (
        <>
            {!loaded && <div className={classes.loadingContainer}>
                <CircularProgress size={20} />
            </div>}
            <iframe
                ref={iframeRef}
                onLoad={onIframeLoaded}
                style={{ height: "100%", width: "100%" }}
                id="doc-preview"
                title="DOC viewer"
                frameBorder={0}
                src={docURL}
            >
                <p>{"It appears your web browser doesn't support iframes."}</p>
            </iframe>
        </>
    )
};


const useStyles = makeStyles(theme => ({
    loadingContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        background: "white",
        zIndex: 999
    },
}));