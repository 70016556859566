import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MomentUtils from "@date-io/moment";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import Line from "components/Placeholders/Line";
import { convertUTCToTimezone, convertUTCToTimezoneWithOffset, isDateBigger } from "utils/time";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import DisableCellOverlay from "../DisableCellOverlay";
import MobileDateTimePicker from "components/DateTimePicker/MobileDateTimePicker";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import i18n from "i18n";

export default function useCheckIn(isCheckingInAll, disabled, timezone) {
  const dispatch = useDispatch();
  const jobStartUtc = useSelector(JobDetailSelectors.getJobStartUtc);
  const classes = useStyles();
  const [batchCheckinValue, setBatchCheckinValue] = useState(convertUTCToTimezone(jobStartUtc, timezone));
  const { t } = useTranslation();

  const handleBatchApply = time => {
    setBatchCheckinValue(time);
    dispatch(JobDetailActions.batchUpdatePositionData("startShiftUtc")(time));
  };

  const renderCell = useCallback((p) => {
    return <RenederCell {...p} extraData={{ isCheckingInAll, disabled, timezone }} />;
  }, [isCheckingInAll, disabled, timezone]);


  return {
    Header: t("common_table_column_checkIn"),
    id: "startShift",
    canFilter: isCheckingInAll,
    sortable: false,
    style: isCheckingInAll ? { position: "unset" } : {},
    headerStyle: isCheckingInAll ? { position: "unset" } : {},
    styles: {
      minWidth: '150px'
    },
    Filter: () => {
      const pickerProps = {
        hideTabs: true,
        value: batchCheckinValue,
        DialogProps: { className: classes.dateTimePicker },
        onChange: date => handleBatchApply(date),
      };
      return (
        <LocalizationProvider locale={i18n.language} dateAdapter={MomentUtils} localeText={{ cancelButtonLabel: t("common_labels_cancel"), okButtonLabel: t("common_labels_ok") }}>
          <MobileDateTimePicker renderInput={(props) => <TextField {...props} variant="standard" />} {...pickerProps} />
        </LocalizationProvider>
      );
    },
    Cell: renderCell,
  };
};


const RenederCell = ({ row: { original }, extraData: { isCheckingInAll, disabled, timezone }, ...props }) => {
  const positionUpdates = useSelector(JobDetailSelectors.getPositionUpdates);
  const dispatch = useDispatch();
  const updatePositionCheckin = JobDetailActions.updatePositionData("startShiftUtc");
  const classes = useStyles();
  const { t } = useTranslation();
  if (
    isCheckingInAll &&
    original.isEditable &&
    (
      !original.startShiftUtc ||
      (!original.checkedOutAtUtc && isDateBigger(original.checkedInAtUtc))
    )
  ) {

    const { startShiftUtc } = positionUpdates.find(
      ({ id }) => id === original.id,
    );
    const onChange = (date) => {
      dispatch(updatePositionCheckin(original.id, date));
    };
    const pickerProps = {
      hideTabs: true,
      readOnly: !(isCheckingInAll && original.isEditable),
      DialogProps: { className: classes.dateTimePicker },
      disabled: !(isCheckingInAll && original.isEditable),
      value: convertUTCToTimezone(startShiftUtc, timezone),
      onChange: date => onChange(date),
    };
    return (
      <>
        <LocalizationProvider locale={i18n.language} dateAdapter={MomentUtils} localeText={{ cancelButtonLabel: t("common_labels_cancel"), okButtonLabel: t("common_labels_ok") }}>
          <MobileDateTimePicker renderInput={(props) => <TextField {...props} variant="standard" />} {...pickerProps} />
        </LocalizationProvider>
      </>
    );
  }

  return (
    <div style={{ position: "relative" }}>
      {!original.startShift ? (
        <Line />
      ) : (
        <Typography variant="body1">{
          convertUTCToTimezoneWithOffset(original.startShiftUtc, timezone)
        }</Typography>
      )}
      <DisableCellOverlay visible={disabled || (isCheckingInAll && !original.isEditable)} />
    </div>
  );
}
