import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DialogActions, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import iconEdit from "assets/img/iconEdit.svg";
import avatar from "assets/img/profile-placeholder.png";
import { Form, Formik } from "formik";
import * as yup from 'yup';
import Button from "components/CustomButtons/Button";
import Dialog from "components/Dialog/Dialog";
import { actions as ProfileActions, selectors as ProfileSelectors } from "store/modules/profile";
import { useTranslation } from "react-i18next";
import { actions as MetaActions } from "store/modules/meta";
import { isValidFile } from "utils/fileUtils";
import { supportedAvatarFiles } from "utils/constant";

const useStyles = makeStyles(() => ({
  avatarImg: {
    margin: '10px auto',
    width: '100px',
    position: 'relative',
    '& img': {
      borderRadius: '100px',
      border: '2px solid #c4c4c4'
    },
    '& label': {
      position: 'absolute',
      right: '6px',
      background: '#ffffff',
      bottom: '0',
      padding: '0px',
      '& img': {
        padding: '2px',
      },
    }
  }
}));
function EditCompanyDialog(props) {
  const { open, onClose, onCancel, onConfirm, profileData } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [logoURL, setLogoURL] = useState();
  const isLoading = useSelector(ProfileSelectors.getIsUpdatingProfileLoading)
  const handleProfileFile = file => {
    const response = isValidFile(file);

    if (response.isValid) {
      const item = new Blob([file], { type: file.type });
      item.name = `logo_${file.name}`;
      const prevLogo = logoURL;
      setLogoURL(URL.createObjectURL(item))
      dispatch(ProfileActions.uploadCompanyLogoImage(item)).then(res => {
        if (res && res?.message) setLogoURL(prevLogo)
      });
    } else {
      dispatch(MetaActions.errorToast(response.message));
    }
  }
  useEffect(() => {
    setLogoURL(profileData.signedLogoImageUrl)
  }, [profileData.signedLogoImageUrl]);

  return (
    <Dialog
      open={open}
      title={t("page_profile_dialog_company_title")}
      onClose={onClose}
      onCancel={onCancel}
      alertType={'form'}
      hideActions
      size="sm"
      modalType="scrollable"
      disableBackdropClick={false}
    >
      <Grid item xs={12} style={{ width: "100%", paddingLeft: 0, paddingRight: 0 }}>
        <Formik
          initialValues={{
            title: profileData.title,
            companyName: profileData.companyName,
            workEmail: profileData.workEmail,
          }}
          onSubmit={(values, actions) => {
            onConfirm(values);
            actions.setSubmitting(false);
          }}
          validationSchema={yup.object().shape({
            workEmail: yup.string().email(t('common_errors_enter_valid_email')).max(100, t("common_errors_max_email_length", { length: 100 })).required(t("common_errors_required")),
            title: yup.string().required(t("common_errors_required")).max(55, t("common_errors_max_title_length", { length: 55 })),
            companyName: yup.string().required(t("common_errors_required")).max(55, t("common_errors_max_company_name_length", { length: 55 })),
          })}>
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
            } = props;
            return (
              <Form>
                <Grid
                  item
                  container
                  direction="row"
                  xs={12}
                >
                  <Grid container item xs={12} md={12} spacing={1}>
                    <div className={classes.avatarImg}>
                      <img width={'100'} height={'100'} src={logoURL} onError={(e) => { e.target.onerror = null; e.target.src = avatar }} alt="..." />
                      <IconButton className="edit-icon" disableRipple component="label">
                        <img src={iconEdit} />
                        <input type="file" accept={supportedAvatarFiles.map(a => a.mime).join(",")} hidden onChange={files => handleProfileFile(files.target.files[0])} />
                      </IconButton>
                    </div>
                  </Grid>
                  <Grid container item xs={12} md={12} spacing={1}>
                    <Grid item xs={12} md={12}>
                      <TextField
                        id="companyName"
                        name="companyName"
                        label={`${t("common_labels_company_name")} *`}
                        className={classes.textField}
                        value={values.companyName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        disabled={!!profileData.companyName}
                        error={errors.companyName && touched.companyName}
                        helperText={errors.companyName && touched.companyName && errors.companyName}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} >
                    <TextField
                      id="title"
                      name="title"
                      label={`${t("common_labels_title")} *`}
                      className={classes.textField}
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      disabled={!!profileData.title}
                      error={errors.title && touched.title}
                      helperText={errors.title && touched.title && errors.title}
                      margin="normal"
                    />
                  </Grid>
                  <Grid container item xs={12} md={12}>
                    <TextField
                      id="workEmail"
                      name="workEmail"
                      label={`${t("common_labels_work_email")} *`}
                      className={classes.textField}
                      value={values.workEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      disabled={!!profileData.workEmail}
                      error={errors.workEmail && touched.workEmail}
                      helperText={errors.workEmail && touched.workEmail && errors.workEmail}
                      margin="normal"
                    />
                  </Grid>
                </Grid>
                <DialogActions className={classes.footer}>
                  <Button
                    type="button"
                    className="outline"
                    onClick={onCancel}
                    color="transparent"
                    round
                  >
                    {t("common_labels_close")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    round
                    disabled={!_.isEmpty(errors) || isLoading}
                  >
                    {t("common_labels_save")}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Dialog>
  );
}

export default EditCompanyDialog;
