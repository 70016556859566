import moment from "moment";

export const getProfileData = (state) => {
  return state.profile.profileDetail;
};
export const getEmployerDetail = (state) => {
  return state.profile.employerDetail;
};

export const getTimeBasePremiumListData = (state) => {
  return state.profile.timeBasePremiumList || [];
};

export const getSelectedTimeBasePremium = (state) => {
  return state.profile.selectedTimeBasePremium;
};

export const getTimeBasePremiumForm = state => {
  return state.profile.timeBasePremiumForm || {};
};

export const canAddUpdateTimeBasePremium = state => {
  const { startTime, endTime, type, value, days } = state.profile.timeBasePremiumForm || {};
  return (
    startTime &&
    endTime &&
    moment(startTime).add(1, "hour").isSameOrBefore(moment(endTime)) &&
    ((type === "PERCENTAGE" && value > 0 && value <= 100) || (type === "VALUE" && value > 0)) &&
    days && days.length
  )
};

export const getDynamicWorkTypeRateListData = (state) => {
  return state.profile.dynamicWorkTypes || [];
};

// Loading selectors
export const getLoadingStateFactory = loader => state => {
  return state.profile.loading[loader] || false;
};

export const getIsPageLoading = getLoadingStateFactory("page");
export const getIsUpdatingProfileLoading = getLoadingStateFactory("updateProfile");
export const getIsChangeCompanyLoading = getLoadingStateFactory("changeCompany");
export const getIsTimeBaseListLoading = getLoadingStateFactory("timeBasePremiumList");
export const getIsAddTimeBasePremiumListLoading = getLoadingStateFactory("addTimeBasePremium");
export const getIsRemoveTimeBasePremiumLoading = getLoadingStateFactory("removeTimeBasePremium");
export const getIsDynamicWorkTypeRateListLoading = getLoadingStateFactory("isDynamicWorkTypeRateList");

// Dialog selectors
export const getDialogStateFactory = loader => state => {
  return state.profile.dialog[loader] || false;
};

export const getIsProfileDialog = getDialogStateFactory("profileDialog");
export const getIsCompanyDialog = getDialogStateFactory("companyDialog");
export const getIsBillingInfoDialog = getDialogStateFactory("billingInfoDialog");
export const GetNewTimeBasePremiumDialog = getDialogStateFactory("newTimeBasePremium");
export const GetRemoveTimeBasePremiumDialog = getDialogStateFactory("removeTimeBasePremium");

