import { isEmpty } from "lodash";
import moment from "moment";
import ApolloClient from "utils/apollo";
import { convertLocalToUTC } from "utils/time";
import { actions as MetaActions } from "store/modules/meta";
import { getMe } from "../login/selectors";
import createJobTemplateMutation from "./graphql/mutations/create-job-template";
import postJobMutation from "./graphql/mutations/post-job";
import GetJobBillQuery from "./graphql/queries/get-employer-bill";
import GetGearsQuery from "./graphql/queries/get-gears";
import GetSkillsQuery from "./graphql/queries/get-skills";
import GetWorkTypeQuery from "./graphql/queries/get-work-types";
import { getPostJobFormData } from "./selectors";
import types from "./types";
import i18n from "i18n";

const setLoadingState = key => value => ({
  type: types.SET_LOADING_STATE,
  payload: { key, value },
});
const setIsPostJobLoading = setLoadingState("postJob");

export const setFormState = key => value => ({
  type: types.SET_FORM_STATE,
  payload: { key, value },
});
export const resetFormData = () => ({
  type: types.RESET_FORM_DATA
});

const setDialogState = key => value => ({
  type: types.SET_DIALOG_STATE,
  payload: { key, value },
});
export const setIsPostJobDialog = setDialogState("postJobDialog");

const setIsPostJobState = key => value => ({
  type: types.SET_POST_JOB_STATE,
  payload: { key, value },
});
export const setIsTempleteSelected = setIsPostJobState("canTemplateSelected");

export const setWorkType = setFormState("workType");
export const setRequiredGear = setFormState("requiredGear");
export const setSkills = setFormState("skills");
export const setDescription = setFormState("description");
export const setAddress = setFormState("address");
export const setUnit = setFormState("unit");
export const setFloor = setFormState("floor");
export const setLocationInstruction = setFormState("locationInstruction");
export const setStreet = setFormState("street");
export const setCity = setFormState("city");
export const setRegion = setFormState("region");
export const setLatitude = setFormState("latitude");
export const setLongitude = setFormState("longitude");
export const setPostalCode = setFormState("postalCode");
export const setCountry = setFormState("country");
export const setTimezone = setFormState("timezone");
export const setDate = setFormState("date");
export const setStartTime = setFormState("startTime");
export const setEndTime = setFormState("endTime");
export const setDuration = setFormState("duration");
export const setBreakDuration = setFormState("breakDuration");
export const setBreakType = setFormState("breakType");
export const setIsRecurring = setFormState("isRecurring");
export const setIsFixedTerm = setFormState("isFixedTerm");
export const setRecurringType = setFormState("recurringType");
export const setWeekDays = setFormState("weekDays");
export const setRepeatEvery = setFormState("repeatEvery");
export const setRepeatType = setFormState("repeatType");
export const setMonthDate = setFormState("monthDate");
export const setRepeatDays = setFormState("repeatDays");
export const setRecurringEndDate = setFormState("recurringEndDate");
export const setNoOfWorkers = setFormState("noOfWorkers");
export const setSiteManager = setFormState("siteManager");
export const setCheckInManager = setFormState("checkInManager");
export const setCheckOutManager = setFormState("checkOutManager");
export const setAnnouncement = setFormState("announcement");
export const setTemplateName = setFormState("templateName");
export const setIsPrivateJob = setFormState("isPrivate");
export const setTags = setFormState("tags");


export const fetchWorkTypes = () => (dispatch) => {

  return ApolloClient.query({
    query: GetWorkTypeQuery,
    variables: {},
  })
    .then(({ data }) => {
      dispatch({
        type: types.SET_WORK_TYPE_LIST,
        payload: {
          data: data.workTypes
        },
      });
    })
    .catch((e) => {
      console.log(e)
    });
};

export const fetchGear = () => (dispatch) => {

  return ApolloClient.query({
    query: GetGearsQuery,
    variables: {},
  })
    .then(({ data }) => {
      dispatch({
        type: types.SET_GEAR_LIST,
        payload: {
          data: data.gears
        },
      });
    })
    .catch((e) => {
      console.log(e)
    });
};

export const fetchSkills = () => (dispatch) => {

  return ApolloClient.query({
    query: GetSkillsQuery,
    variables: {},
  })
    .then(({ data }) => {
      dispatch({
        type: types.SET_SKILLS_LIST,
        payload: {
          data: data.skills
        },
      });
    })
    .catch((e) => {
      console.log(e)
    });
};

export const calculateEmployerBill = (params) => (dispatch, getState) => {
  const state = getState();
  const { employerId } = getMe(state);

  return ApolloClient.query({
    query: GetJobBillQuery,
    variables: {
      data: {
        employerId: employerId,
        ...params
      }
    },
  })
    .then(({ data }) => {
      dispatch({
        type: types.SET_CALCULATED_JOB_AMOUNT,
        payload: {
          data: data.calculateEmployerBill
        },
      });
    })
    .catch((e) => {
      console.log(e)
    });
};

export const postJob = () => (dispatch, getState) => {
  const state = getState();
  const { employerId } = getMe(state);
  const requestData = getPostJobFormData(state);

  const variable = {
    country: requestData.country ? requestData.country : '',
    employerId: employerId,
    startUtc: convertLocalToUTC(requestData.startTime),
    endUtc: convertLocalToUTC(requestData.endTime),
    workTypeId: requestData.workType.value,
    peopleNeeded: Number(requestData.noOfWorkers),
    street: requestData.street,
    city: requestData.city ? requestData.city : '',
    region: requestData.region ? requestData.region : '',
    latitude: requestData.latitude || 0,
    longitude: requestData.longitude || 0,
    breakMins: Number(requestData.breakDuration),
    isBreakPaid: requestData.breakType === "paid" ? true : false,
    unit: requestData.unit,
    description: requestData.description,
    gears: requestData.requiredGear?.map?.(a=>a?.id?.toString?.()),
    skills: requestData.skills?.map?.(a=>a?.id?.toString?.()),
    floor: requestData.floor,
    isPrivate: requestData.isPrivate,
    tags: requestData.tags || [],
    locationInstruction: requestData.locationInstruction,
    jobManager: [
      ...(!isEmpty(requestData.siteManager) ? [{ role: 'SiteManager', managerId: requestData.siteManager.manager.id }] : []),
      ...(!isEmpty(requestData.checkInManager) ? [{ role: 'CheckInManager', managerId: requestData.checkInManager.manager.id }] : []),
      ...(!isEmpty(requestData.checkOutManager) ? [{ role: 'CheckOutManager', managerId: requestData.checkOutManager.manager.id }] : []),
    ],
    isRecurring: requestData.isRecurring === 'true',
    isFixedTerm: requestData.isFixedTerm,
    postalCode: requestData.postalCode,
  }
  if (requestData.isRecurring === 'true') {
    variable.jobRecurring = {
      frequency: requestData.recurringType,
      frequencyEndAt: convertLocalToUTC(requestData.recurringEndDate),
      dayOrWeekDay: requestData.recurringType === 'Weekly' ? requestData.weekDays.map(n => n.value) : requestData.monthDate,
      frequencyInterval: requestData.repeatEvery
    }
  }
  if (requestData.announcement.length > 0 && requestData.announcement[0].title) {
    variable.jobAnnouncement = requestData.announcement.map(n => ({
      title: n.title,
      description: n.description,
      frequency: n.repeatType,
      dayOrWeekDay: n.days.map(m => m.value),
      sendAt: n.time ? moment(n.time).format('HH:mm') + ':00' : '',
      beforeAfter: 'Before',
      minutes: n.before,
      workers: []
    }))
  }
  dispatch(setIsPostJobLoading(true))
  return ApolloClient.mutate({
    mutation: postJobMutation,
    variables: { data: variable },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      }
      else {
        dispatch(setIsPostJobDialog(true));
        dispatch(resetFormData());
        dispatch(setIsPostJobLoading(false));
        return data;
      }
    })
    .catch((e) => {
      console.log(e)
      dispatch(setIsPostJobLoading(false))
      dispatch(MetaActions.errorToast(`${e.message}`));
    });
};

export const saveTemplate = () => (dispatch, getState) => {
  const state = getState();
  const { employerId } = getMe(state);
  const requestData = getPostJobFormData(state);
  const variable = {
    country: requestData.country ? requestData.country : '',
    templateName: requestData.templateName,
    employerId: employerId,
    workTypeId: requestData.workType.value,
    peopleNeeded: Number(requestData.noOfWorkers),
    street: requestData.street,
    city: requestData.city ? requestData.city : '',
    region: requestData.region ? requestData.region : '',
    latitude: requestData.latitude || 0,
    longitude: requestData.longitude || 0,
    breakMins: Number(requestData.breakDuration),
    isBreakPaid: requestData.breakType === "paid" ? true : false,
    unit: requestData.unit,
    description: requestData.description,
    gears: requestData.requiredGear?.map?.(a=>a?.id?.toString?.()),
    skills: requestData.skills?.map?.(a=>a?.id?.toString?.()),
    floor: requestData.floor,
    isPrivate: requestData.isPrivate,
    tags: requestData.tags || [],
    locationInstruction: requestData.locationInstruction,
  }
  return ApolloClient.mutate({
    mutation: createJobTemplateMutation,
    variables: { data: variable },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      }
      dispatch(MetaActions.successToast(i18n.t("toast_success_post_job_template_save")));
      return data;
    })
    .catch((e) => {
      console.log(e)
      dispatch(MetaActions.errorToast(`${e.message}`));
    });
}