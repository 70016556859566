import React from "react";
import { Container, Grid, Hidden } from "@mui/material";
import { makeStyles } from '@mui/styles';
import AppStore from "assets/img/appstore.png";
import PhoneWorker from "assets/img/grizzly_phone_workers.png";
import SuccessIcon from "assets/img/success-icon.png";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        backgroundColor: '#FFF',
        maxWidth: '1000px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    borderTop: {
        borderTop: '1px solid #cccccc',
    },
    link: {
        color: '#337ab7'
    },
    boldFont: {
        fontWeight: "bold"
    },
    handimg: {
        width: '100%'
    }
}));
export default function EmailVerifySuccess() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <Container fixed className={classes.container}>
                <Grid container>
                    <Grid item xs={12}>
                        <h1 className={classes.boldFont}>
                            <Hidden mdDown>
                                <img src={SuccessIcon} width="80" />
                            </Hidden>
                            {t("page_email_verification_welcome")}
                        </h1>
                    </Grid>
                    <Grid item xs={12}>
                        <h4 className={classes.boldFont}>
                            <div dangerouslySetInnerHTML={{ __html: t("page_email_verification_desc", { interpolation: { escapeValue: false } }) }} />
                        </h4>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <h4 className={classes.boldFont}>
                            {t("page_email_verification_already_done")}
                        </h4>
                        <a href="https://apps.apple.com/us/app/grizzly-force/id1205858904" target="_blank" rel="noreferrer">
                            <img src={AppStore} width="49%" alt="" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.GrizzlyLC.Grizzly" target="_blank" rel="noreferrer">
                            <img width="49%" src={"/assets/img/gplay.svg"} alt="" />
                        </a>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <img src={PhoneWorker}
                            className={classes.handimg} />
                    </Grid>
                    <Grid item xs={12} className={classes.borderTop}>
                        <h4 className={classes.boldFont}>
                            {t("page_email_verification_forwerd_email_info")}<a href="mailto: admin@randstad.ca" className={classes.link}>admin@randstad.ca.</a>
                        </h4>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
