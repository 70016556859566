import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, Router } from "react-router-dom";
import indexRoutes from "./routes/index.jsx";
import { actions as LoginActions } from "./store/modules/login";
import history from "./utils/history";


class App extends Component {
  state = {
    initializing: true,
  };

  async componentDidMount() {
    if (history.location.pathname !== '/email-verified' && history.location.pathname !== '/chat-support-iframe' && !history.location.pathname?.includes('/review-documents')) {
      if (!this.props.me) {
        await this.props.checkSession();
        this.setState({ initializing: false });
      } else {
        this.setState({ initializing: false });
      }
      window.addEventListener("storage", this.syncLogout);
    } else {
      this.setState({ initializing: false });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener("storage", this.syncLogout);
    window.localStorage.removeItem("logout");
  }

  syncLogout = event => {
    if (event.key === "logout") {
      this.props.resetLogin();
      history.replace("/");
    }
  };

  render() {
    if (this.state.initializing) {
      return (
        <div className="spinner">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </div>
      );
    }

    return (
      <>
        <Router history={history}>
          <Switch>
            {indexRoutes.map((prop, key) => {
              return <Route path={prop.path} component={prop.component} key={key} />;
            })}
          </Switch>
        </Router>
        {this.props.isLoggingOut && <div style={{ backgroundColor: "#00000021", top: 0, position: "fixed", zIndex: 1500 }}>
          <div className="spinner">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </div>
        </div>}
      </>
    );
  }
}

const mapState = state => {
  const {  isLoggingOut, me } = state.login;
  return {
    isLoggingOut,
    me
  };
};

const mapActions = {
  checkSession: LoginActions.checkSession,
  resetLogin: LoginActions.resetForm,
  getTimezoneList: LoginActions.setTimezoneList,
};

export default connect(mapState, mapActions)(App);
