import i18n from "i18n";
import { supportedAvatarFiles } from "./constant";

// Validate file type and size [default size is 5MB]
export const isValidFile = (file, size = 5) => {
    let isValid = supportedAvatarFiles.find(f => f.mime === file.type);
    if (isValid) {
        isValid = isValid && (file.size < size * 1024 * 1024);
        if (isValid) {
            return ({
                isValid: true,
                message: "Valid File"
            });
        } else
            return ({
                isValid: false,
                message: i18n.t("error_max_file_size", { size })
            });
    }
    else
        return ({
            isValid: false,
            message: i18n.t("error_invalid_file_type")
        });
};