const { makeStyles } = require("@mui/styles");

export const useStyles = makeStyles(() => ({

    bannerSection: {
      height: '150px',
      backgroundColor: '#00d3b3'
    },
    profilePhoto: {
      marginTop: '-50px',
      textAlign: 'center',
      position: 'relative',
      '& .edit-icon': {
        position: 'absolute',
        bottom: '0',
        right: '10px'
      }
    },
    avatarImg: {
      width: '100px',
      height: '100px',
      borderRadius: '100px',
      border: '2px solid #FFF',
      background: '#FFF'
    },
    detailSection: {
      textAlign: 'center',
      color: '#202326',
      '& h3': {
        fontWeight: '500',
        marginBottom: '0'
      },
      '& p': {
        color: '#848499',
        fontWeight: '500',
        marginBottom: '0'
      }
    },
    companyName: {
      padding: '0 0 5px 0',
      '& .MuiCardHeader-title': {
        fontSize: '20px',
        fontWeight: 'bold'
      },
      '& .MuiCardHeader-subheader': {
        fontSize: '1rem',
        fontWeight: '400'
      },
    },
    companyLogo: {
      width: '40px',
      height: '40px',
      borderRadius: '5px'
    },
    billingName: {
      padding: '0 0 5px 0',
      position:"relative",
      '& .MuiCardHeader-title': {
        fontSize: '20px',
        fontWeight: 'bold'
      },
      '& .MuiCardHeader-subheader': {
        fontSize: '1rem',
        fontWeight: '400'
      },
    },
    emailSection: {
      marginLeft: '56px',
      paddingBottom: '15px',
      color: '#848499',
      overflowWrap: 'break-word',
      width: 'auto'
    },
    billingSection: {
      marginLeft: '40px',
      paddingBottom: '15px',
      color: '#848499',
      "@media (max-width: 600px)": {
        marginLeft: '0px',
      }
    },
    secondSection: {
      paddingTop: '15px',
      borderTop: '1px solid #e3e3ec',
      textAlign: 'center',
      '& .leftBlock': {
        borderRight: '1px solid #e3e3ec',
      },
      '& .value': {
        marginBottom: '5px',
        '& span': {
          position: 'relative',
          top: '3px',
          fontSize: '20px',
          fontWeight: '500'
        }
      },
      '& .label': {
        color: '#202326'
      }
    },
    cardHeading: {
      display: 'flex',
      '& button': {
        padding: '0'
      }
    },
    billingCard: {
      height: '100%'
    },
    address: {
      marginTop: '10px'
    },
    overlayLoading:{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background:"#d2d2d282"
    }
  }));

  export default useStyles;