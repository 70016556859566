import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Chip, Divider, IconButton, TextField, Typography, FormControlLabel, Switch } from "@mui/material";
import { remove } from "lodash";
import Button from "components/Button/Button";
import { actions as MetaActions } from "store/modules/meta";
import { actions as PostJobActions, selectors as PostJobSelectors } from "store/modules/postJob";
import { actions as ProfileActions, selectors as ProfileSelectors } from "store/modules/profile";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import i18n from "i18n";

function RequiredGear({ handleNext }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [gear, setGear] = useState("");
  const requiredGear = useSelector(PostJobSelectors.getRequiredGear)?.filter(a=>typeof a !== "string");
  const gearList = useSelector(PostJobSelectors.getGearList);
  const skillList = useSelector(PostJobSelectors.getSkillList);
  const [tag, setTag] = useState("");
  const employerData = useSelector(ProfileSelectors.getEmployerDetail);
  const isPrivate = useSelector(PostJobSelectors.getIsPrivateJob);
  const tags = useSelector(PostJobSelectors.getTags)
  const canPostPrivateJob = employerData.canPostPrivateJob
  const [gearTextLength, setGearTextLength] = useState(0)
  const isFixedTerm = useSelector(PostJobSelectors.getIsFixedTerm);
  const [skillTextLength, setSkillTextLength] = useState(0)
  const isFrenchLanguage = i18n.language === "fr";

  useEffect(() => {
    dispatch(PostJobActions.fetchGear());
    dispatch(PostJobActions.fetchSkills());
    dispatch(ProfileActions.fetchEmployerDetail());
  }, []);

  useEffect(() => {
    if (!isPrivate) {
      dispatch(PostJobActions.setTags([]));
    }
  }, [isPrivate])

  const onIsPrivateChange = (isPrivate) => {
    dispatch(PostJobActions.setIsPrivateJob(isPrivate))
  };

  const handleChangeTag = (e) => {
    if (e.target.value.length <= 50) {
      setTag(e.target.value);
    }
  }

  const addTag = () => {
    if (tags.indexOf(tag) < 0) {
      if (tag.replace(/^\s+|\s+$/gm, '')) {
        const seletedTags = [...tags];
        seletedTags.push(tag);
        dispatch(PostJobActions.setTags(seletedTags));
        setTag("");
      }
    } else {
      dispatch(MetaActions.errorToast(t(`common_errors_tag_already_added`)));
    }
  };

  const handleDeleteTags = (value) => {
    const seletedTags = [...tags];
    remove(seletedTags, n => n === value);
    dispatch(PostJobActions.setTags(seletedTags));
  }

  const handleChange = e => {
    if (e) {
      setGearTextLength(e.length)
      if (e.trim() && e.length <= 50) {
        setGear(e.trim());
      }
    } else {
      setGear(e);
    }
  };
  const addGear = () => {
    if (requiredGear?.findIndex(r => r?.[isFrenchLanguage ? "frenchGearName" : "gearName"] === gear) < 0) {
      if (gear.trim()) {
        const seleted = [...requiredGear];
        const item = gearList.find(s => s?.[isFrenchLanguage ? "frenchGearName" : "gearName"] === gear);
        if (item) {
          seleted.push(item);
          dispatch(PostJobActions.setRequiredGear(seleted));
        }
        setGear("");
      }
    } else {
      dispatch(MetaActions.errorToast(t(`common_errors_gear_already_added`)));
    }
  };
  const handleDelete = value => {
    const seleted = [...requiredGear];
    remove(seleted, n => n?.id === value?.id);
    dispatch(PostJobActions.setRequiredGear(seleted));
  };

  const [skill, setSkill] = useState("");
  const skills = useSelector(PostJobSelectors.getSkills)?.filter(a=>typeof a !== "string");;

  const handleChangeSkill = e => {
    if (e) {
      setSkillTextLength(e.length)
      if (e.trim() && e.length <= 50) {
        setSkill(e.trim());
      }
    } else {
      setSkill(e);
    }
  };
  const addSkill = () => {
    if (skills.findIndex(s => s?.[isFrenchLanguage ? "frenchSkillName" : "skillName"] === skill) < 0) {
      if (skill.replace(/^\s+|\s+$/gm, '')) {
        const seleted = [...skills];
        const item = skillList.find(s => s?.[isFrenchLanguage ? "frenchSkillName" : "skillName"] === skill);
        if (item) {
          seleted.push(item);
          dispatch(PostJobActions.setSkills(seleted));
        }
        setSkill("");
      }
    } else {
      dispatch(MetaActions.errorToast(t(`common_errors_skill_already_added`)));
    }
  };
  const handleDeleteSkill = value => {
    const seleted = [...skills];
    remove(seleted, n => n?.id === value?.id);
    dispatch(PostJobActions.setSkills(seleted));
  };
  return (
    <>
      <h3>{t("common_labels_required_gears")}</h3>
      <div className={classes.gearTextBox}>
        <Autocomplete
          id="free-solo-demo"
          freeSolo
          disableClearable={!gear}
          onChange={(event, newValue) => {
            handleChange(newValue)
          }}
          value={gear || ""}
          fullWidth
          options={gearList.map((option) => option?.[isFrenchLanguage ? "frenchGearName" : "gearName"])}
          renderInput={(params) =>
            <TextField
              {...params}
              value={gear}
              placeholder={t("common_labels_required_gears_input")}
              onChange={e => handleChange(e.target.value)}
              onKeyPress={e => {
                if (e.key === "Enter" && e.target.value) {
                  addGear();
                }
              }}
            />
          }
        />
        <Button
          disabled={!gear || gearTextLength > 50}
          onClick={addGear}
          label={t("common_labels_add")}
        />
      </div>
      {requiredGear?.length > 0 && (
        <div className={classes.selectedSection}>
          <Typography
            sx={{ fontSize: 14 }}
            color="text.secondary"
            gutterBottom
            justifyContent={"space-between"}
          >
            {t("common_labels_selected")} ({requiredGear.length})
          </Typography>
          {requiredGear &&
            requiredGear.map((n, index) => (
              <Chip key={index} label={n?.[isFrenchLanguage ? "frenchGearName" : "gearName"]} variant="outlined" onDelete={() => handleDelete(n)} />
            ))}
        </div>
      )}
      <br />
      <Divider />
      <h3>{t("common_labels_suggested_skills")} {!isFixedTerm && canPostPrivateJob ? t("common_labels_suggested_skills_optional") : ''}</h3>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {t("page_post_job_required_gears_skills_helper")}
      </Typography>
      <div className={classes.skillsTagsSection}>
        {canPostPrivateJob && (
          <div>
            <div className={classes.tags}>
              <h6 style={{ marginRight: "3px", marginTop: '12px' }}>{t("page_post_job_required_gears_private_job")}</h6>
              <Switch
                id="isPrivate"
                defaultChecked={isPrivate}
                onChange={(e) => onIsPrivateChange(e.target.checked)}
                name="isPrivate"
                color="secondary"
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
            {isPrivate && (
              <>
                <div className={classes.gearTextBox}>
                  <TextField
                    id="tag"
                    name="tag"
                    placeholder={t("common_labels_suggested_skills_optional_input")}
                    value={tag}
                    onChange={handleChangeTag}
                    fullWidth
                    margin="none"
                    onKeyPress={e => {
                      if (e.key === "Enter" && e.target.value) {
                        addTag();
                      }
                    }}
                  />
                  <Button
                    // size="small"
                    disabled={!tag}
                    onClick={addTag}
                    label={t("common_labels_add")}
                  />
                </div>
                {tags.length > 0 && (
                  <div className={classes.selectedSection}>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                      justifyContent={"space-between"}
                    >
                      {t("common_labels_added_tags")}
                    </Typography>
                    {tags &&
                      tags.map((n, index) => (
                        <Chip key={index} label={n} variant="outlined" onDelete={() => handleDeleteTags(n)} />
                      ))}
                  </div>
                )}
              </>
            )}
          </div>
        )}
        <div>
          {canPostPrivateJob && (
            <h6>{t("common_labels_suggested_skills")}</h6>
          )}
          <div className={classes.gearTextBox}>
            <Autocomplete
              id="free-solo-demo-1"
              freeSolo
              disableClearable={!skill}
              onChange={(event, newValue) => {
                handleChangeSkill(newValue)
              }}
              value={skill || ""}
              fullWidth
              options={skillList.map((option) => option?.[isFrenchLanguage ? "frenchSkillName" : "skillName"])}
              renderInput={(params) =>
                <TextField
                  {...params}
                  value={skill}
                  placeholder={t("page_post_job_required_gears_skills_placeholder")}
                  onChange={e => handleChangeSkill(e.target.value)}
                  onKeyPress={e => {
                    if (e.key === "Enter" && e.target.value) {
                      addSkill();
                    }
                  }}
                />
              }
            />
            <Button
              disabled={!skill || skillTextLength > 50}
              onClick={addSkill}
              label={t("common_labels_add")}
            />
          </div>
          {skills?.length > 0 && (
            <div className={classes.selectedSection}>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
                justifyContent={"space-between"}
              >
                {t("common_labels_selected")} ({skills.length})
              </Typography>
              {skills &&
                skills.map((n, index) => (
                  <Chip key={index} label={n?.[isFrenchLanguage ? "frenchSkillName" : "skillName"]} variant="outlined" onDelete={() => handleDeleteSkill(n)} />
                ))}
            </div>
          )}



          <Button
            className={classes.continueButton}
            size="small"
            disabled={requiredGear?.length === 0 || skills?.length === 0 || (isPrivate && tags.length === 0)}
            onClick={handleNext}
            label={t("common_labels_continue")}
          />
        </div>
      </div>
    </>
  );
}

export default RequiredGear;
