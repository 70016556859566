/* eslint-disable import-helpers/order-imports */
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import {
  useTable, usePagination, useGlobalFilter, useFilters, useExpanded, useSortBy
} from 'react-table';
import classNames from 'classnames';
import {
  CircularProgress, Paper, Table,
  TableFooter, TableRow, Typography
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import DefaultColumnFilter from './filterFunctions/DefaultColumnFilter';
import TablePaginationActions from './TablePaginationActions';
import '../../assets/scss/react-table.css';
import { useTranslation } from 'react-i18next';

// import GlobalFilter from './filterFunctions/GlobalFilter';
/* eslint-disable */

const ReactTableCustom = forwardRef(({ columns, data, fetchData, loading, pageCount: controlledPageCount, defaultFilter, defaultSort, currentPage, defaultPageSize, title, onRowClick, expandView }, ref) => {
  const { t } = useTranslation();
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page,
    pageOptions,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    setAllFilters,
    state: { pageIndex, pageSize, globalFilter, filters, sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0 },
      manualPagination: true, // Tell the usePagination
      manualGlobalFilter: true,
      autoResetFilters: false,
      // autoResetGlobalFilter: false,
      // debug: true,
      pageCount: controlledPageCount,
      initialState: {
        filters: defaultFilter || [],
        sortBy: defaultSort || [],
        pageSize: defaultPageSize || 10,
        pageIndex: currentPage || 0
      }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  );

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize, globalFilter, filters, sortBy })
  }, [fetchData, pageIndex, pageSize, globalFilter, filters, sortBy]);
  // const firstPageRows = rows.slice(0, 10);

  useEffect(() => {
    if (currentPage !== pageIndex) gotoPage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (!data?.length && currentPage !== 0) gotoPage(0);
  }, [data?.length]);

  //Reload data
  useImperativeHandle(ref, () => ({
    reloadData() {
      fetchData({ pageIndex, pageSize, globalFilter, filters, sortBy });
    }
  }));
  // Render the UI for your table
  return (
    <>
      <Paper className="react-table-main">
        {/*<GlobalFilter*/}
        {/*  preGlobalFilteredRows={preGlobalFilteredRows}*/}
        {/*  globalFilter={globalFilter}*/}
        {/*  setGlobalFilter={setGlobalFilter}*/}
        {/*/>*/}
        {title && (
          <Typography
            variant="h5"
            className="title"
            color="primary"
          >
            {title}
          </Typography>
        )}
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <React.Fragment key={i}>
                <tr {...headerGroup.getHeaderGroupProps()} key={i} className="header-row">
                  {headerGroup.headers.map((column, ind) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} title="" className={column.canSort ? 'cursor-pointer' : ''} style={column.styles ? column.styles : {}} key={ind}>
                      {column.render('Header')}
                      {column.canSort && <span className='sort-arrow'>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <i className="fas fa-caret-down"></i>
                            : <i className="fas fa-caret-up"></i>
                          : <i className="fas fa-sort"></i>}
                      </span>}
                    </th>
                  ))}
                </tr>
                <tr className="header-filter-row">
                  {/* Render the columns filter UI */}
                  {headerGroup.headers.map((column, ind) => (
                    <th {...column.getHeaderProps()} key={ind}>
                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                    </th>
                  ))}
                </tr>
              </React.Fragment>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={i}>
                  <tr {...row.getRowProps()} className="data-row">
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          key={index}
                          onClick={() => {
                            if (!cell.column.isNotClickable && onRowClick) {
                              onRowClick(row);
                            }
                          }}
                          className={classNames(cell.column.className ? cell.column.className : '', (!cell.column.isNotClickable && onRowClick) ? 'cursor-pointer' : '')}
                          style={cell.column.styles ? cell.column.styles : {}}>{cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                  {row.isExpanded ? (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {expandView(row)}
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              )
            })}
            {page.length === 0 && !loading && (
              <tr className="data-row">
                <td colSpan={30} className='align-center'>{t("common_table_not_found")}</td>
              </tr>
            )}
            {loading && (
              // Use our custom loading state to show a loading indicator
              <tr>
                <td style={{ padding: 0 }}>
                  <div className="progress-overlay">
                    <CircularProgress
                      size={24}
                      className="button-progress"
                    />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Table>
          <TableFooter>
            <TableRow>
              <TablePagination
                labelRowsPerPage={t("common_table_rows_per_page")}
                className="table-pagination-footer"
                colSpan={3}
                count={pageOptions.length}
                rowsPerPage={pageSize}
                page={pageIndex}
                onPageChange={(e, page) => {
                  gotoPage(page)
                }}
                onRowsPerPageChange={e => {
                  setPageSize(Number(e.target.value))
                }}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={(data)=>(t("common_table_remaining_of_total_page",data))}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </>
  )
});

export default ReactTableCustom;
