import moment from "moment";
import { createReducer } from "utils/redux";
import types from "./types";

const initialLoadingState = {
  page: false,
  updateProfile: false,
  changeCompany: false,
  timeBasePremiumList: false,
  addTimeBasePremium: false,
  removeTimeBasePremium: false,
  isDynamicWorkTypeRateList: false,
};
const initialDialogState = {
  profileDialog: false,
  companyDialog: false,
  newTimeBasePremium: false,
  removeTimeBasePremium: false
};
const initialTimeBasePremiumForm = {
  isForFullShift: false,
  startTime: moment(),
  endTime: moment().add(4, "hours"),
  type: "PERCENTAGE",
  value: 0,
  days: []
};
const initialState = {
  profileDetail: {},
  employerDetail: {},
  loading: { ...initialLoadingState },
  dialog: { ...initialDialogState },
  timeBasePremiumList: [],
  selectedTimeBasePremium: null,
  timeBasePremiumForm: { ...initialTimeBasePremiumForm },
  dynamicWorkTypes: []
};

const actionsMap = {
  [types.SET_SELECTED_TIMEBASE_PREMIUM]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      selectedTimeBasePremium: data,
    };
  },
  [types.SET_EMPLOYER_PROFILE_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      profileDetail: data,
    };
  },
  [types.CLEAR_TIME_BASE_PREMIUM_FORM]: (state) => {
    return {
      ...state,
      timeBasePremiumForm: { ...initialTimeBasePremiumForm },
      selectedTimeBasePremium: null,
    };
  },
  [types.SET_EDIT_TIME_BASE_PREMIUM_FORM]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      timeBasePremiumForm: data ? {
        isForFullShift: data.isForFullShift,
        startTime: moment(data.startTime, "hh:mm:ss"),
        endTime: moment(data.endTime, "hh:mm:ss"),
        type: data.type,
        value: data.value,
        days: data.days
      } : { ...initialTimeBasePremiumForm }
    };
  },
  [types.SET_EMPLOYER_DETAIL_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      employerDetail: data,
    };
  },
  [types.SET_LOADING_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: value,
      },
    };
  },
  [types.SET_DIALOG_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      dialog: {
        ...state.dialog,
        [key]: value,
      },
    };
  },
  [types.SET_TIME_BASE_PREMIUM_LIST]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      timeBasePremiumList: data
    };
  },
  [types.SET_TIME_BASE_PREMIUM_FORM]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      timeBasePremiumForm: {
        ...state.timeBasePremiumForm,
        [key]: value
      }
    }
  },
  [types.SET_DYANMIC_WORK_TYPES_DATA]: (state, { payload }) => {
    const { dynamicWorkTypes } = payload;
    return {
      ...state,
      dynamicWorkTypes
    };
  },
};

export default createReducer(initialState, actionsMap);
