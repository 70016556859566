import { ApolloClient, InMemoryCache, concat, ApolloLink } from "@apollo/client";
import "whatwg-fetch";
import { createUploadLink } from "apollo-upload-client";
import CryptoJS from "crypto-js";

window.global = window;
// @ts-ignore
window.Buffer = window.Buffer || require('buffer').Buffer;

const uri = process.env.REACT_APP_API_ENDPOINT;
const defaultOptions = {
    watchQuery: { errorPolicy: "ignore" },
    query: { errorPolicy: "all", fetchPolicy: "no-cache" },
    mutate: { errorPolicy: "all" },
    connectToDevTools: true,
};
const KEY=process.env.REACT_APP_API_REQUEST_ENCRYPT_TOKEN;

export const getApolloClient = (options = {}) => {
    let canRequest = true;

    const requestMiddleware = async (uri, options) => {
        let encodedRequestBody, body;
        try {
            encodedRequestBody = CryptoJS.AES.encrypt(options.body, KEY).toString();
            body = JSON.stringify({ data: encodedRequestBody });
        } catch (er) {
            body = options.body;
        };

        if (canRequest)
            return fetch(uri, { ...options, body }).then(async (r) => {
                if (r && r.status === 429) {
                    canRequest = false;
                    throw Error("Please reload page and try again");
                } else
                    return r
            }).then(async (r) => {
                if (r?.status === 401) {
                    const b = await r.json();
                    throw new Error(b.message)
                }
                const data = await r?.text?.();
                const bytes = CryptoJS.AES.decrypt(data, KEY);
                const decodedResponseBody = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                return new Response(JSON.stringify(decodedResponseBody), { ...r, "Content-Type": "application/json" });
            });
    };

    // Terminating Link
    // The terminating link is the last link in a link chain
    const UploadLink = createUploadLink({ uri, fetch: requestMiddleware });


    // Auth Link
    // The Auth link is the first link in a link chain
    const authLink = new ApolloLink((operation, forward) => {
        operation.setContext(({ headers = {} }) => {
            return { headers };
        });
        return forward(operation);
    });

    //Link Chain
    const links = concat(authLink, UploadLink);


    const apolloConfig = {
        name: "Grizzly-Employer-Client",
        version: "1.0",
        queryDeduplication: false,
        link: links,
        cache: new InMemoryCache(),
        connectToDevTools: true,
        defaultOptions: {
            ...defaultOptions,
            ...options,
        },
    };
    return new ApolloClient(apolloConfig);
};

export default getApolloClient();
