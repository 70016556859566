import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import StateInfo from "./StateInfo";
const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "22px",
    fontWeight: "bold",
  },
}));
export default function JobHeader() {
  const classes = useStyles();
  // const jobDetail = useSelector(JobDetailSelectors.getJobDetails);
  const jobWorkTypeLabel = useSelector(JobDetailSelectors.getJobWorkTypeLabel);
  return (
    <React.Fragment>
      <div className={classes.header}>
        <div>{jobWorkTypeLabel}</div>
        {/* {jobDetail.id && (
          <StateInfo
            jobId={jobDetail.id}
            timeframe={jobDetail.timeframe}
            status={jobDetail.status}
            isActive={jobDetail.isActive}
            isQueued={jobDetail.isQueued}
          />
        )} */}
      </div>
    </React.Fragment>
  );
}
