import {gql} from "@apollo/client";

export default gql`
query GetPositionDocumentByPositionId($data: GetPositionDocumentInput!) {
  getPositionDocumentByPositionId(data: $data) {
    id
    documentId
    positionId
    documentStatus
    createdAt
    updatedAt
    agreementDocument {
      id
      documentName
      documentUrl
      provinceId
      helpText
      documentType
      createdAt
      updatedAt
      signedDocumentImageUrl
    }
  }
}
`;
