import {gql} from "@apollo/client";

export default gql`
  query skills {
    skills {
      id
      skillName
      frenchSkillName
      isActive
    }
  }
`;