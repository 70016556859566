import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Dialog from "components/Dialog/Dialog";
import { useTranslation } from "react-i18next";

function IncreasePositionsDialog(props) {
  const { open, onClose, onCancel, onConfirm, value, onChange } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      title={t("page_job_details_dialog_increase_positions_title")}
      description={null}
      onClose={onClose}
      onConfirm={onConfirm}
      onCancel={onCancel}
      alertType="question"
      icon="exposure_plus_1">
      <Grid container direction="column" alignItems="center">
        <Typography variant="body1" align="center">
          {t(`page_job_details_dialog_positions_info`)}
        </Typography>
        <TextField
          placeholder={t("page_job_details_dialog_increase_positions_field_placeholder")}
          value={value}
          onChange={onChange}
          type="number"
          margin="normal"
          inputProps={{ min: 1 }}
        />
      </Grid>
    </Dialog>
  );
}

export default IncreasePositionsDialog;
