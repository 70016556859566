import DocRenderer from "../components/DocRenderer";
import ImageRenderer from "../components/ImageRenderer";

export const allowedDocumentsRenderer = [
    {
        Renderer: ImageRenderer,
        "mime": "image/gif"
    },
    {
        Renderer: ImageRenderer,
        "mime": "image/jpeg"
    },
    {
        Renderer: ImageRenderer,
        "mime": "image/tiff"
    },
    {
        Renderer: ImageRenderer,
        "mime": "image/png"
    },
    {
        Renderer: ImageRenderer,
        "mime": "image/svg+xml"
    },
    {
        Renderer: DocRenderer,
        "mime": "application/msword"
    },
    {
        Renderer: DocRenderer,
        "mime": "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    },
    {
        Renderer: DocRenderer,
        "mime": "application/pdf"
    },
];