import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { BanReasonsLabel } from "helpers/constants/index";
import Dialog from "components/Dialog/Dialog";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import { useTranslation } from "react-i18next";

function BanDialog(props) {
  const { open, onClose, onCancel, onConfirm, position } = props;
  const [banReason, setBanReason] = useState("NA");
  const [otherReason, setOtherReason] = useState("");
  const { t } = useTranslation();
  const companyName = useSelector(JobDetailSelectors.getCompanyName);

  const onChangeTextBox = event => {
    if (event.target.value.length <= 100) {
      setOtherReason(event.target.value);
    }
  };
  const onChange = event => {
    setBanReason(event.target.value);
    setOtherReason('')
  };

  const handleBan = () => {
    const reason = banReason === 'Other' ? otherReason : banReason
    onConfirm(reason);
    setBanReason("NA");
  };

  return (
    <Dialog
      open={open}
      title={t("page_job_details_dialog_ban_title",{name:position.fullName})}
      description={t("page_job_details_dialog_ban_desc",{name:position.fullName,companyName})}
      onClose={onClose}
      onConfirm={handleBan}
      onCancel={onCancel}
      alertType="warning"
      icon="not_interested"
      modalType="scrollable"
      disabled={banReason === "NA" || (banReason === "Other" && !otherReason)}>
      <TextField select fullWidth value={banReason} onChange={onChange} margin="normal">
        <MenuItem value="NA">{t("page_job_details_dialog_ban_select_reason")}</MenuItem>
        {Object.keys(BanReasonsLabel).map(key => (
          <MenuItem key={key} value={BanReasonsLabel[key]}>
            {t("common_dynamic_ban_"+key)}
          </MenuItem>
        ))}
      </TextField>
      {banReason === 'Other' && <TextField
        fullWidth
        value={otherReason}
        placeholder={t("common_labels_reason_placeholder")}
        onChange={onChangeTextBox}
        margin="normal"
      />}
    </Dialog>
  );
}

BanDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  position: PropTypes.object,
};

export default BanDialog;
