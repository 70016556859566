import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';

i18n.use(Backend).use(initReactI18next).use(LanguageDetector).init({
    backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    detection: {
        order: [
            'cookie',
            'navigator',
            'htmlTag',
            'localStorage',
            'sessionStorage'
        ],
        caches: [
            "cookie"
        ],
    },
    supportedLngs: ["en", "fr"],
    // fallbackLng: 'en',
    debug: false,
    ns: ['translation'],
    defaultNS: 'translation',
    keySeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ','
    },
    react: {
        wait: true
    }
});

export default i18n;


// export default I18n;