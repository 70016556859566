import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add, DateRange, Event, History, PendingActions, Schedule } from "@mui/icons-material";
import Button from "components/Button/Button";
import MainContent from "components/Containers/MainContent";
import SubNav from "components/Navigation/SubNav";
import SubNavTab from "components/Navigation/SubNavTab";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import { actions as PostJobActions } from "store/modules/postJob";
import HistoryTab from "./components/History";
import TodayTab from "./components/Today";
import TomorrowTab from "./components/Tomorrow";
import UpcomingTab from "./components/Upcoming";
import QueueTab from "./components/Queue";
import { useTranslation } from "react-i18next";

export default function JobOverview(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const timeframeState = useSelector(JobSelectors.getTimeframeStats);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    if (props.history.location.search) {
      const params = new URLSearchParams(props.history.location.search);
      params.append("timeframe", newValue);
      props.history.push(`${props.history.location.pathname}?${params.toString()}`);
    }
    props.history.push(`${props.history.location.pathname}?timeframe=${newValue}`);
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(JobActions.fetchTimeframeStats());
    if (props.history.location.search) {
      const params = new URLSearchParams(props.history.location.search);
      const timeframe = parseInt(params.get("timeframe"), 10);
      if (!isNaN(timeframe)) {
        setValue(parseInt(timeframe, 10));
      }
    } else {
      props.history.push(`${props.history.location.pathname}?timeframe=${value}`);
    }
  }, [props.history.location.pathname]);

  const createJob = () => {
    dispatch(PostJobActions.resetFormData());
    props.history.push("/jobs/post");
  };

  const tabs = () => {
    const isFixedTerm = props?.history?.location?.pathname?.includes("multi-shift");
    return [
      ...(timeframeState.today > 0
        ? [<SubNavTab icon={<Schedule />} key="Today" label={t("page_job_overview_tabs_today")} count={timeframeState.today} />]
        : []),
      ...(timeframeState.tomorrow > 0
        ? [
          <SubNavTab
            icon={<Event />}
            key="Tomorrow"
            label={t("page_job_overview_tabs_tomorrow")}
            count={timeframeState.tomorrow}
          />,
        ]
        : []),
      ...(timeframeState.upcoming > 0
        ? [
          <SubNavTab
            icon={<DateRange />}
            key="upcoming"
            label={t("page_job_overview_tabs_upcoming")}
            count={timeframeState.upcoming}
          />,
        ]
        : []),
      ...(!isFixedTerm ? [<SubNavTab icon={<PendingActions />} key="Queue" label={t("common_labels_queue")} />] : []),
      <SubNavTab icon={<History />} key="History" label={t("page_job_overview_tabs_past")} />,
      <Button
        type="Button"
        key="create"
        style={{ alignSelf: "flex-end", marginBottom: "6px" }}
        label={t("page_job_overview_button_job_create")}
        icon={<Add />}
        alignIcon="left"
        size="small"
        onClick={createJob}
      />,
    ];
  };
  const content = () => {
    const isFixedTerm = props?.history?.location?.pathname?.includes("multi-shift");
    return [
      ...(timeframeState.today > 0
        ? [
          <div key="today">
            <TodayTab history={props.history} />
          </div>,
        ]
        : []),
      ...(timeframeState.tomorrow > 0
        ? [
          <div key="tomorrow">
            <TomorrowTab history={props.history} />
          </div>,
        ]
        : []),
      ...(timeframeState.upcoming > 0
        ? [
          <div key="upcoming">
            <UpcomingTab history={props.history} />
          </div>,
        ]
        : []),
      ...(!isFixedTerm ? [<div key="queue">
        <QueueTab history={props.history} />
      </div>] : []),
      <div key="history">
        <HistoryTab history={props.history} />
      </div>,
    ];
  };
  return (
    <React.Fragment>
      <SubNav value={value} onChange={handleChange} tabs={tabs()} />
      <MainContent>
        {content().map((el, i) => {
          if (i === value) {
            return el;
          }
        })}
      </MainContent>
    </React.Fragment>
  );
}
