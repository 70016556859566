import { makeStyles } from '@mui/styles';
import ReactTableSimple from 'components/ReactTable/ReactTableSimple';
import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ProfileActions, selectors as ProfileSelectors } from 'store/modules/profile';
import { convertUTCToLocal } from 'utils/time';
import iconDateTime from "assets/img/iconDateTime.svg";
import { Cancel, CheckCircle } from '@mui/icons-material';
import { weekDaysList } from 'utils/constant';
import { Grid, Typography } from '@mui/material';
import TimeBasePremiumAction from "./TimeBasePremiumAction";
import { useTranslation } from 'react-i18next';
import moment from "moment";

const useStyles = makeStyles(theme => ({
    main: {
        marginTop: theme.spacing(2),
        "& > .MuiPaper-root": {
            boxShadow: "none"
        }
    },
    progressOverlay: {
        background: "#0000001c",
        width: "100%",
        height: "100%",
        top: 0,
        position: "absolute",
        textAlign: "center"
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    dateTime: {
        marginBottom: theme.spacing(1),
        display: "flex",
        alignItems: "center",
        "& .icon": {
            width: "30px",
            display: "flex",
            alignItems: "center"
        },
        "& .date": {
            fontSize: "16px",
        },
        "& .duration": {
            color: "#848499",
        },
        "& .button": {
            display: "flex",
            marginTop: "10px",
            marginBottom: "10px",
            "& .break": {
                borderRadius: "10px",
                padding: "2px 8px",
                border: "1px solid",
                color: "#00a25b",
                background: "rgb(0 162 91 / 10%)",
                marginRight: "10px",
            },
            "& .recurring": {
                borderRadius: "10px",
                padding: "2px 8px",
                border: "1px solid",
                color: "#0074c8",
                background: "rgb(0 116 200 / 10%)",
            },
        },
    },
    weekDays: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: theme.spacing(1)
    },
    days: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: '1px solid',
        borderRadius: '100px',
        width: '35px',
        height: '35px',
        textAlign: 'center',
        marginRight: '10px',
        cursor: 'pointer',
        marginBottom: '10px',
        borderColor: '#00c8a9 !important',
        color: '#00c8a9 !important',
        background: '#FFF',
        fontWeight: '400'
    },
    secondSection: {
        paddingTop: '15px',
        borderTop: '1px solid #e3e3ec',
        textAlign: 'center',
        '& .leftBlock': {
            borderRight: '1px solid #e3e3ec',
        },
        '& .value': {
            marginBottom: '5px',
            '& span': {
                position: 'relative',
                top: '3px',
                fontSize: '16px',
                fontWeight: '500'
            }
        },
        '& .label': {
            color: '#202326'
        }
    },
}));

export default function TimebasePremiumTable() {
    const dispatch = useDispatch();
    const data = useSelector(ProfileSelectors.getTimeBasePremiumListData);
    const classes = useStyles();
    const isTableLoading = useSelector(ProfileSelectors.getIsTimeBaseListLoading);
    const { t } = useTranslation();
    useEffect(() => {
        dispatch(ProfileActions.fetchTimeBasePremiumList());
    }, []);

    return (
        <ReactTableSimple
            classes={classes}
            loading={isTableLoading}
            data={data}
            columns={[
                {
                    Header: t("time_base_premium_label"),
                    id: "value",
                    disableFilters: true,
                    disableSortBy: true,
                    styles: {
                        width: 200
                    },
                    accessor: ({ value }) => value,
                    Cell: ({ row: { original: { value, type } } }) => {
                        return type === "PERCENTAGE" ? `${value}%` : `$${value}`
                    }
                },
                {
                    Header: t("time_base_premium_start_time_label"),
                    id: "startTime",
                    disableFilters: true,
                    disableSortBy: true,
                    styles: {
                        width: 200
                    },
                    accessor: ({ startTime }) => moment(startTime,"HH:mm:ss").format("hh:mm A"),
                },
                {
                    Header: t("time_base_premium_end_time_label"),
                    id: "endTime",
                    disableFilters: true,
                    disableSortBy: true,
                    styles: {
                        width: 200
                    },
                    accessor: ({ endTime }) => moment(endTime,"HH:mm:ss").format("hh:mm A"),
                },
                {
                    Header: t("time_base_premium_full_shift_label"),
                    id: "isForFullShift",
                    disableFilters: true,
                    styles: {
                        width: 200
                    },
                    disableSortBy: true,
                    accessor: ({ isForFullShift }) => isForFullShift,
                    Cell: ({ row: { original: { isForFullShift } } }) => {
                        return isForFullShift ? <CheckCircle color='secondary' /> : <Cancel color="error" />
                    }
                },
                {
                    Header: t("time_base_premium_days_label"),
                    id: "days",
                    disableFilters: true,
                    disableSortBy: true,
                    styles: {
                        width: 330
                    },
                    accessor: ({ days }) => days,
                    Cell: ({ row: { original: { days } } }) => {
                        return (
                            <div className={classes.weekDays}>
                                {weekDaysList.map(n => days?.includes(n.value) ? <div className={classes.days} key={n.value}>{n.label}</div> : null)}
                            </div>
                        )
                    }
                },
                // {
                //     Header: t("time_base_premium_actions_label"),
                //     id: "action",
                //     disableFilters: true,
                //     disableSortBy: true,
                //     styles: {
                //         textAlign: "right"
                //     },
                //     Cell: ({ row: { original } }) => {
                //         return (
                //             <TimeBasePremiumAction timebasePremium={original} />
                //         )
                //     }
                // },

            ]}
        />
    )
}
