import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, DialogActions, IconButton, MenuItem, Typography, Button as Btn, FormControlLabel, Checkbox, FormControl, RadioGroup, Radio } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import iconEdit from "assets/img/iconEdit.svg";
import avatar from "assets/img/profile-placeholder.png";
import { Form, Formik, useField } from "formik";
import * as yup from 'yup';
import Button from "components/CustomButtons/Button";
import Dialog from "components/Dialog/Dialog";
import { actions as MetaActions } from "store/modules/meta"
import { actions as ProfileActions, selectors as ProfileSelectors } from "store/modules/profile";
import { useTranslation } from "react-i18next";
import { allowedCustomFieldFiles, CustomFieldTypesObject } from "utils/constant";
import { Close, CloudUpload, Description, Visibility } from "@mui/icons-material";
import { useCallback } from "react";
import { getMe } from "store/modules/login/selectors";
import classNames from "classnames";
import { supportedAvatarFiles } from "utils/constant";
import { isValidFile } from "utils/fileUtils";

const controlPadding = {
  leftColumn: { paddingRight: 4 },
  rightColumn: { paddingLeft: 4 },
};

const useStyles = makeStyles((theme) => ({
  countryCode: {
    width: '100%',
    '& .MuiInputBase-root': {
      borderRadius: '4px 0 0 4px'
    },
  },
  phoneNumber: {
    '& .MuiInputBase-root': {
      borderRadius: '0 4px 4px 0'
    },
  },
  avatarImg: {
    margin: '10px auto',
    width: '100px',
    position: 'relative',
    '& img': {
      borderRadius: '100px',
      border: '2px solid #c4c4c4'
    },
    '& label': {
      position: 'absolute',
      right: '6px',
      background: '#ffffff',
      bottom: '0',
      padding: '0px',
      '& img': {
        padding: '2px',
      },
    }
  },
  // formControlLabel: {
  //   "& span": {
  //     color: theme.palette.text.primary,
  //     whiteSpace: "nowrap"
  //   }
  // },
  fieldSet: {
    marginTop: 20,
    border: "1px solid",
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
    paddingTop: 8,
    paddingBottom: 4,
    padding: 4,
    paddingLeft: 8,
    width: "100%",
    position: "relative"
  },
  fieldSetError: {
    borderColor: theme.palette.error.main,
  },
  formControlLabel: {
    width: "100%",
    marginRight: 0,
    paddingRight: theme.spacing(1),
    "& span": {
      // color: theme.palette.text.primary,
      whiteSpace: "nowrap"
    },
    "& .MuiFormControlLabel-label": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    }
  },
  textField: {
    "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
      display: "block",
      paddingRight: `${theme.spacing(4)} !important`
    },
    "& input": {
      textOverflow: "ellipsis"
    }
  },
  legend: {
    position: "absolute",
    background: "white",
    top: -9,
    left: 9,
    marginBottom: 0,
    zIndex: 99
  }
}));
function EditProfileDialog(props) {
  const { open, onClose, onCancel, onConfirm, profileData } = props;
  const { t } = useTranslation();
  const { accountType } = useSelector(getMe);
  const classes = useStyles();
  const [customFileFieldPreviews, setCustomFileFieldPreview] = useState([]);
  const dispatch = useDispatch();
  const [profileURL, setProfileURL] = useState();
  const isLoading = useSelector(ProfileSelectors.getIsUpdatingProfileLoading)
  const customFields = accountType === "employer" ? profileData?.customFieldValues?.map(field => ({
    name: field?.customField?.name,
    value: field?.value,
    isRequired: field?.customField?.isRequired,
    preview: field?.signedCustomFileUrl,
    type: field?.customField?.type,
    id: field?.id,
    options: field?.customField?.getCustomFieldsOptions?.map(op => ({
      label: op?.label,
      value: op?.value,
      id: op?.id
    })) || [],
  }))?.sort((a, b) => (a?.type < b?.type ? 1 : a?.type > b?.type ? -1 : 0)) : [];

  const handleProfileFile = file => {
    const response = isValidFile(file);

    if (response.isValid) {
      const item = new Blob([file], { type: file.type });
      item.name = `profile_${file.name}`;
      const profileURLPreview = profileURL;
      setProfileURL(URL.createObjectURL(item))
      dispatch(ProfileActions.uploadEmployerProfileImage(item)).then((r) => {
        if (r && r?.message) setProfileURL(profileURLPreview);
      });
    } else {
      dispatch(MetaActions.errorToast(response.message));
    }
  }

  const handleUploadCustomFieldFile = useCallback((file, field, handlers) => {
    if (allowedCustomFieldFiles.find(a => a.mime === file.type)) {
      const item = new Blob([file], { type: file.type });
      item.name = `employer_${file.name}`;
      const callback = (url) => {
        handlers.setValue(url.filepath, true);
        const index = customFileFieldPreviews?.findIndex(a => Object.keys(a)?.[0] == field?.id);
        setCustomFileFieldPreview(p => Object.assign([], p, { [index >= 0 ? index : customFileFieldPreviews.length]: { [`${field?.id}`]: { path: url.filepath, preview: url.fileSignedurl } } }));
      }
      dispatch(ProfileActions.uploadCustomFieldFile(item, callback));
    } else {
      dispatch(MetaActions.errorToast(t("common_errors_file_type_not_supported")));
    };
  }, [customFileFieldPreviews]);

  useEffect(() => {
    setProfileURL(profileData.signedProfileImageUrl)
  }, [profileData.signedProfileImageUrl]);

  const OptionRenderer = ({ field, ...props }) => {
    const [formikProps, meta, handlers] = useField(field?.id?.toString());

    switch (field?.type) {
      case CustomFieldTypesObject.FILE: {
        const file = customFileFieldPreviews?.find(a => Object.keys(a)?.[0] == field?.id);

        return (
          <Grid xs={12} textAlign="left" marginTop={1.5}>
            <div className={classNames(classes.fieldSet, (meta.error && meta.touched) && classes.fieldSetError)}>
              <legend className={classes.legend}>
                <Typography fontSize={"11px"} variant="body2" color={(meta.error && meta.touched) ? "error" : "grey.700"}>{field?.name}</Typography>
              </legend>
              <Grid item xs textAlign="left">
                {formikProps.value ?
                  <>
                    <Grid container alignItems={"center"} border={1} borderColor="rgba(0, 0, 0, 0.23)" borderRadius={1} overflow="hidden">
                      <Grid xs={10.5} display="flex" alignItems={"center"} overflow="hidden">
                        <Avatar src={file?.[`${field?.id}`]?.preview || field.preview} variant="square" sx={{ bgcolor: "grey.400", marginRight: 1 }}>
                          <Description />
                        </Avatar>
                        <Typography title={file?.[`${field?.id}`]?.path || field.value} variant="body2" whiteSpace={"nowrap"} overflow="hidden" textOverflow={"ellipsis"}>
                          {file?.[`${field?.id}`]?.path || field.value}
                        </Typography>
                      </Grid>
                      <Grid item xs={1.5} display="flex" justifyContent="center">
                        <IconButton size="small" href={file?.[`${field?.id}`]?.preview || field.preview} target="_blank">
                          <Visibility fontSize='small' />
                        </IconButton>
                      </Grid>
                      {/* <Grid item xs={1.5} display="flex" justifyContent="center">
                        <IconButton size="small" onClick={() => {
                          handlers.setValue("", true);
                          const data = [...customFileFieldPreviews];
                          const index = data?.findIndex(a => Object.keys(a)?.[0] == field?.id);
                          index >= 0 && data.splice(index, 1);
                          setCustomFileFieldPreview(data);
                        }}>
                          <Close fontSize='small' />
                        </IconButton>
                      </Grid> */}
                    </Grid>
                  </>
                  : (
                    <Btn style={{ marginTop: 2, marginBottom: 2, border: "none" }} color="secondary" disabled variant="outlined" component="label">
                      File Not Uploaded
                      {/* Select File
                      <input
                        accept={allowedCustomFieldFiles.map(a => a.mime).join(",")}
                        type="file"
                        hidden
                        onChange={files => handleUploadCustomFieldFile(files.target.files[0], field, handlers)} /> */}
                    </Btn>
                  )
                }
              </Grid>
            </div >
            {
              meta.error && meta.touched && meta.error && <Grid xs={12} marginLeft={2}>
                <Typography variant="body2" color="error" id={field?.name}>{meta.error}</Typography>
              </Grid>
            }
          </Grid >
        )
      };
      case CustomFieldTypesObject.CHECKBOX: {
        return (
          <Grid xs={12} textAlign="left" marginTop={1.5}>
            <div className={classNames(classes.fieldSet, (meta.error && meta.touched) && classes.fieldSetError)}>
              <legend className={classes.legend}>
                <Typography fontSize={"11px"} variant="body2" color={(meta.error && meta.touched) ? "error" : "grey.700"}  >{field?.name}</Typography>
              </legend>
              <Grid textAlign="left" container>
                {field?.options?.map(option => (
                  <Grid key={option?.id?.toString()} item xs={12} sm={6} overflow={"hidden"}>
                    <FormControlLabel
                      disabled
                      color="primary"
                      className={classes.formControlLabel}
                      label={option.label}
                      title={option.label}
                      onChange={() => {
                        const data = JSON.parse(formikProps.value || "[]")
                        if (data?.length) {
                          const ind = data?.findIndex(a => a == option.id);
                          if (ind >= 0) data?.splice(ind, 1);
                          else data.push(option?.id);
                        } else data?.push(option.id);
                        handlers.setValue(data?.length ? JSON.stringify(data) : '', true);
                      }}
                      checked={JSON.parse(formikProps.value || "[]")?.includes(option.id)}
                      control={<Checkbox />} />
                  </Grid>
                ))}
              </Grid>
            </div>
            {meta.error && meta.touched && meta.error && <Grid xs={12} marginLeft={2}>
              <Typography variant="body2" color="error" id={field?.name}>{meta.error}</Typography>
            </Grid>}
          </Grid>
        )
      };
      case CustomFieldTypesObject.RADIO: {
        return (
          <Grid xs={12} textAlign="left" marginTop={1.5}>
            <div className={classNames(classes.fieldSet, (meta.error && meta.touched) && classes.fieldSetError)}>
              <legend className={classes.legend}>
                <Typography fontSize={"11px"} variant="body2" color={(meta.error && meta.touched) ? "error" : "grey.700"}  >{field?.name}</Typography>
              </legend>
              <Grid textAlign="left" container>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby={field?.name}
                    {...formikProps}
                    disabled
                  >
                    {field?.options?.map(option => (
                      <Grid key={option?.id?.toString()} item xs={12} sm={6}>
                        <FormControlLabel
                          disabled
                          color="primary"
                          value={option?.id?.toString()}
                          className={classes.formControlLabel}
                          label={option.label}
                          title={option.label}
                          control={<Radio />} />
                      </Grid>
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </div>
            {meta.error && meta.touched && meta.error && <Grid xs={12} marginLeft={2}>
              <Typography variant="body2" color="error" id={field?.name}>{meta.error}</Typography>
            </Grid>}
          </Grid>
        )
      };
      case CustomFieldTypesObject.SELECT: {
        return (
          <Grid item xs textAlign="left" marginTop={.5} >
            <TextField
              select
              id={field?.name}
              label={field?.name}
              className={classes.textField}
              fullWidth
              margin="normal"
              {...formikProps}
              {...meta}
              error={meta.error && meta.touched}
              helperText={
                meta.error && meta.touched && meta.error
              }
              disabled
            >
              <MenuItem value={''}><em>Empty</em></MenuItem>
              {
                field?.options?.map(op => (
                  <MenuItem key={op.id} value={op?.id?.toString()}>{op.label}</MenuItem>
                ))
              }
            </TextField>
          </Grid>
        )
      };
      default: {
        return (
          <>
            <Grid item xs textAlign="left" marginTop={.5}>
              <TextField
                id={field?.name}
                label={field?.name}
                className={classes.textField}
                fullWidth
                margin="normal"
                {...formikProps}
                {...meta}
                error={meta.error && meta.touched}
                helperText={
                  meta.error && meta.touched && meta.error
                }
                disabled
              />
            </Grid>
          </>
        )
      };
    };
  };

  return (
    <Dialog
      open={open}
      title={t("page_profile_dialog_profile_title")}
      onClose={onClose}
      onCancel={onCancel}
      alertType={'form'}
      hideActions
      size="sm"
      modalType="scrollable"
      disableBackdropClick={false}
    >
      <Grid item xs={12} style={{ width: "100%", paddingLeft: 0, paddingRight: 0 }}>
        <Formik
          initialValues={{
            // title: '',
            firstName: profileData.firstName,
            lastName: profileData.lastName,
            email: profileData.email,
            phoneNumber: profileData.mobilePhone,
            ...(accountType === "employer" ? {
              invoicingJDENumber: profileData.invoicingJDENumber,
              companyJDENumber: profileData.companyJDENumber
            } : {}),
            countryCode: profileData.countryCode,
            ...(customFields?.reduce((p, c) => ({ ...p, [`${c?.id}`]: c?.value || "" }), {}) || {}),
          }}
          onSubmit={(values, actions) => {
            onConfirm(values, () => setCustomFileFieldPreview([]));
            actions.setSubmitting(false);
          }}
          validationSchema={yup.object().shape({
            email: yup.string().email(t("common_errors_enter_valid_email")).required(t("common_errors_required")).max(100, t("common_errors_max_email_length", { length: 100 })),
            // title: yup.string().required(t("common_errors_required")),
            firstName: yup.string().required(t("common_errors_required")).max(55, t("common_errors_max_first_name_length", { length: 55 })),
            lastName: yup.string().required(t("common_errors_required")).max(55, t("common_errors_max_last_name_length", { length: 55 })),
            phoneNumber: yup.string().max(10, t("common_errors_enter_valid_phone")).min(10, t("common_errors_enter_valid_phone")).required(t("common_errors_required")),
            ...(accountType === "employer" ? {
              invoicingJDENumber: yup.string().required(t("common_errors_required")),
              companyJDENumber: yup.string().required(t("common_errors_required")),
            } : {}),
            countryCode: yup.string().required(t("common_errors_required")),
            ...(customFields?.reduce((p, c) => {
              let yupObj = yup;
              yupObj = yupObj.string();
              // if (c.type === CustomFieldTypesObject.TEXTBOX) yupObj = yupObj.max(50, t("common_errors_max_length", { name: c.name, length: 50 }));
              // if (c.isRequired) yupObj = yupObj.required(t("dynamic_error_custom_field", { name: c.name }));

              const validation = {
                [`${c?.id}`]: yupObj
              }
              return {
                ...p,
                ...validation
              }
            }, {}) || {})
          })}>
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              dirty
            } = props;
            return (
              <Form>
                <Grid
                  item
                  container
                  direction="row"
                  xs={12}
                >
                  <Grid container item xs={12} md={12} spacing={1}>
                    <div className={classes.avatarImg}>
                      <img width={'100'} height={'100'} src={profileURL || avatar} onError={(e) => { e.target.onerror = null; e.target.src = avatar }} alt="..." />
                      <IconButton className="edit-icon" disableRipple component="label">
                        <img src={iconEdit} />
                        <input type="file" accept={supportedAvatarFiles.map(a => a.mime).join(",")} hidden onChange={files => handleProfileFile(files.target.files[0])} />
                      </IconButton>
                    </div>
                  </Grid>
                  <Grid container item xs={12} md={12} spacing={1}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="firstName"
                        name="firstName"
                        label={`${t("common_labels_first_name")} *`}
                        className={classes.textField}
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        disabled={!!profileData.firstName}
                        error={errors.firstName && touched.firstName}
                        helperText={errors.firstName && touched.firstName && errors.firstName}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="lastName"
                        name="lastName"
                        label={`${t("common_labels_last_name")} *`}
                        className={classes.textField}
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        disabled={!!profileData.lastName}
                        error={errors.lastName && touched.lastName}
                        helperText={errors.lastName && touched.lastName && errors.lastName}
                        margin="normal"
                      />
                    </Grid>

                  </Grid>
                  {/* <Grid item xs={12} md={12} >
                    <TextField
                      id="title"
                      name="title"
                      label="Title"
                      className={classes.textField}
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={errors.title && touched.title}
                      helperText={errors.title && touched.title && errors.title}
                      margin="normal"
                    />
                  </Grid> */}
                  <Grid container item xs={12} md={12}>
                    <TextField
                      id="email"
                      name="email"
                      label={`${t("common_labels_email")} *`}
                      className={classes.textField}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={errors.email && touched.email}
                      helperText={errors.email && touched.email && errors.email}
                      margin="normal"
                      disabled
                    />
                  </Grid>
                  <Grid container direction="row" item xs={12} sm={12}>
                    <Grid container item xs={4} sm={2}>
                      <TextField
                        select
                        name="countryCode"
                        label={`${t("common_labels_code")} *`}
                        className={classes.countryCode}
                        value={values.countryCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.countryCode && touched.countryCode && errors.countryCode
                        }
                        margin="normal"
                        disabled
                      >
                        <MenuItem value={'+1'}>
                          +1
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid container item xs={8} sm={10}>
                      <TextField
                        name="phoneNumber"
                        label={`${t("common_labels_mobile_phone")} *`}
                        className={classes.phoneNumber}
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        type="number"
                        error={errors.phoneNumber && touched.phoneNumber}
                        helperText={
                          errors.phoneNumber && touched.phoneNumber && errors.phoneNumber
                        }
                        margin="normal"
                        disabled
                      />
                    </Grid>
                    {/* {accountType === "employer" && <>
                      <Grid container item xs={6} sm={6}>
                        <TextField
                          name="invoicingJDENumber"
                          label={`${t("common_labels_invoicingJDENumber")}`}
                          className={classes.invoicingJDENumber}
                          value={values.invoicingJDENumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          error={errors.invoicingJDENumber && touched.invoicingJDENumber}
                          helperText={
                            errors.invoicingJDENumber && touched.invoicingJDENumber && errors.invoicingJDENumber
                          }
                          margin="normal"
                          disabled
                          style={controlPadding.leftColumn}
                        />
                      </Grid>
                      <Grid container item xs={6} sm={6}>
                        <TextField
                          name="companyJDENumber"
                          label={`${t("common_labels_companyJDENumber")}`}
                          className={classes.companyJDENumber}
                          value={values.companyJDENumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          error={errors.companyJDENumber && touched.companyJDENumber}
                          helperText={
                            errors.companyJDENumber && touched.companyJDENumber && errors.companyJDENumber
                          }
                          margin="normal"
                          disabled
                          style={controlPadding.rightColumn}
                        />
                      </Grid>
                    </>} */}
                    {
                      customFields?.map((field, i) => {
                        return (
                          <Grid
                            container
                            item
                            xs={6}
                            key={field?.id?.toString()}
                            display="block"
                            style={controlPadding[i % 2 === 0 ? "leftColumn" : "rightColumn"]}>
                            <OptionRenderer field={field} />
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                </Grid>
                <DialogActions className={classes.footer}>
                  <Button
                    type="button"
                    className="outline"
                    onClick={onCancel}
                    color="transparent"
                    round
                  >
                    {t("common_labels_close")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    round
                    disabled={!_.isEmpty(errors) || isLoading || !dirty}
                  >
                    {t("common_labels_save")}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Dialog >
  );
}

export default EditProfileDialog;
