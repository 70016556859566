import {gql} from "@apollo/client";

export default gql`
  query getJobAnnouncementByEmployerId(
    $employerId: ID,
    $archive:Boolean
  ) {
    jobAnnouncementByEmployerId(
      employerId: $employerId,
      archive: $archive
    ) {
        id
        employerId
        managerId
        start
        end
        startUtc
        endUtc
        timezone
        peopleNeeded
        city
        region
        positionsFilled
        checkedInCount
        checkedOutCount
        skills
        workType{
          id
          label
          frenchName
        }
    jobAnnouncement{
        id
        title
        employerId
        managerId
        description
        frequency
        dayOrWeekDay
        jobAnnouncementTime{
            sendAt
        }
        workers{
            workerId
            worker{
              fullName
              firstName
              signedProfileImageUrl
            }
        }
        employer{
            id
            fullName
        }
        manager{
            id
            firstName
            lastName
        }
    }
    }
  }
`;
