import {gql} from "@apollo/client";

// first is zero because we only want to return the totals and no specific info
export default gql`
  fragment StatsFields on JobStats {
    count
    bookings
    revenue
    hours
  }

  query getJobListStats($employerId: String, $timeframe: Timeframes!, $timezone: String, $isFixedTerm: Boolean) {
    total: jobsStats(employerId: $employerId, timeframe: $timeframe, isActive: true, timezone: $timezone, isFixedTerm: $isFixedTerm) {
      ...StatsFields
    }
    active: jobsStats(
      employerId: $employerId,
      timeframe: $timeframe
      status: InProgress
      isActive: true
      timezone: $timezone
      isFixedTerm: $isFixedTerm
    ) {
      ...StatsFields
    }
    unfilled: jobsStats(
      employerId: $employerId,
      timeframe: $timeframe
      status: NotStarted
      isActive: true
      filled: false
      timezone: $timezone
      isFixedTerm: $isFixedTerm
    ) {
      ...StatsFields
    }
    filled: jobsStats(
      employerId: $employerId,
      timeframe: $timeframe
      status: NotStarted
      isActive: true
      filled: true
      timezone: $timezone
      isFixedTerm: $isFixedTerm
    ) {
      ...StatsFields
    }
    completed: jobsStats(
      employerId: $employerId,
      timeframe: $timeframe
      status: Completed
      isActive: true
      timezone: $timezone
      isFixedTerm: $isFixedTerm
    ) {
      ...StatsFields
    }
  }
`;
