import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent } from "@mui/material";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";
import { actions as SettingsActions, selectors as SettingsSelectors } from "store/modules/settings";
import CompanyTemplateMenu from "./CompanyTemplateMenu";
import { useTranslation } from "react-i18next";
import i18n from "i18n";

function CompanyJobTemplate({ history }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isFrenchLanguage = i18n.language === "fr";
  
  const companyJobTemplateList = useSelector(SettingsSelectors.getCompanyJobTemplateListData);
  useEffect(() => {
    dispatch(SettingsActions.fetchCompanyJobTemplate());
  }, []);

  const columns = [
    {
      Header: t("common_table_column_name"),
      id: "templateName",
      accessor: ({ templateName }) => templateName,
    },
    {
      Header: t("common_table_column_work_type"),
      id: "workType",
      accessor: ({ workType }) => workType[isFrenchLanguage?"frenchName":"label"],
    },
    {
      Header: t("common_table_column_address"),
      id: "street",
      accessor: ({ street }) => street,
      Cell: ({ row: { original: { street, city, region } } }) => (
        <>
          {(street || "") + ' ' + (city || "") + ' ' + (region || "")}
        </>
      ),
    },
    {
      Header: t("common_table_column_action"),
      id: "id",
      accessor: "id",
      disableFilters: true,
      styles: {
        textAlign: 'right'
      },
      Cell: ({ row: { original } }) => {
        return (
          <>
            <CompanyTemplateMenu
              data={original}
              history={history}
            />
          </>
        )
      }
    },
  ];

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <ReactTableSimple
            columns={columns}
            data={companyJobTemplateList}
            initialSort={[{ id: 'templateName', desc: false }]}
          />
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default CompanyJobTemplate;
