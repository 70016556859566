import { makeStyles } from '@mui/styles';
import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ProfileActions, selectors as ProfileSelectors } from 'store/modules/profile';
import ReactTableSimple from 'components/ReactTable/ReactTableSimple';
import { formatMoney } from 'utils/stringUtils';
import { useTranslation } from 'react-i18next';
import i18n from "i18n";

const useStyles = makeStyles(theme => ({
    main: {
        marginTop: theme.spacing(2),
        "& > .MuiPaper-root": {
            boxShadow: "none"
        }
    },
    progressOverlay: {
        background: "#0000001c",
        width: "100%",
        height: "100%",
        top: 0,
        position: "absolute",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
}));

export default function DynamicWorkTypeRates(props) {
    const dispatch = useDispatch();
    const data = useSelector(ProfileSelectors.getDynamicWorkTypeRateListData);
    const classes = useStyles();
    const isTableLoading = useSelector(ProfileSelectors.getIsDynamicWorkTypeRateListLoading);
    const { t } = useTranslation();
    const isFrenchLanguage = i18n.language === "fr";

    useEffect(() => {
        dispatch(ProfileActions.fetchDynamicWorkTypeRate());
    }, []);

    return (
        <ReactTableSimple
            classes={classes}
            loading={isTableLoading}
            data={data}
            columns={[
                {
                    Header: t("common_table_column_work_type"),
                    id: "workType",
                    disableFilters: true,
                    disableSortBy: true,
                    accessor: ({ workType }) => workType?.[isFrenchLanguage ? "frenchName" : "label"],
                    Cell: ({ row: { original: { workType } } }) => {
                        return workType?.[isFrenchLanguage ? "frenchName" : "label"] || ""
                    }
                },
                {
                    Header: t("dynamic_work_type_pay_rate_label"),
                    id: "payRate",
                    disableFilters: true,
                    disableSortBy: true,
                    accessor: ({ payRate }) => payRate,
                    Cell: ({ row: { original: { payRate } } }) => {
                        return formatMoney(payRate)
                    }
                },
                {
                    Header: t("dynamic_work_type_billing_rate_label"),
                    id: "billingRate",
                    disableFilters: true,
                    disableSortBy: true,
                    accessor: ({ billingRate }) => billingRate,
                    Cell: ({ row: { original: { billingRate } } }) => {
                        return formatMoney(billingRate)
                    }
                },
            ]}
        />
    )
}
