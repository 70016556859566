import React from "react";
import DisableCellOverlay from "../DisableCellOverlay";
import PositionMenu from "../Menus/PositionMenu";
import PositionTableMenu from "../Menus/PositionTableMenu";

export default function usePositionMenu({
    isWorkerPickerOpen,
    disabled,
    isEditing,
    // handleRatingAll,
    handleCheckingInAll,
    handleCheckingOutAll,
    toggleIncreasePositions,
    toggleDecreasePositions,
    onEditSave,
    onEditCancel,
}) {
    
    return {
        Header: (
            <PositionTableMenu
                isEditing={isEditing}
                // triggerRateAll={handleRatingAll}
                triggerCheckInAll={handleCheckingInAll}
                triggerCheckOutAll={handleCheckingOutAll}
                triggerIncreasePositions={toggleIncreasePositions}
                triggerDecreasePositions={toggleDecreasePositions}
                onEditSave={onEditSave}
                onEditCancel={onEditCancel}
            />
        ),
        id: "actions",
        width: 60,
        sortable: false,
        styles: { textAlign: "right" },
        Cell: ({ row: { original } }) => {
            // original.workerRatings = ratingOption.map(n => ({ rating: 3, ratingOptionId: n.id }))
            return (
                <>
                    <PositionMenu position={original} isWorkerPickerOpen={isWorkerPickerOpen} />
                    <DisableCellOverlay visible={disabled} />
                </>
            )
        }
    };
}
