import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import DecreasePositionsDialog from "./Dialogs/DecreasePositionsDialog";
import IncreasePositionsDialog from "./Dialogs/IncreasePositionsDialog";
import PositionStatus from "./PositionStatus";
import useAvatar from "./table-column/avatar";
import useBreakMins from "./table-column/breakMins";
import useCheckIn from "./table-column/checkIn";
import useCheckOut from "./table-column/checkOut";
import useConfirmed from "./table-column/confirmed";
import useLocation from "./table-column/location";
import useName from "./table-column/name";
import usePositionMenu from "./table-column/positionMenu";
import useRating from "./table-column/rating";
import useReConfirmed from "./table-column/reconfirmed";
import usePreferBan from "./table-column/usePreferBan";

const useStyles = makeStyles(({ shape, typography, spacing }) => ({
  table: {
    flex: 1,
    border: "none",
    borderRadius: shape.borderRadius / 2,
    width: "100%",
  },
  icon: {
    fontSize: typography.pxToRem(20),
    marginRight: spacing(2),
  },
}));

function PositionTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const positions = useSelector(JobDetailSelectors.getPositions);
  const jobDetails = useSelector(JobDetailSelectors.getJobDetails);
  const isEditing = props.isRatingAll || props.isCheckingInAll || props.isCheckingOutAll;

  const batchActionDisable = false;
  const [isIncreasePositions, setIsIncreasePositions] = useState(false);
  const [isDecreasePositions, setIsDecreasePositions] = useState(false);
  const [changeBy, setChangeBy] = useState("");
  const toggleIncreasePositions = useCallback(() => {
    setIsIncreasePositions(v => !v);
    setChangeBy("");
  }, []);

  const toggleDecreasePositions = useCallback(() => {
    setIsDecreasePositions(v => !v);
    setChangeBy("");
  }, []);
  const handleIncreasePositions = () => {
    toggleIncreasePositions();
    dispatch(JobDetailActions.increasePositions(parseInt(changeBy, 10)));
  };

  const handleDecreasePositions = () => {
    toggleDecreasePositions();
    dispatch(JobDetailActions.decreasePositions(parseInt(changeBy, 10)));
  };

  const onValueChange = useCallback(e => {
    if (e.currentTarget.value <= 1000) {
      setChangeBy(e.currentTarget.value);
    }
  }, []);

  const columns = [
    useAvatar(batchActionDisable),
    useName(batchActionDisable),
    usePreferBan(batchActionDisable),
    useLocation(batchActionDisable),
    // useRating(props.isRatingAll),
    useCheckIn(props.isCheckingInAll, batchActionDisable, jobDetails.timezone),
    useCheckOut(props.isCheckingOutAll, batchActionDisable, jobDetails.timezone),
    useBreakMins(props.isCheckingOutAll, batchActionDisable),
    usePositionMenu({
      isWorkerPickerOpen: props.isWorkerPickerOpen,
      onChooseWorker: props.onChooseWorker,
      isEditing: isEditing,
      disabled: batchActionDisable,
      handleRatingAll: props.handleRatingAll,
      handleCheckingInAll: props.handleCheckingInAll,
      handleCheckingOutAll: props.handleCheckingOutAll,
      toggleIncreasePositions: toggleIncreasePositions,
      toggleDecreasePositions: toggleDecreasePositions,
      onEditCancel: props.onEditCancel,
      onEditSave: props.onEditSave,
    }),
  ];

  return (
    <>
      <ReactTableSimple
        title={<PositionStatus />}
        showPaginationBottom={true}
        filterable={false}
        showPageJump={false}
        showPaginationTop={false}
        resizable={false}
        className={classes.table}
        minRows={0}
        data={positions}
        // loading={isLoading}
        columns={columns}
      />
      <IncreasePositionsDialog
        open={isIncreasePositions}
        onClose={toggleIncreasePositions}
        onCancel={toggleIncreasePositions}
        onConfirm={handleIncreasePositions}
        value={changeBy}
        onChange={onValueChange}
      />
      <DecreasePositionsDialog
        open={isDecreasePositions}
        onClose={toggleDecreasePositions}
        onCancel={toggleDecreasePositions}
        onConfirm={handleDecreasePositions}
        value={changeBy}
        onChange={onValueChange}
      />
    </>
  );
}

export default PositionTable;
