// Define a default UI for filtering
import React from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

function DefaultColumnFilter({
  // eslint-disable-next-line react/prop-types
  column: { filterValue, setFilter },
}) {
  const { t } = useTranslation();

  return (
    <TextField
      name="name"
      size="small"
      variant="standard"
      className="filter-textfield"
      value={filterValue || ''}
      autoComplete="off"
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={t("common_table_search")}
    />
  );
}
export default DefaultColumnFilter;
