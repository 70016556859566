import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import MainContent from "components/Containers/MainContent";
import SubNav from "components/Navigation/SubNav";
import SubNavTab from "components/Navigation/SubNavTab";
import { getMe } from "store/modules/login/selectors";
import { actions as ProfileActions, selectors as ProfileSelectors } from "store/modules/profile";
import { actions as SettingsActions, selectors as SettingsSelectors } from "store/modules/settings";
import CompanyJobTemplate from "./components/CompanyJobTemplate";
import MyJobTemplate from "./components/MyJobTemplate";
import { useTranslation } from "react-i18next";

function Settings({ history }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const { t } = useTranslation();

  const timezoneList = useSelector(SettingsSelectors.getTimezoneListData);
  const profileDetail = useSelector(ProfileSelectors.getProfileData);
  const { accountType } = useSelector(getMe);

  const params = new URLSearchParams(history?.location?.search);
  const view = parseInt(params?.get?.("view"), 10);

  const handleChangeTab = (event, newValue) => {
    history?.replace?.(`?view=${newValue}`);
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(SettingsActions.fetchTimezone());
    dispatch(ProfileActions.fetchProfile());
    dispatch(SettingsActions.fetchMyJobTemplate());

    if (history?.location?.search) {
      if (!isNaN(view)) {
        setValue(parseInt(view, 10));
      }
    } else {
      history?.replace?.(`?view=${value}`);
    }

  }, []);

  const handleChange = e => {
    if (e.target.value) {
      dispatch(SettingsActions.uploadTimezone(e.target.value));
    }
  };
  const handleChangeNotification = () => {
    dispatch(SettingsActions.uploadNotification());
  };


  const tabs = () => {
    return [
      <SubNavTab key="my-template" label={t("component_template_title_my")} />,
      <SubNavTab key="company-template" label={t("component_template_title_company")} />
    ];
  };
  return (
    <React.Fragment>
      <MainContent>
        <Grid container display="row" spacing={2}>
          {/* {accountType === "employer" && (
            <Grid item xs={12} sm={12} md={6}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Timezone
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel id="timezone-label">Timezone</InputLabel>
                    <Select
                      labelId="timezone-label"
                      id="timezone"
                      value={profileDetail.timezone}
                      label="Timezone"
                      onChange={handleChange}
                    >
                      {timezoneList &&
                        timezoneList.map(n => (
                          <MenuItem key={n.id} value={n.timezoneName}>
                            {n.timezoneName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>
          )}
          {accountType === "employer" && (
            <Grid item xs={12} sm={12} md={6}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Notification
                  </Typography>
                  <Grid container display="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={profileDetail.jobNotification}
                            onChange={handleChangeNotification}
                            name="gilad"
                          />
                        }
                        label="Job Reminder"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )} */}
          <Grid item xs={12} sm={12} md={12}>
            <SubNav value={value} onChange={handleChangeTab} tabs={tabs()} />
            {value === 0 && <MyJobTemplate history={history} />}
            {value === 1 && <CompanyJobTemplate history={history} />}
          </Grid>
        </Grid>
        <Grid container display="row" spacing={2} />
      </MainContent>
    </React.Fragment>
  );
}

export default Settings;
