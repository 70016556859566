import ApolloClient from "utils/apollo";
import { flattenGraphQLArray } from "utils/graphql";
import { convertUTCToTimezoneWithOffset, getTimeZone } from "utils/time";
import { actions as MetaActions } from "store/modules/meta";
import { getMe } from "../login/selectors";
import BanWorkerMutation from "./graphql/mutations/ban-worker";
import CancelJobMutation from "./graphql/mutations/cancel-job";
import CancelRecurringJobMutation from "./graphql/mutations/cancel-recurring-job";
import CheckInAllMutation from "./graphql/mutations/checkin-all-workers";
import CheckInMutation from "./graphql/mutations/checkin-worker";
import CheckOutAllMutation from "./graphql/mutations/checkout-all-workers";
import CheckOutMutation from "./graphql/mutations/checkout-worker";
import ClearExceptionMutation from "./graphql/mutations/clear-exception";
import DecreaseJobPositionsMutation from "./graphql/mutations/decrease-positions";
import DeleteJobMutation from "./graphql/mutations/delete-job";
import IncreaseJobPositionsMutation from "./graphql/mutations/increase-positions";
import NoShowPositionMutation from "./graphql/mutations/noshow-worker";
import PreferWorkerMutation from "./graphql/mutations/prefer-worker";
import RateAllWorkersMutation from "./graphql/mutations/rating-all-workers";
import RatingWorkerMutation from "./graphql/mutations/rating-worker";
import FetchJobQuery from "./graphql/queries/fetch-job";
import FetchJobWorkersQuery from "./graphql/queries/fetch-job-workers";
import FetchPositionsQuery from "./graphql/queries/fetch-positions";
import FetchRatingOptionQuery from "./graphql/queries/fetch-rating-option";
import FetchRecurringJobQuery from "./graphql/queries/fetch-recurring-job";
import types from "./types";
import i18n from "i18n";
import FetchScheduledJobsQuery from "./graphql/queries/fetch-scheduled-jobs";
import FetchMultiShiftJobPositionQuery from "./graphql/queries/fetch-multi-shift-positions";
import RemovePositionMutation from "./graphql/mutations/remove-worker";

const setLoadingState = key => value => ({
  type: types.SET_LOADING_STATE,
  payload: { key, value },
});

const setIsPageLoading = setLoadingState("page");
const setIsUpdatingPositions = setLoadingState("updatingPositions");
const setScheduledJobLoading = setLoadingState("scheduledJobLoading");
const setIsDeleteJobLoading = setLoadingState("isDeleteJobLoading");

export const setIsCancelJobDialogState = value => ({
  type: types.SET_IS_CANCEL_JOB_DIALOG_STATE,
  payload: { value },
});

const reset = () => ({
  type: types.RESET,
});

const setJob = job => ({
  type: types.SET_JOB,
  payload: { job },
});

export const removePosition = workerId => ({
  type: types.REMOVE_POSITION,
  payload: { workerId },
});

const setJobWorkers = workers => ({
  type: types.SET_JOB_WORKERS,
  payload: { workers },
});

const setScheduledJobs = jobs => ({
  type: types.SET_SCHEDULED_JOB,
  payload: { jobs },
});

const setMultiShiftJobPositionList = (data) => ({
  type: types.SET_MULTI_SHIFT_JOB_POSITIONS,
  payload: data
});


export const removePositionByPositionId = workerId => ({
  type: types.REMOVE_POSITION_BY_POSITION_ID,
  payload: { workerId },
});

export const fetchJobWorkers = (jobId, employerId) => dispatch => {
  return ApolloClient.query({
    query: FetchJobWorkersQuery,
    variables: { jobId, employerId },
  }).then(({ data }) => {
    const workers = flattenGraphQLArray(data.workers);
    dispatch(setJobWorkers(workers));
  });
};

export const fetchJob = (id, isPageLoading = true, isMultiShift = false) => dispatch => {
  dispatch(setIsPageLoading(isPageLoading));
  return ApolloClient.query({
    query: FetchJobQuery,
    variables: { id: id, timezone: getTimeZone() },
  })
    .then(async ({ data: { job } }) => {
      dispatch(setJob(job));
      isMultiShift && await dispatch(fetchMultiShiftJobPositions(job.id));
      return dispatch(fetchJobWorkers(job.id, job.employerId));
    })
    .then(() => {
      dispatch(setIsPageLoading(false));
    })
    .catch(e => {
      dispatch(setIsPageLoading(false));
      dispatch(MetaActions.errorToast(i18n.t("toast_error_job_detail_not_found", { message: e.message })));
    });
};

export const fetchScheduledJobs = (id) => dispatch => {
  dispatch(setScheduledJobLoading(true));
  return ApolloClient.query({
    query: FetchScheduledJobsQuery,
    variables: { id: +id, timezone: getTimeZone() },
  })
    .then(({ data: { getFixedTermJobs } }) => {
      dispatch(setScheduledJobs(getFixedTermJobs));
      dispatch(setScheduledJobLoading(false));
    })
    .catch(e => {
      dispatch(setScheduledJobLoading(false));
      dispatch(MetaActions.errorToast(i18n.t("toast_error_job_detail_get_scheduled_job", { message: e.message })));
    });
};

export const updatePositions = positions => ({
  type: types.UPDATE_POSITIONS,
  payload: { positions },
});

export const updatePosition = position => ({
  type: types.UPDATE_POSITION,
  payload: { position },
});

export const fetchPositions = () => (dispatch, getState) => {
  const { id: jobId, employerId } = getState().jobDetails;
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.query({
    query: FetchPositionsQuery,
    variables: {
      jobId,
      employerId,
    },
  })
    .then(({ data }) => {
      dispatch(updatePositions(data.job.positions));
      dispatch(setIsUpdatingPositions(false));
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.errorToast(18n.t("toast_error_job_detail_faild_to_update_position", { message: e.message })));
    });
};

const removeJobFactory = (mutation, type) => (jobId) => (dispatch, getState) => {
  const { positionsFilled } = getState().jobDetails;
  const { employerId } = getMe(getState());
  dispatch(setIsPageLoading(true));
  return ApolloClient.mutate({
    mutation,
    variables: {
      data: {
        employerId,
        jobId: Number(jobId),
      },
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      } else {
        dispatch(reset());
        dispatch(setIsPageLoading(false));
        let message;
        if (type === "delete") {
          message = i18n.t("toast_success_job_detail_job_delete");
        }

        if (type === "cancel") {
          message = i18n.t("toast_success_job_detail_job_cancel");
          if (positionsFilled) {
            message = i18n.t("toast_success_job_detail_job_add_worker", { message, positionsFilled });
          }
        }
        dispatch(MetaActions.successToast(message));
        return data
      }
    })
    .catch(e => {
      dispatch(setIsPageLoading(false));
      dispatch(MetaActions.errorToast(i18n.t("toast_error_job_detail_faild_to_type_job", { type, message: e.message })));
    });
};

export const deleteJob = removeJobFactory(DeleteJobMutation, "delete");
export const cancelJob = removeJobFactory(CancelJobMutation, "cancel");

const setRecurringJobList = (data) => ({
  type: types.SET_RECURRING_JOB_LIST,
  payload: data
});

export const fetchJobRecurringJob = (jobDetail, isFixedTerm = true) => dispatch => {
  return ApolloClient.query({
    query: FetchRecurringJobQuery,
    variables: {
      jobId: jobDetail.id,
      isFuture: !isFixedTerm
    },
  }).then(({ data: { recurringJobsByJobId } }) => {
    dispatch(setRecurringJobList(recurringJobsByJobId));
  }).catch(e => {
    dispatch(MetaActions.errorToast(i18n.t("toast_error_job_detail_get_data", { message: e.message })));
  });
}

export const cancelRecurringJob = (recurringJobIds, jobId, isAllFutureJobs) => (dispatch, getState) => {
  const { positionsFilled, isFixedTerm } = getState().jobDetails;
  const { employerId } = getMe(getState());
  dispatch(setIsDeleteJobLoading((isFixedTerm && isAllFutureJobs)?"futureLoading":"currentLoading"));
  return ApolloClient.mutate({
    mutation: CancelRecurringJobMutation,
    variables: {
      data: {
        employerId,
        jobId: Number(jobId),
        recurringJobIds: [...recurringJobIds],
        ...(isFixedTerm ? { isFixedTerm, isAllFutureJobs } : {})
      },
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      } else {
        dispatch(setIsDeleteJobLoading(false));
        dispatch(setIsCancelJobDialogState(false));
        let message;
        message = i18n.t("toast_success_job_detail_job_cancel");
        if (positionsFilled) {
          message = i18n.t("toast_success_job_detail_job_add_worker", { message, positionsFilled });
        }
        dispatch(MetaActions.successToast(message));
        return data
      }

    })
    .catch(e => {
      dispatch(setIsDeleteJobLoading(false));
      dispatch(setIsCancelJobDialogState(false));
      dispatch(MetaActions.errorToast(`${e.message}`));
    });
}

export const updatePositionData = field => (positionId, value) => {
  return {
    type: types.UPDATE_POSITION_DATA,
    payload: { positionId, field, value },
  };
};

export const batchUpdatePositionData = field => value => ({
  type: types.BATCH_UPDATE_POSITION_DATA,
  payload: { field, value },
});

export const resetPositionUpdates = () => ({
  type: types.RESET_POSITION_UPDATES,
});

export const updateBanList = workerId => ({
  type: types.UPDATE_BAN_LIST,
  payload: { workerId },
});

export const updatePreferList = workerId => ({
  type: types.UPDATE_PREFER_LIST,
  payload: { workerId },
});

export const rateAllWorkers = () => (dispatch, getState) => {
  const { id, positionUpdates } = getState().jobDetails;
  const { employerId } = getMe(getState());
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: RateAllWorkersMutation,
    variables: {
      data: {
        jobId: id,
        workerRatings: positionUpdates.map(({ workerId, ratings }) => ({ workerId, workerRatingComment: '', ratings: ratings })),
      },
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      } else {
        dispatch(setIsUpdatingPositions(false));
        dispatch(fetchJobWorkers(id, employerId))
        dispatch(MetaActions.successToast(i18n.t("toast_success_job_detail_worker_rated")));
      }
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      // Failed so reset to actual data
      dispatch(resetPositionUpdates());
      dispatch(MetaActions.errorToast(i18n.t("toast_error_job_detail_worker_rated", { message: e.message })));
    });
};

export const checkinAllWorkers = () => (dispatch, getState) => {
  const { id, positionUpdates, employerId } = getState().jobDetails;
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: CheckInAllMutation,
    variables: {
      employerId,
      data: {
        jobId: id,
        times: positionUpdates.map(({ workerId, startShift, startShiftUtc, id }) => ({
          positionId: id,
          workerId,
          startShift,
          startShiftUtc,
        })),
      },
    },
  })
    .then(({ data }) => {
      dispatch(updatePositions(data.checkInAllWorkers));
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.successToast(i18n.t("toast_success_job_detail_worker_checkIn")));
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      // Failed so reset to actual data
      dispatch(resetPositionUpdates());
      dispatch(MetaActions.errorToast(i18n.t("toast_error_job_detail_worker_checkIn", { message: e.message })));
    });
};

export const checkoutAllWorkers = () => (dispatch, getState) => {
  const { id, positionUpdates, employerId } = getState().jobDetails;
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: CheckOutAllMutation,
    variables: {
      employerId,
      data: {
        jobId: id,
        times: positionUpdates.map(({ workerId, endShift, breakMins, endShiftUtc, id }) => ({
          positionId: id,
          workerId,
          endShift,
          breakMins,
          endShiftUtc,
        })),
      },
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        dispatch(resetPositionUpdates());
        throw new Error(errors[0].message);
      }
      dispatch(updatePositions(data.checkOutAllWorkers));
      dispatch(fetchJob(id, false));
      dispatch(MetaActions.successToast(i18n.t("toast_success_job_detail_worker_checkOut")));
      dispatch(setIsUpdatingPositions(false));
    })
    .catch(e => {
      console.log(e)
      dispatch(setIsUpdatingPositions(false));
      // Failed so reset to actual data
      dispatch(resetPositionUpdates());
      dispatch(MetaActions.errorToast(i18n.t("toast_error_job_detail_worker_checkOut", { message: e.message })));
    });
};

export const increasePositions = increaseBy => (dispatch, getState) => {
  const { id: jobId, employerId } = getState().jobDetails;
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: IncreaseJobPositionsMutation,
    variables: {
      data: {
        employerId,
        jobId,
        increaseBy,
      },
    },
  })
    .then(({ data: { increasePositions } }) => {
      dispatch(setJob(increasePositions));
      return dispatch(fetchJobWorkers(increasePositions.id, increasePositions.employerId));
    })
    .then(() => {
      const { peopleNeeded } = getState().jobDetails;
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.successToast(i18n.t("toast_success_job_detail_positions_increase", { peopleNeeded })));
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.errorToast(i18n.t("toast_error_job_detail_positions_increase", { message: e.message })));
    });
};

export const decreasePositions = decreaseBy => (dispatch, getState) => {
  const { id: jobId, employerId } = getState().jobDetails;
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: DecreaseJobPositionsMutation,
    variables: {
      data: {
        employerId,
        jobId,
        decreaseBy,
      },
    },
  })
    .then(({ data: { decreasePositions } }) => {
      dispatch(setJob(decreasePositions));
      return dispatch(fetchJobWorkers(decreasePositions.id, decreasePositions.employerId));
    })
    .then(() => {
      const { peopleNeeded } = getState().jobDetails;
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.successToast(i18n.t("toast_success_job_detail_positions_decrease", { peopleNeeded })));
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.errorToast(i18n.t("toast_error_job_detail_positions_decrease", { message: e.message })));
    });
};

export const checkinWorker = (positionId) => (dispatch, getState) => {
  const { id, employerId, positions, positionUpdates, timezone } = getState().jobDetails;
  const { fullName } = positions.find(info => info.id === positionId);
  const { startShift, startShiftUtc, workerId } = positionUpdates.find(info => info.id === positionId);
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: CheckInMutation,
    variables: {
      employerId,
      data: {
        jobId: id,
        workerId,
        startShift,
        startShiftUtc,
        positionId
      },
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      }
      dispatch(updatePosition(data.checkInWorker));
      dispatch(setIsUpdatingPositions(false));
      dispatch(
        MetaActions.successToast(i18n.t("toast_success_job_detail_position_check_in", { name: fullName, time: convertUTCToTimezoneWithOffset(startShiftUtc, timezone, 'MMM Do h:mm a') }))
      );
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      // Failed so reset to actual data
      dispatch(resetPositionUpdates());
      dispatch(MetaActions.errorToast(i18n.t("toast_error_job_detail_position_check_in", { name: fullName, message: e.message })));
    });
};

export const checkoutWorker = positionId => (dispatch, getState) => {
  const { id, positionUpdates, employerId, positions, timezone } = getState().jobDetails;
  const { fullName } = positions.find(info => info.id === positionId);
  const { endShift, endShiftUtc, breakMins, workerId } = positionUpdates.find(info => info.id === positionId);
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: CheckOutMutation,
    variables: {
      employerId,
      data: {
        jobId: id,
        workerId,
        endShift,
        endShiftUtc,
        breakMins,
        positionId,
      },
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      }
      dispatch(updatePosition(data.checkOutWorker));
      dispatch(fetchJob(id, false));
      dispatch(setIsUpdatingPositions(false));
      dispatch(
        MetaActions.successToast(i18n.t("toast_success_job_detail_position_check_in", { name: fullName, time: convertUTCToTimezoneWithOffset(endShiftUtc, timezone, 'MMM Do h:mm a') }))
      );
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      // Failed so reset to actual data
      dispatch(resetPositionUpdates());
      dispatch(MetaActions.errorToast(i18n.t("toast_error_job_detail_position_check_in", { name: fullName, message: e.message })));
    });
};

export const noShowWorker = (workerId, ban) => (dispatch, getState) => {
  const { id, positions, employer, isFixedTerm } = getState().jobDetails;
  const { fullName } = positions.find(info => info.workerId === workerId);
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: NoShowPositionMutation,
    variables: {
      data: {
        jobId: id,
        workerId,
        ban,
      },
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      } else {
        dispatch(fetchPositions());
        dispatch(setIsUpdatingPositions(false));
        if (ban) {
          dispatch(
            MetaActions.successToast(i18n.t(isFixedTerm ? "toast_success_job_detail_position_no_show_and_ban" : "toast_success_job_detail_position_no_show", { name: fullName, companyName: employer.companyName }))
          );
        } else {
          // dispatch(suspendWorker(workerId, 7, `Flagged as a no show. Job [${id}]`));
          dispatch(
            MetaActions.successToast(i18n.t(isFixedTerm ? "toast_error_job_detail_position_no_show_only" : "toast_error_job_detail_position_no_show", { name: fullName }))
          );
        }
      }
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      // Failed so reset to actual data
      dispatch(resetPositionUpdates());
      dispatch(MetaActions.errorToast(i18n.t("toast_error_job_detail_position_flag_no_show", { name: fullName, message: e.message })));
    });
};

export const preferWorker = (workerId, isDashboard) => (dispatch, getState) => {
  const { positions, employerId, employer, id, multiShiftPositions } = getState().jobDetails;
  const { fullName } = (isDashboard ? multiShiftPositions : positions).find(info => info.workerId === workerId);
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: PreferWorkerMutation,
    variables: {
      data: {
        workerId,
        employerId,
      },
    },
  })
    .then(({ data }) => {
      return Promise.all([data, dispatch(fetchJobWorkers(id, employerId))]);
    })
    .then(([data]) => {
      dispatch(updatePreferList(data.preferWorker.workerId));
      dispatch(setIsUpdatingPositions(false));
      dispatch(
        MetaActions.successToast(i18n.t("toast_success_job_detail_position_preferd", { name: fullName, companyName: employer.companyName }))
      );
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.errorToast(i18n.t("toast_error_job_detail_position_preferd", { name: fullName, message: e.message })));
    });
};

export const banWorker = (workerId, reason, isDashboard) => (dispatch, getState) => {
  const { positions, employerId, employer, id, multiShiftPositions } = getState().jobDetails;
  const { fullName } = (isDashboard ? multiShiftPositions : positions).find(info => info.workerId === workerId);
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: BanWorkerMutation,
    variables: {
      data: {
        workerId,
        employerId,
        reason,
      },
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      } else {
        dispatch(fetchJob(id, false));
        dispatch(updateBanList(data.banWorker.workerId));
        dispatch(setIsUpdatingPositions(false));
        dispatch(MetaActions.successToast(i18n.t("toast_success_job_detail_position_ban", { name: fullName, companyName: employer.companyName })));
      }
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.errorToast(i18n.t("toast_error_job_detail_position_ban", { name: fullName, message: e.message })));
    });
};

export const clearException = (workerId, type, isDashboard) => (dispatch, getState) => {
  const { positions, employerId, id, multiShiftPositions } = getState().jobDetails;
  const { fullName } = (isDashboard ? multiShiftPositions : positions).find(info => info.workerId === workerId);
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: ClearExceptionMutation,
    variables: {
      data: {
        workerId,
        employerId,
      },
    },
  })
    .then(() => {
      return dispatch(fetchJobWorkers(id, employerId));
    })
    .then(() => {
      dispatch({
        type: types.REMOVE_PREFER_BAN,
        payload: { workerId },
      });
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.successToast(i18n.t("toast_success_job_detail_position_exception", { name: fullName, type })));
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.errorToast(i18n.t("toast_error_job_detail_position_exception", { name: fullName, type, message: e.message })));
    });
};

const setRatingOption = (data) => ({
  type: types.SET_RATING_OPTION_LIST,
  payload: data
});

export const fetchRatingOption = () => dispatch => {
  return ApolloClient.query({
    query: FetchRatingOptionQuery,
    variables: { platform: "Worker" },
  }).then(({ data: { ratingOption } }) => {
    dispatch(setRatingOption(ratingOption))
  }).catch(e => {
    dispatch(MetaActions.errorToast(i18n.t("toast_error_job_detail_get_rate", { message: e.message })));
  });
};

export const ratingWorker = (workerId, ratings, workerRatingComment, isDashboard = false) => (dispatch, getState) => {
  const { id, positions, multiShiftPositions } = getState().jobDetails;
  const { fullName } = (isDashboard ? multiShiftPositions : positions).find(info => info.workerId === workerId);
  const { employerId } = getMe(getState());

  return ApolloClient.mutate({
    mutation: RatingWorkerMutation,
    variables: { data: { jobId: id, workerId, ratings, workerRatingComment } },
  }).then(({ data, errors }) => {
    if (!data && errors.length > 0) {
      throw new Error(errors[0].message);
    } else {
      dispatch(MetaActions.successToast(i18n.t("toast_success_job_detail_give_rate", { name: fullName })));
      dispatch(fetchJobWorkers(id, employerId))
    }
  }).catch(e => {
    dispatch(MetaActions.errorToast(i18n.t("toast_error_job_detail_give_rate", { message: e.message })));
  });
};

export const removeWorker = (workerId, suspend, ban = false, isAllFuturePositions = false) => (dispatch, getState) => {
  const { id, positions, isFixedTerm } = getState().jobDetails;
  const position = positions.find(info => info.workerId === workerId);

  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: RemovePositionMutation,
    variables: {
      data: {
        jobId: id,
        workerId: position.workerId,
        suspend,
        positionId: position.id,
        ...(ban ? { ban } : {}),
        ...(isFixedTerm ? { isFixedTerm, isAllFuturePositions } : {}),
      },
    },
  })
    .then(() => {
      dispatch(removePositionByPositionId(workerId));
      dispatch(setIsUpdatingPositions(false));
      if (suspend) {
        dispatch(
          MetaActions.successToast(i18n.t("toast_error_job_detail_give_remove_suspend",{fullName:position.fullName})),
        );
      } else {
        dispatch(MetaActions.successToast(i18n.t("toast_error_job_detail_give_remove",{fullName:position.fullName})));
      }
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      // Failed so reset to actual data
      dispatch(resetPositionUpdates());
      dispatch(MetaActions.errorToast(i18n.t(`toast_error_job_detail_give_remove_faild`, {fullName:position.fullName,message:e.message})));
    });
};

export const removeWorkerFromDashboard = (workerId, suspend, ban = false, isAllFuturePositions = false) => (dispatch, getState) => {
  const { id, multiShiftPositions, isFixedTerm } = getState().jobDetails;
  const position = multiShiftPositions.find(info => info.workerId === workerId);

  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: RemovePositionMutation,
    variables: {
      data: {
        jobId: id,
        workerId: position.workerId,
        suspend,
        positionId: position.id,
        ...(ban ? { ban } : {}),
        ...(isFixedTerm ? { isFixedTerm, isAllFuturePositions } : {}),
      },
    },
  })
    .then(() => {
      dispatch(removePositionByPositionId(workerId));
      dispatch(setIsUpdatingPositions(false));
      if (suspend) {
        dispatch(
          MetaActions.successToast(i18n.t("toast_error_job_detail_give_remove_suspend",{fullName:position.fullName}))
        );
      } else {
        dispatch(MetaActions.successToast(i18n.t("toast_error_job_detail_give_remove",{fullName:position.fullName})));
      }
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      // Failed so reset to actual data
      dispatch(resetPositionUpdates());
      dispatch(MetaActions.errorToast(i18n.t(`toast_error_job_detail_give_remove_faild`, {fullName:position.fullName,message:e.message})));
    });
};


export const fetchMultiShiftJobPositions = (jobId) => dispatch => {
  return ApolloClient.query({
    query: FetchMultiShiftJobPositionQuery,
    variables: {
      jobId: +jobId,
    },
  }).then(({ data }) => {
    dispatch(setMultiShiftJobPositionList(data));
  }).catch(e => {
    dispatch(MetaActions.errorToast(i18n.t(`toast_error_job_detail_remove_faild`,{message:e.message})));
  });
}