export default {
  SET_POST_JOB_STATE: "postJob/SET_POST_JOB_STATE",
  SET_WORK_TYPE_LIST: "postJob/SET_WORK_TYPE_LIST",
  SET_GEAR_LIST: "postJob/SET_GEAR_LIST",
  SET_SKILLS_LIST: "postJob/SET_SKILLS_LIST",
  SET_LOADING_STATE: "postJob/SET_LOADING_STATE",
  SET_DIALOG_STATE: "postJob/SET_DIALOG_STATE",
  SET_FORM_STATE: "postJob/SET_FORM_STATE",
  RESET_FORM_DATA: "postJob/RESET_FORM_DATA",
  SET_CALCULATED_JOB_AMOUNT: "postJob/SET_CALCULATED_JOB_AMOUNT",
};
