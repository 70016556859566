import React from "react";
import { Chip, DialogActions, Divider, Typography } from "@mui/material";
import Button from "components/CustomButtons/Button";
import iconPinLocation from "assets/img/iconPinLocation.svg";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import i18n from "i18n";

function ViewTemplateDetail({ detail, onCancel, onConfirm }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const isFrench = i18n.language === "fr";
  if (!detail) {
    return "";
  }

  return (
    <div style={{ textAlign: "left" }}>
      <h3>{detail.workType && detail.workType?.[isFrench ? "frenchName" : "label"]}</h3>
      <div className={classes.location}>
        <div className="icon">
          <img src={iconPinLocation} />
        </div>
        <div className="address">
          <div>
            {detail.street && detail.street} {detail.city && detail.city}{" "}
            {detail.region && detail.region}
          </div>
          {detail.unit && (
            <div>
              <b>{t("common_labels_unit")}: </b> {detail.unit}{" "}
            </div>
          )}
          {detail.floor && (
            <div>
              <b>{t("common_labels_floor")}: </b> {detail.floor}{" "}
            </div>
          )}
          {detail.locationInstruction && (
            <div>
              <b>{t("common_labels_additional_instruction")}:</b> {detail.locationInstruction}
            </div>
          )}
        </div>
      </div>
      {((detail.jobTemplateGear && detail.jobTemplateGear.length > 0) || (detail.gears && detail.gears.length > 0)) && (
        <React.Fragment>
          <br />
          <Divider />
          <div className={classes.requiredGear}>
            <br />
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
              justifyContent={"space-between"}
            >
              {t("common_labels_required_gears").toUpperCase()}
            </Typography>
            {detail.jobTemplateGear ?
              detail.jobTemplateGear.map(j =>
                <Chip key={j.id} label={j.gear[isFrench ? "frenchGearName" : "gearName"]} variant="outlined" />
              )
              : detail.gears.map((n, index) => (
                <Chip key={index} label={n} variant="outlined" />
              ))}
          </div>
          <br />
        </React.Fragment>
      )}
      {((detail.jobTemplateSkill && detail.jobTemplateSkill.length > 0) || (detail.skills && detail.skills.length > 0)) && (
        <React.Fragment>
          <Divider />
          <div className={classes.requiredGear}>
            <br />
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
              justifyContent={"space-between"}
            >
              {t("common_labels_suggested_skills").toUpperCase()} {((detail.tags && detail.tags.length > 0) ? t('common_labels_suggested_skills_optional') : '').toUpperCase()}
            </Typography>
            <ul>
              {detail.jobTemplateSkill ?
                detail.jobTemplateSkill.map(j =>
                  <li key={j.id}>{j.skill[isFrench ? "frenchSkillName" : "skillName"]} </li>
                )
                : detail.skills.map((n, index) => (
                  <li key={index}>{n}</li>
                ))}
            </ul>
            {detail.tags && detail.tags.length > 0 && (
              <div style={{ marginBottom: "20px", paddingLeft: "20px" }}>
                {detail.tags.map((n, index) => (
                  <Chip key={index} label={n} variant="outlined" />
                ))}
              </div>
            )}
          </div>
        </React.Fragment>
      )}
      <Divider />
      <div className={classes.requiredGear}>
        <br />
        <Typography
          sx={{ fontSize: 14 }}
          color="text.secondary"
          gutterBottom
          justifyContent={"space-between"}
        >
          {t("common_labels_description").toUpperCase()}
        </Typography>
        <ul>
          <li>{detail.description}</li>
        </ul>
      </div>
      <Divider />
      <DialogActions className={classes.footer}>
        <Button type="button" className="outline" onClick={onCancel} color="transparent" round>
          {t("common_labels_close")}
        </Button>
        <Button variant="contained" color="primary" type="submit" round onClick={onConfirm}>
          {t("common_labels_use_template")}
        </Button>
      </DialogActions>
    </div>
  );
}

export default ViewTemplateDetail;
