import { useEffect, useState } from "react";

export const useBrowserDetection = (payload) => {
    const [state, setState] = useState();

    useEffect(() => {
        const userAgent = navigator?.userAgent || navigator?.vendor || window?.opera;
        if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
            window.location.replace(`directrandstadjobs://App/Jobs/ProfileDocuments/${encodeURIComponent(payload)}`)
            setState('iOS');
        }
        else if (userAgent.match(/Android/i)) {
            window.location.replace(`directrandstadjobs://App/Jobs/ProfileDocuments/${encodeURIComponent(payload)}`)
            setState('Android');
        }
        else {
            setState('web');
        }
    }, []);

    return state;
};
