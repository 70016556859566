import React from 'react'

export default function ImageRenderer({ document }) {
    return (
        <div style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "auto"
        }}>
            <img
                style={{ height: "90%", width: "90%", objectFit: "contain" }}
                src={document?.agreementDocument?.signedDocumentImageUrl} />
        </div>
    );
};
