import React from "react";
import PropTypes from "prop-types";
import { Alarm, InfoOutlined, PendingActions, VisibilityOff } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import {
  JobStatusesArray,
  JobStatusesLabel,
  JobStatusesMap,
  TimeframeArray,
  TimeframeLabel,
  TimeframeMap,
} from "helpers/constants/index";
import { useTranslation } from "react-i18next";

const styles = ({ spacing }) => ({
  container: {
    justifyContent: (props) => props.isScheduled ? 'space-between' : 'right',
    "& p": {
      fontSize: (props) => props.isScheduled ? 9 : 'initial',
      marginLeft: `${spacing(2)}`,
    },
    "& p:nth-last-child(1)": {
      marginRight: (props) => props.isScheduled ? spacing(0) : spacing(2)
    },
    "& p:nth-child(1)": {
      marginLeft: (props) => props.isScheduled ? spacing(0) : spacing(2)
    },
    "& svg": {
      marginLeft: `${spacing(2)}`,
    },
  },
});

function StateInfo({ classes, jobId, status, timeframe, isActive, isQueued, isScheduled }) {
  const { t } = useTranslation();

  return (
    <Grid container item xs={isScheduled ? 12 : 6} alignItems="center" className={classes.container}>
      <Typography variant="body1" color="textSecondary">
        {`#${jobId}`}
      </Typography>
      {!isScheduled && <Typography variant="body1" color="textSecondary">
        &#8226;
      </Typography>}
      <React.Fragment>
        <Typography variant="body1" color="textSecondary">
          {t("common_dynamic_timeframe_" + TimeframeLabel[timeframe])}
        </Typography>
      </React.Fragment>
      {!isScheduled && <>
        {
          !isActive && !isQueued && (
            <React.Fragment>
              <Typography variant="body1" color="textSecondary">
                &#8226;
              </Typography>
              <VisibilityOff color="primary" />
              <Typography variant="body1" color="textSecondary">
                {t("common_labels_draft")}
              </Typography>
            </React.Fragment>
          )
        }
        {
          !isActive && isQueued && (
            <React.Fragment>
              <Typography variant="body1" color="textSecondary">
                &#8226;
              </Typography>
              <PendingActions color="primary" />
              <Typography variant="body1" color="textSecondary">
                {t("common_labels_queue")}
              </Typography>
            </React.Fragment>
          )
        }
        {
          timeframe === TimeframeMap.Today && isActive ? (
            <React.Fragment>
              <Typography variant="body1" color="textSecondary">
                &#8226;
              </Typography>
              {status === JobStatusesMap.NotStarted ? (
                <InfoOutlined color="primary" />
              ) : (
                <Alarm color="primary" />
              )}
              <Typography variant="body1" color="textSecondary">
                {t("common_dynamic_status_" + JobStatusesLabel[status])}
              </Typography>
            </React.Fragment>
          ) : null
        }
      </>}
    </Grid >
  );
}

StateInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  jobId: PropTypes.number.isRequired,
  status: PropTypes.oneOf(JobStatusesArray).isRequired,
  timeframe: PropTypes.oneOf(TimeframeArray).isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default withStyles(styles)(StateInfo);
