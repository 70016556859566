import React from "react";
import { useTranslation } from "react-i18next";
import ReactTable from "components/ReactTable"
import { sharedColumns } from "../helpers/tableProps";

function TableComponent({ pageInfo, jobListData, pageFilter, pageSort, fetchData, extraColumn, history }) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <ReactTable
        columns={[
          ...sharedColumns({ t }),
          ...(extraColumn ? extraColumn : [])
        ]}
        data={jobListData}
        fetchData={fetchData}
        defaultFilter={pageFilter}
        defaultSort={pageSort}
        currentPage={pageInfo.currentPage}
        defaultPageSize={pageInfo.pageIndex}
        loading={pageInfo.isLoading}
        pageCount={pageInfo.totalCount}
        onRowClick={({ original }) => {
          history.push(`/jobs/${original.id}${original.isFixedTerm ? "/dashboard" : ""}`);
        }}
      />
    </React.Fragment>
  )
}
export default TableComponent