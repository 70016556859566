import { gql } from "@apollo/client";

export default gql`
mutation UpdatePositionDocumentStatus($data: UpdatePositionDocumentStatusInput!) {
  updatePositionDocumentStatus(data: $data) {
    id
    documentId
    positionId
    documentStatus
    createdAt
    updatedAt
    agreementDocument {
      id
      documentName
      documentUrl
      provinceId
      helpText
      documentType
      createdAt
      updatedAt
      signedDocumentImageUrl
    }
  }
}
`;
