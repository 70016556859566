import React from "react";
import Typography from "@mui/material/Typography";
import Line from "components/Placeholders/Line";
import { useTranslation } from "react-i18next";

export default function useLocation() {
  const { t } = useTranslation();
  return {
    Header: t("common_table_column_location"),
    id: "location",
    styles: {
      minWidth: '100px'
    },
    sortable: false,
    Cell: ({ row: { original: { city, region } } }) => {
      return (
        <>
          <Typography variant="body1">
            {!city || !region ? <Line /> : `${city}, ${region}`}
          </Typography>
        </>
      );
    },
  };
}
