import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import { alpha, CardContent, CircularProgress, Typography } from "@mui/material";
import Card from "components/Card/Card";
import StateInfo from "../JobHeader/StateInfo";
import { convertUTCToTimezoneWithOffset } from "utils/time";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ spacing, palette, shape }) => ({
    filledBreakdown: {
        marginRight: spacing(1),
        fontWeight: "bold",
        fontSize: 16
    },
    titleContainer: {
        padding: `${spacing(1)} ${spacing(2)}`,
        minHeight: 64,
        position: "relative"
    },
    root: {
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: shape.borderRadius / 2,
        marginTop: spacing(2),
        "& $titleContainer:nth-child(1)": {
            // borderBottom: `1px solid ${palette.grey[500]}`,
        },
        "& $titleContainer:nth-child(2)": {
            // borderBottom: `1px solid ${palette.grey[500]}`,
            padding: 0
        }
    },
    progress: {
        position: "absolute",
        display: "flex",
        background: alpha(palette.grey[500], 0.5),
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
    },
    jobsContainer: {
        // display: "flex",
        // gap: spacing(1),
        width: "100%",
        flexWrap: "wrap",
        overflow: "auto",
        padding: spacing(1),

        display: "grid",
        gridGap: 8,
        gridRowGap: 8,
        justifyContent: "space-around",
        gridTemplateColumns: "repeat(auto-fill, 126px)"
    },
    card: {
        margin: spacing(0, 0),
        cursor: "pointer",
        // minWidth: "fit-content",
        width: "auto",
        border: `1px solid ${palette.secondary.main}`,
        "& .MuiCardContent-root": {
            padding: spacing(1),
            paddingBottom: spacing(1)
        },
        "&:hover": {
            background: alpha(palette.grey[300], 0.5)
        }
    },
    jobTimeContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: spacing(1, 0, 0, 0),
        justifyContent: "center"
    },
    jobTime: {
        fontSize: 14,
    },
    jobStartTime: {
        justifyContent: "center",
        display: 'flex',
        alignItems: "center",
        marginBottom: spacing(1),
        "& p": {
            fontSize: 11,
        },
    },
    notFound: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        "& p": {
            fontSize: 21
        }
    }
}));

export const sharedReactTableProps = {
    showPageSizeOptions: true,
    sortable: false,
    defaultSortDesc: false,
    multiSort: false,
    showPaginationBottom: true,
    showPageJump: false,
    showPaginationTop: false,
    resizable: false,
    classes: "-highlight",
    minRows: 0,
    rowsPerPageOptions: { rowsPerPageOptions: [10, 20, 40, 80, 100] }, // No more than this
};


function ScheduledJobs(props) {
    const classes = useStyles();
    const isLoading = useSelector(JobDetailSelectors.getIsScheduledJobLoading);
    const scheduledJobs = useSelector(JobDetailSelectors.getAllScheduledJobs);
    const history = useHistory();
    const { t } = useTranslation();

    const handleRedirectToJob = (e, jobId) => {
        if (jobId) {
            if (e.metaKey || e.ctrlKey) {
                const win = window.open(
                    `${window.location.origin}/jobs/${jobId}`,
                    "_blank"
                );
                win.focus();
                return;
            }
            history.push(`/jobs/${jobId}`);
        }
    }

    return (
        <Grid container item className={classes.root} direction="column">
            <Grid className={classes.titleContainer} container xs={12}>
                <Grid container item xs={6} direction="row" alignItems="center">
                    <Typography className={classes.filledBreakdown} variant="subtitle2">
                        {t(`page_job_details_label_scheduled_jobs`)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid className={classes.titleContainer} container xs={12}>
                <div className={classes.jobsContainer}>
                    {scheduledJobs?.map(job => (
                        <Card key={job.id} className={classes.card} onClick={(e) => handleRedirectToJob(e, job.id)}>
                            <CardContent>
                                {/* <StateInfo
                                    isScheduled
                                    jobId={job.id}
                                    timeframe={job.timeframe}
                                    status={job.status}
                                    isActive={job.isActive}
                                    isQueued={job.isQueued} /> */}
                                <div className={classes.jobTimeContainer}>
                                    <Typography className={classes.jobTime}>{convertUTCToTimezoneWithOffset(job.startUtc, job.timezone, "DD MMM", true)}</Typography>
                                    <Typography className={classes.jobTime}>{convertUTCToTimezoneWithOffset(job.startUtc, job.timezone, "ddd", true)}</Typography>
                                </div>
                                <Grid container item xs={12} alignItems="center" className={classes.jobStartTime}>
                                    <Typography variant="body1" color="textSecondary">
                                        {`${convertUTCToTimezoneWithOffset(job.startUtc, job.timezone, "h:mm a", true)} - ${convertUTCToTimezoneWithOffset(job.endUtc, job.timezone, "h:mm a", true)}`}
                                    </Typography>
                                </Grid>
                            </CardContent>
                        </Card>
                    ))}
                </div>
                {!scheduledJobs || !scheduledJobs?.length && <div className={classes.notFound}>
                    <Typography>{t(`page_job_details_label_scheduled_jobs_not_available`)}</Typography>
                </div>}
                {isLoading && <div className={classes.progress}>
                    <CircularProgress size={20} />
                </div>}
            </Grid>
        </Grid>
    );
}

export default ScheduledJobs;
