import Callback from "Callback.jsx";
import ChatSupport from "ChatSupport";
import Dashboard from "layouts/Dashboard.jsx";
import ReviewDocuments from "views/ReviewDocuments/ReviewDocuments";
import EmailVerifySuccess from "../views/Email/EmailVerifySuccess";

var indexRoutes = [
  { path: "/review-documents", component: ReviewDocuments },
  { path: "/chat-support-iframe", component: ChatSupport },
  { path: "/callback", component: Callback },
  { path: "/email-verified", component: EmailVerifySuccess },
  { path: "/", name: "Home", component: Dashboard },
];

export default indexRoutes;
