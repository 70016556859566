import {gql} from "@apollo/client";
export default gql`
  query myJobTemplate($employerId: ID!){
    myJobTemplate(employerId: $employerId) {
      id
      templateName
      employerId
      managerId
      workTypeId
      peopleNeeded
      street
      city
      region
      country
      longitude
      latitude
      postalCode
      unit
      floor
      description
      isActive
      locationInstruction
      gears
      skills
      jobTemplateGear {
        gear {
          isActive
          id
          gearName
          frenchGearName
          createdAt
        }
        gearId
        id
        createdAt
      }
      jobTemplateSkill {
        skill {
          frenchSkillName
          id
          isActive
          skillName
          createdAt
        }
        id
        skillId
        createdAt
      }
      isPrivate
      tags
      breakMins
      isBreakPaid
      manager {
        id
        firstName
        lastName
      }
      workType {
        id
        label
        frenchName
      }
    }
  }
`;