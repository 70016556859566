import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, FormControl, InputLabel, MenuItem, Select, Typography, Divider, TextField, IconButton } from "@mui/material";
import { InfoRounded, Clear } from "@mui/icons-material";
import Tooltip from '@mui/material/Tooltip';
import { find } from "lodash";
import Button from "components/Button/Button";
import CustomButton from "components/CustomButtons/Button";
import AddManagerDialog from "views/Managers/components/AddManagerDialog";
import { actions as ManagerActions, selectors as ManagerSelectors } from "store/modules/managers";
import { actions as PostJobActions, selectors as PostJobSelectors } from "store/modules/postJob";
import { actions as LoginActions, selectors as LoginSelectors } from "store/modules/login";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { getMe } from "store/modules/login/selectors";

function Budget({ handleNext }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const noOfWorkers = useSelector(PostJobSelectors.getNoOfWorkers);
  const isOpenAddManagerDialog = useSelector(ManagerSelectors.getManagersDialogState);
  const { accountType } = useSelector(getMe);
  const { t } = useTranslation();

  const handleChange = e => {
    if (e.target.value < 1000) {
      dispatch(PostJobActions.setNoOfWorkers(e.target.value));
    }
  };

  const siteManager = useSelector(PostJobSelectors.getSiteManager);
  const checkInManager = useSelector(PostJobSelectors.getCheckInManager);
  const checkOutManager = useSelector(PostJobSelectors.getCheckOutManager);
  const managerList = useSelector(ManagerSelectors.getManagersListData());

  useEffect(() => {
    dispatch(ManagerActions.fetchManagers());
  }, [isOpenAddManagerDialog])

  const handleChangeSiteManager = (e) => {
    dispatch(PostJobActions.setSiteManager(find(managerList, n => n.id == e.target.value)));
  }
  const handleChangeCheckInManager = (e) => {
    dispatch(PostJobActions.setCheckInManager(find(managerList, n => n.id == e.target.value)));
  }
  const handleChangeCheckOutManager = (e) => {
    dispatch(PostJobActions.setCheckOutManager(find(managerList, n => n.id == e.target.value)));
  }
  const showDialog = () => {
    dispatch(ManagerActions.setIsAddManagerDialogState(true))
  }
  const hideDialog = () => {
    dispatch(ManagerActions.setIsAddManagerDialogState(false))
  }
  const saveManager = (data) => {
    if (data) {
      data.mobilePhone = data.mobilePhone.toString()
      dispatch(ManagerActions.addManager(data))
    }
  }
  const handleClearSiteManager = () => {
    dispatch(PostJobActions.setSiteManager({}));
  }
  const handleClearCheckInManager = () => {
    dispatch(PostJobActions.setCheckInManager({}));
  }
  const handleClearCheckOutManager = () => {
    dispatch(PostJobActions.setCheckOutManager({}));
  }

  //Remove active element focus
  const removeFocus = () => setTimeout(() => document.activeElement.blur(), 0);

  return (
    <>
      <h3>{t("common_labels_budget")}</h3>
      {/* <div className={classes.budgetSection}>
        <Typography
          sx={{ fontSize: 14 }}
          marginTop={1}
          color="text.secondary"
          gutterBottom
          justifyContent={"space-between"}
        >
          NUMBER OF WORKER(S)
        </Typography>
        <div>
          <IconButton
            disabled={noOfWorkers <= 1}
            className="closeButton"
            color="primary"
            onClick={() => handleChange(noOfWorkers - 1)}
            aria-label="delete"
            component="span"
          >
            <RemoveCircleOutline className="substract" />
          </IconButton>
          {noOfWorkers}
          <IconButton
            className="closeButton"
            color="primary"
            onClick={() => handleChange(noOfWorkers + 1)}
            aria-label="delete"
            component="span"
          >
            <AddCircle className="add" />
          </IconButton>
        </div>
      </div> */}
      <Grid item md={12}>
        <TextField
          id="locationInstruction"
          name="locationInstruction"
          label={t("common_labels_workers_input").toUpperCase()}
          value={noOfWorkers}
          type="number"
          onChange={handleChange}
          fullWidth
          margin="none"
        />
      </Grid>
      <br />
      <Divider />
      <div className={classes.managerHeading}>
        <h3>{t("page_post_job_budget_project_roles_title")}</h3>
        {(accountType !== "employermanager") &&
          <div>
            <CustomButton
              className={classes.linkBtn}
              link={true}
              size='lg'
              onClick={showDialog}
            >
              + {t("page_post_job_budget_manager_invite")}
            </CustomButton>
          </div>
        }
      </div>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {t("page_post_job_budget_project_roles_subtitle")}
      </Typography>
      <br />
      <div className={classes.roles}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="site-manager-select-label">{t("common_labels_managers_site")}</InputLabel>
              <Select
                onClose={removeFocus}
                labelId="site-manager-select-label"
                id="site-manager-select-label"
                value={siteManager.id || ''}
                label={t("common_labels_managers_site")}
                onChange={handleChangeSiteManager}
                endAdornment={
                  <div className={classes.assignedRoleEndAdornment}>
                    {!!siteManager.id && (
                      <IconButton className={classes.assignedRoleEndAdornmentButton} onClick={handleClearSiteManager} >
                        <Clear />
                      </IconButton>
                    )}
                    <Tooltip title={t("common_labels_managers_tooltips_site")}>
                      <IconButton className={classes.assignedRoleEndAdornmentButton}>
                        <InfoRounded />
                      </IconButton>
                    </Tooltip>
                  </div>
                }
              >
                {managerList &&
                  managerList.map(n => (
                    <MenuItem key={n.id} value={n.id}>
                      {n.manager.firstName} {n.manager.lastName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="checkin-manager-select-label">{t("common_labels_managers_checkIn")}</InputLabel>
              <Select
                onClose={removeFocus}
                labelId="checkin-manager-select-label"
                id="checkin-manager-select-label"
                value={checkInManager.id || ''}
                label={t("common_labels_managers_checkIn")}
                onChange={handleChangeCheckInManager}
                endAdornment={
                  <div className={classes.assignedRoleEndAdornment}>
                    {!!checkInManager.id && (
                      <IconButton className={classes.assignedRoleEndAdornmentButton} onClick={handleClearCheckInManager} >
                        <Clear />
                      </IconButton>
                    )}
                    <Tooltip title={t("common_labels_managers_tooltips_checkIn")}>
                      <IconButton className={classes.assignedRoleEndAdornmentButton}>
                        <InfoRounded />
                      </IconButton>
                    </Tooltip>
                  </div>
                }
              >
                {managerList &&
                  managerList.map(n => (
                    <MenuItem key={n.id} value={n.id}>
                      {n.manager.firstName} {n.manager.lastName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="checkout-manager-select-label">{t("common_labels_managers_checkOut")}</InputLabel>
              <Select
                onClose={removeFocus}
                labelId="checkout-manager-select-label"
                id="checkout-manager-select-label"
                value={checkOutManager.id || ''}
                label={t("common_labels_managers_checkOut")}
                onChange={handleChangeCheckOutManager}
                endAdornment={
                  <div className={classes.assignedRoleEndAdornment}>
                    {!!checkOutManager.id && (
                      <IconButton className={classes.assignedRoleEndAdornmentButton} onClick={handleClearCheckOutManager}>
                        <Clear />
                      </IconButton>
                    )}
                    <Tooltip title={t("common_labels_managers_tooltips_checkOut")}>
                      <IconButton className={classes.assignedRoleEndAdornmentButton}>
                        <InfoRounded />
                      </IconButton>
                    </Tooltip>
                  </div>
                }
              >
                {managerList &&
                  managerList.map(n => (
                    <MenuItem key={n.id} value={n.id}>
                      {n.manager.firstName} {n.manager.lastName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <Button
        className={classes.continueButton}
        size="small"
        disabled={noOfWorkers <= 0}
        onClick={handleNext}
        label={t("common_labels_continue")}
      />
      <AddManagerDialog
        open={isOpenAddManagerDialog}
        onClose={hideDialog}
        onCancel={hideDialog}
        onConfirm={saveManager}
      />
    </>
  );
}

export default Budget
