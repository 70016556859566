import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Dialog from "components/Dialog/Dialog";
import { isWithinSuspensionTimeframe } from "utils/time";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import { useTranslation } from "react-i18next";

function RemoveDialog(props) {
    const { open, onClose, onCancel, onConfirm, position } = props;
    const [showRemoveSuspend, setShowRemoveSuspend] = useState(false);
    const [showBan, setShowBan] = useState(false);
    const { t } = useTranslation();

    const jobStart = useSelector(JobDetailSelectors.getJobStartUtc);
    const { isPrivate, isActive, isFixedTerm } = useSelector(JobDetailSelectors.getJobDetails);

    const handleRemove = (withFuture) => {
        if (isWithinSuspensionTimeframe(jobStart) && isActive) {
            if (isPrivate) {
                if (position.lateJobCancelCount && position.lateJobCancelCount > 0) {
                    setShowBan(true);
                } else {
                    onConfirm(false, false, withFuture);
                }
            } else {
                setShowRemoveSuspend(true);
            }
        } else {
            onConfirm(false, false, withFuture);
        }
    };

    const handleRemoveOnly = () => {
        setShowRemoveSuspend(false, false);
        setShowBan(false);
        onConfirm(false, false);
    };

    const handleRemoveAndSuspend = () => {
        setShowRemoveSuspend(false, false);
        onConfirm(true, false);
    };

    const handleRemoveBan = () => {
        setShowBan(false);
        onConfirm(false, true);
    };

    const handleClose = (e) => {
        setShowBan(false);
        setShowRemoveSuspend(false);
        onClose(e)
    }


    const removePostionFromJob = (withFuture) => {
        handleRemove(withFuture);
    };

    return (
        <>
            <Dialog
                open={open && !showRemoveSuspend}
                title={t("common_labels_are_you_sure")}
                description={t("page_job_details_dialog_remove_position_description",{name:position.fullName})}
                onClose={handleClose}
                onConfirm={() => removePostionFromJob(false)}
                onCancel={onCancel}
                icon="remove_circle"
                alertType={isFixedTerm ? "warningWithTwoConfirmButtons" : "warning"}
                onSecondConfirm={() => removePostionFromJob(true)}
                confirmText={isFixedTerm ? t("common_labels_yes_current_job") : t("common_labels_yes")}
                secondConfirmText={t("common_labels_yes_future_job")}
            />
            <Dialog
                open={open && showRemoveSuspend}
                title={t("page_job_details_dialog_no_show_suspand_title")}
                description={t("page_job_details_dialog_no_show_suspand_description",{name:position.fullName})}
                onClose={handleClose}
                onConfirm={handleRemoveAndSuspend}
                onCancel={handleRemoveOnly}
                cancelText="No, Remove Only"
                alertType="warning"
                icon="event_busy"
            />
            <Dialog
                open={open && showBan}
                title={t("page_job_details_dialog_no_show_ban_title")}
                description={t("page_job_details_dialog_no_show_ban_description",{name:position.fullName})}
                onClose={handleClose}
                onConfirm={handleRemoveBan}
                onCancel={handleRemoveOnly}
                cancelText="No, Remove Only"
                alertType="warning"
                icon="event_busy"
            />
        </>
    );
}

RemoveDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    position: PropTypes.object,
};

export default RemoveDialog;
