import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardContent, Checkbox, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { find } from "lodash";
import Button from "components/Button/Button";
import { actions as PostJobActions, selectors as PostJobSelectors } from "store/modules/postJob";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import Card from "components/Card/Card";
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import i18n from "i18n";

const DESCRIPTION_LENGTH = 3000;

function WorkType({ handleNext }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const workType = useSelector(PostJobSelectors.getWorkTypeList);
  const selectedWorkType = useSelector(PostJobSelectors.getWorkType);
  const isFixedTerm = useSelector(PostJobSelectors.getIsFixedTerm);
  const description = useSelector(PostJobSelectors.getDescription);
  const { t } = useTranslation();
  const isFrenchLanguage = i18n.language === "fr";

  useEffect(() => {
    dispatch(PostJobActions.fetchWorkTypes());
  }, []);

  const handleChange = e => {
    dispatch(PostJobActions.setWorkType(find(workType, n => n.value == e.target.value)));
  };

  const handleChangeJobType = value => {
    dispatch(PostJobActions.setIsFixedTerm(value));
    if (value) {
      dispatch(PostJobActions.setRecurringType("Daily"));
    }
  };

  const handleChangeDescription = e => {
    if (e.target.value.length <= DESCRIPTION_LENGTH) {
      dispatch(PostJobActions.setDescription(e.target.value));
    }
  };
  return (
    <>

      {/* Job type */}
      <h3>{t("page_post_job_jobType_title")}</h3>
      <div className={classes.jobTypeCard}>
        <Card style={{ margin: 0 }} className="card" onClick={() => isFixedTerm && handleChangeJobType(false)}>
          <CardContent>
            <FormControlLabel control={<Radio checked={!isFixedTerm} icon={<RadioButtonUnchecked />} onChange={() => isFixedTerm && handleChangeJobType(false)} checkedIcon={<CheckCircle />} color="secondary" />} label={t("common_labels_day_work_job")} />
            <Typography className="desc" color="text.secondary">
              {t("page_post_job_day_work_job_desc")}
            </Typography>
          </CardContent>
        </Card>
        <Card style={{ margin: 0 }} className="card" onClick={() => !isFixedTerm && handleChangeJobType(true)}>
          <CardContent>
            <FormControlLabel control={<Radio checked={isFixedTerm} icon={<RadioButtonUnchecked />} onChange={() => !isFixedTerm && handleChangeJobType(true)} checkedIcon={<CheckCircle />} color="secondary" />} label={t("common_labels_fixed_term_job")} />
            <Typography className="desc" color="text.secondary">
              {t("page_post_job_fixed_term_job_desc")}
            </Typography>
          </CardContent>
        </Card>
      </div>


      <h3>{t("page_post_job_workType_title")}</h3>
      <div>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={selectedWorkType.value}
          onChange={e => handleChange(e)}
        >
          {workType &&
            workType.map(n => (
              <FormControlLabel
                className={`${classes.checkboxLabel} ${selectedWorkType.value === n.value ? classes.selectedWorkType : ""
                  }`}
                key={n.value}
                value={n.value}
                control={<Radio />}
                label={n[isFrenchLanguage?"frenchName":"label"]}
              />
            ))}
        </RadioGroup>
      </div>
      <h3>{t("common_labels_description")}</h3>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {t("page_post_job_workType_description_helper")}
      </Typography>
      <div className={classes.gearTextBox}>
        <TextField
          id="description"
          name="description"
          // label="Description"
          placeholder={t("page_post_job_workType_description_placeholder")}
          value={description}
          onChange={handleChangeDescription}
          fullWidth
          margin="none"
          multiline
          minRows={4}
        />
      </div>
      <div className={classes.descriptionCountdown}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">{`${description?.length || 0}/${DESCRIPTION_LENGTH} ${t("common_labels_characters")}`}</Typography>
      </div>
      <Button
        className={classes.continueButton}
        size="small"
        disabled={selectedWorkType.value === 0 || !description}
        onClick={handleNext}
        label={t("common_labels_continue")}
      />
    </>
  );
}

export default WorkType;
