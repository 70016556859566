export default {
  RESET: "jobDetails/RESET",
  SET_LOADING_STATE: "jobDetails/SET_LOADING_STATE",
  SET_JOB: "jobDetails/SET_JOB",
  SET_JOB_WORKERS: "jobDetails/SET_JOB_WORKERS",
  SET_WORKER_PICKER_LIST_DATA: "jobDetails/SET_WORKER_PICKER_LIST_DATA",
  IS_LOADING_DETAIL: "jobDetails/IS_LOADING_DETAIL",
  UPDATE_POSITIONS: "jobDetails/UPDATE_POSITIONS",
  UPDATE_POSITION: "jobDetails/UPDATE_POSITION",
  IS_ADDING_WORKER: "jobDetails/IS_ADDING_WORKER",
  UPDATE_POSITION_DATA: "jobDetails/UPDATE_POSITION_DATA",
  RESET_POSITION_UPDATES: "jobDetails/RESET_POSITION_UPDATES",
  BATCH_UPDATE_POSITION_DATA: "jobDetails/BATCH_UPDATE_POSITION_DATA",
  UPDATE_BAN_LIST: "jobDetails/UPDATE_BAN_LIST",
  UPDATE_PREFER_LIST: "jobDetails/UPDATE_PREFER_LIST",
  REMOVE_PREFER_BAN: "jobDetails/REMOVE_PREFER_BAN",
  REMOVE_POSITION:"jobDetails/REMOVE_POSITION",
  SET_RATING_OPTION_LIST: "jobDetails/SET_RATING_OPTION_LIST",
  SET_RECURRING_JOB_LIST: "jobDetails/SET_RECURRING_JOB_LIST",
  SET_IS_CANCEL_JOB_DIALOG_STATE: "jobDetails/SET_IS_CANCEL_JOB_DIALOG_STATE",
  SET_SCHEDULED_JOB: "jobDetails/SET_SCHEDULED_JOB",
  REMOVE_POSITION_BY_POSITION_ID: "jobDetails/REMOVE_POSITION_BY_POSITION_ID",
  SET_MULTI_SHIFT_JOB_POSITIONS: "jobDetails/SET_MULTI_SHIFT_JOB_POSITIONS",
};
