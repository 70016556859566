import { alpha } from "@mui/material";

const { makeStyles } = require("@mui/styles");

export const useStyles = makeStyles((theme) => ({
  jobTypeCard:{
    display:"flex",
    flexWrap:"wrap",
    gap:theme.spacing(3),
    "& .card":{
      minWidth:320,
      width:"calc(50% - 24px)",
      flex:1,
      cursor:"pointer",
      background:"transparent",
      transition:"1s all",
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    "& .MuiFormControlLabel-label":{
      fontSize:"18px",
      color:theme.palette.text.primary,
    },
    "& .desc":{
      fontSize:13,
      marginLeft:theme.spacing(3.6)
    }
  },
  hideLegend: {
    "& > label": {
      display: "none"
    },
    "& fieldset > legend > span": {
      display: "none"
    }
  },
  checkboxLabel: {
    color: '#202326',
    border: `1px solid ${theme.palette.secondary.main}`,
    padding: '10px',
    borderRadius: '5px',
    marginBottom: '10px',
    marginRight: '10px',
    marginLeft: '0px',
    '& .MuiRadio-root': {
      display: 'none'
    },
  },
  selectedWorkType: {
    background: '#57c4a6',
    color: '#FFF'
  },
  gearTextBox: {
    display: 'flex'
  },
  skillsTagsSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginTop: '10px',
  },
  tags: {
    display: 'flex',
    alignItems: 'center'
  },
  selectedSection: {
    marginTop: '30px',
    '& p': {
      textTransform: 'uppercase'
    },
    '& .MuiChip-root': {
      marginTop: '5px',
      marginRight: '10px'
    }
  },
  addedSkillList: {
    border: '1px solid #e3e3ec',
    padding: '14px',
    borderRadius: '5px',
    position: 'relative',
    marginBottom: '10px',
    '& .content': {
      width: 'calc(100% - 20px)'
    },
    '& .closeButton': {
      position: 'absolute',
      right: '0',
      top: '6px'
    }
  },
  descriptionTextarea: {
    borderRadius: '5px',
    padding: '10px',
    border: 'none',
    width: '100%',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif'
  },
  durationInfo: {
    display: 'flex',
    background: '#eaeaf2',
    padding: '10px',
    borderRadius: '10px'
  },
  pageHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    "@media (max-width: 600px)": {
      flexDirection: 'column',
      paddingBottom: '10px'
    }
  },
  breakRadioGroup: {
    // width: '104px',
    '& .MuiRadio-root': {
      display: 'none'
    },
    '& label': {
      margin: '0',
      border: '1px solid #b2b2c4',
      padding: '5px 10px',
      color: '#848499'
    },
    '& .paidLabel': {
      borderRadius: '4px 0px 0px 4px',
      borderRight: '1px solid #00c8a9',
    },
    '& .weekLabel': {
      // borderRadius: '0px 4px 4px 0px',
      borderLeft: 'none',
    },
    '& .unpaidLabel': {
      borderRadius: '0px 4px 4px 0px',
      borderLeft: 'none'
    },
    '& .selected': {
      color: '#00c8a9',
      borderColor: '#00c8a9'
    }
  },
  recurringTypeRadioGroup: {
    border: '1px solid #b2b2c4',
    width: 'fit-content',
    borderRadius: '5px',
    '& .MuiRadio-root': {
      display: 'none'
    },
    '& label': {
      margin: '1px',
      border: '1px solid transparent',
      padding: '5px 10px',
      color: '#848499'
    },
    '& .selected': {
      color: '#00c8a9',
      borderColor: '#00c8a9',
      border: '1px solid #00c8a9',
      borderRadius: '5px',
      background: '#FFF'
    }
  },
  budgetSection: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '25px',
    '& .add': {
      color: '#57c4a6',
      width: '1.5em',
      height: '1.5em'
    },
    '& .substract': {
      width: '1.5em',
      height: '1.5em'
    }
  },
  managerHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    "@media (max-width: 600px)": {
      flexDirection: 'column',
    }
  },
  rolesTab: {
    height: '100px',
    textAlign: 'center',
    border: '1px solid #FFF',
    background: '#FFF',
    borderRadius: '10px'
  },
  workType: {
    fontSize: '20px',
    marginTop: '25px'
  },
  location: {
    marginTop: '16px',
    marginBottom: '24px',
    color: '#202326',
    display: 'flex',
    '& .icon': {
      width: '30px'
    }
  },
  dateTime: {
    display: 'flex',
    '& .icon': {
      width: '30px'
    },
    '& .date': {
      fontSize: '16px',
      fontWeight: 'bold'
    },
    '& .duration': {
      color: '#848499'
    },
    '& .button': {
      display: 'flex',
      marginTop: '10px',
      marginBottom: '10px',
      '& .break': {
        borderRadius: '10px',
        padding: '2px 8px',
        border: '1px solid',
        color: '#00a25b',
        background: 'rgb(0 162 91 / 10%)',
        marginRight: '10px'
      },
      '& .recurring': {
        borderRadius: '10px',
        padding: '2px 8px',
        border: '1px solid',
        color: '#0074c8',
        background: 'rgb(0 116 200 / 10%)',
      }
    }
  },
  breakSection: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #bebec1',
    borderRadius: '5px',
    "@media (max-width: 600px)": {
      flexDirection: 'column',
      alignItems: 'center'
    },
    '& .label': {
      lineHeight: '2.3',
    },
    '& .disabledLabel': {
      lineHeight: '2.3',
      color: '#dddddd'
    }
  },
  weekDays: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  days: {
    padding: '14px 0',
    border: '1px solid',
    borderRadius: '100px',
    width: '50px',
    height: '50px',
    textAlign: 'center',
    marginRight: '10px',
    fontWeight: '400',
    borderColor: '#b2b2c4',
    color: '#848499',
    cursor: 'pointer',
    marginBottom: '10px'
  },
  selectedDays: {
    borderColor: '#00c8a9 !important',
    color: '#00c8a9 !important',
    background: '#FFF',
    fontWeight: '400'
  },
  repeatEvery: {
    display: 'flex',
    justifyContent: 'start',
    fontSize: '25px',
    '& .tite p': {
      lineHeight: '3.8',
      margin: '0'
    },
    '& .number': {
      lineHeight: '1.9'
    },
    '& .add': {
      color: '#57c4a6',
      width: '1.5em',
      height: '1.5em'
    },
    '& .substract': {
      width: '1.5em',
      height: '1.5em'
    }
  },
  monthDate: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  date: {
    padding: '9px 0',
    border: '1px solid',
    borderRadius: '100px',
    width: '40px',
    height: '40px',
    textAlign: 'center',
    marginRight: '10px',
    fontWeight: '400',
    borderColor: '#b2b2c4',
    color: '#848499',
    cursor: 'pointer',
    marginBottom: '10px'
  },
  requiredGear: {
    '& .MuiChip-root': {
      marginTop: '5px',
      marginRight: '10px'
    }
  },
  managerName: {
    margin: '0',
    fontSize: '1.5em'
  },
  templateTitle: {
    paddingBottom: '5px',
    '& .MuiCardHeader-title': {
      fontSize: '20px'
    }
  },
  announcementHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    '& p': {
      margin: '0',
      lineHeight: '2.6',
      fontWeight: 'bold',
    }
  },
  addAnother: {
    fontWeight: 'bold',
    cursor: 'pointer',
    width: 'fit-content'
  },
  announcementReview: {
    marginBottom: '10px',
    '& .title': {
      fontWeight: 'bold',
      wordBreak: "break-word"
    },
    '& .description': {
      color: '#9a9fb1',
      marginTop: '10px',
      wordBreak: "break-word"
    },
    '& .recurring': {
      borderRadius: '10px',
      padding: '2px 8px',
      border: '1px solid',
      color: '#0074c8',
      background: 'rgb(0 116 200 / 10%)',
      width: 'fit-content',
      marginBottom: '10px'
    }
  },
  continueButton: {
    textAlign: 'right',
    marginTop: '20px',
    paddingRight: '0px !important'
  },
  linkBtn: {
    marginTop: '20px',
    padding: 0,
    color: "#00c4a6",
    "@media (max-width: 600px)": {
      margin: '0px',
      padding: '0px'
    },
    "&:focus": {
      color: "#00c4a6",
    },
    "&:hover": {
      color: "#00c4a6",
    }
  },
  announceFooter: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'end'
  },
  total: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  feesText: {
    width: "250px",
    textAlign: "left"
  },
  feesTextAlign: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    marginTop: "20px"
  },
  feesTextItem: {
    textAlign: "left"
  },
  template: {
    padding: '0px 16px 16px 16px'
  },
  templateForm: {
    display: 'flex',
    alignItems: 'end'
  },
  footer: {
    "@media (max-width: 600px)": {
      flexDirection: 'column-reverse'
    }
  },
  assignedRoleEndAdornmentButton: {
    padding: 4,
  },
  assignedRoleEndAdornment: {
    display: "flex",
    alignItems: "center",
    position: 'absolute',
    right: 26
  },
  disabledDay: {
    cursor: "not-allowed",
    pointerEvents: "none",
    touchAction: "none",
    color: "#84849985"
  },
  descriptionCountdown: {
    display: 'flex',
    justifyContent: "flex-end",
    marginTop: theme.spacing(.5)
  }
}));

export default useStyles;