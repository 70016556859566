import { createReducer } from "utils/redux";
import types from "./types";

const initialLoadingState = {
    getDocument: true,
    isAccepting:false
};

const initialDialogState = {
};

const initialState = {
    loading: { ...initialLoadingState },
    dialog: { ...initialDialogState },
    documents: [],
    isAccepted: false
};

const actionsMap = {
    [types.SET_LOADING_STATE]: (state, { payload: { key, value } }) => {
        return {
            ...state,
            loading: {
                ...state.loading,
                [key]: value,
            },
        };
    },
    [types.SET_DIALOG_STATE]: (state, { payload: { key, value } }) => {
        return {
            ...state,
            dialog: {
                ...state.dialog,
                [key]: value,
            },
        };
    },
    [types.GET_DOCUMENTS]: (state, { payload: { data } }) => {
        return {
            ...state,
            documents: data,
        };
    },
    [types.SET_IS_ACCEPTED]: (state, { payload: { value } }) => {
        return {
            ...state,
            isAccepted: value,
        };
    },
};

export default createReducer(initialState, actionsMap);