import {gql} from "@apollo/client";

export default gql`
  query($jobId: Int!, $isFuture: Boolean){
    recurringJobsByJobId(jobId: $jobId, isFuture: $isFuture) {
      id
      startUtc
      endUtc
      timezone
      referenceJobId
    }
  }
`