import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { JobStatusesMap, TimeframeLabel } from "helpers/constants";
import MainContent from "components/Containers/MainContent";
import { MuiButton } from "components/CustomButtons/Button";
import Dialog from "components/Dialog/Dialog";
import SubNav from "components/Navigation/SubNav";
import SubNavTab from "components/Navigation/SubNavTab";
import {
    actions as JobDetailActions,
    selectors as JobDetailSelectors,
} from "store/modules/jobDetails";
import { selectors as JobSelectors } from "store/modules/jobs";
import { fetchRatingOption } from "store/modules/jobDetails/actions";
import JobHeader from "./components/JobHeader";
import JobPositions from "./components/JobPositions";
import JobSummary from "./components/JobSummary";
import JobCancelTable from "./components/JobCancelTable";
import { useTranslation } from "react-i18next";
import ScheduledJobs from "./components/ScheduledJobs/ScheduledJobs";

const useStyles = makeStyles(({ palette, spacing }) => ({
    header: {
        background: "#FFF",
    },
    summary: {
        "@media (max-width: 600px)": {
            padding: '0px',
        }
    },
    progress: {
        color: palette.secondary.light,
        position: "absolute",
        top: "50%",
        left: "45%",
    },
    visibility: {
        paddingTop: spacing(2),
    },
    visibilityFont: {
        color: "#6e7a8a",
        paddingLeft: spacing(1),
    },
}));
export default function JobDetails(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isInitialized } = useSelector(JobDetailSelectors.getJobDetails);
    const isLoading = useSelector(JobDetailSelectors.getIsPageLoading);
    const jobDetail = useSelector(JobDetailSelectors.getJobDetails);
    const isBaseJob = useSelector(JobDetailSelectors.getIsBaseJob);
    const isFixedTermParentJob = useSelector(JobDetailSelectors.getIsFixedTermParentJob);
    const timeframeState = useSelector(JobSelectors.getTimeframeStats);
    const [value, setValue] = useState(0);
    const { t } = useTranslation();
    const params = new URLSearchParams(props?.history?.location?.search);
    const view = parseInt(params?.get?.("view"), 10);

    const handleChange = (event, newValue) => {
        props?.history?.replace?.(`?view=${newValue}`);
        setValue(newValue);
    };

    useEffect(() => {
        if (props.match.params && props.match.params.id && jobDetail && jobDetail.id && +jobDetail.id === +props.match.params.id) {
            if (!isFixedTermParentJob) props.history.replace(props.history.location.pathname.replace("/dashboard", ""))
        }
    }, [props.match.params.id, isFixedTermParentJob, jobDetail]);

    useEffect(() => {
        if (props.match.params && props.match.params.id) {
            dispatch(JobDetailActions.fetchJob(props.match.params.id, true,true));
        }
        if (props?.history?.location?.search) {
            if (!isNaN(view)) {
                setValue(parseInt(view, 10));
            }
        } else {
            props.history?.replace?.(`?view=${value}`);
        }
        dispatch(fetchRatingOption());
    }, [props.match.params.id]);

    useEffect(() => {
        if (isFixedTermParentJob) {
            dispatch(JobDetailActions.fetchScheduledJobs(props?.match?.params?.id));
        }
    }, [isFixedTermParentJob]);

    const showCancelDialog = () => {
        dispatch(JobDetailActions.setIsCancelJobDialogState(true));
    };

    const cancelJob = (recurringJobList, id) => {
        dispatch(JobDetailActions.cancelRecurringJob(recurringJobList, id, true)).then(data => {
            if (data) {
                let jobType = TimeframeLabel[jobDetail.timeframe]
                if (jobType === 'Upcoming' && timeframeState.today > 0 && timeframeState.tomorrow > 0) {
                    props.history.replace(`/jobs/multi-shift/overview?timeframe=2`);
                }
                else if (jobType === 'Upcoming' && timeframeState.today == 0 && timeframeState.tomorrow > 0) {
                    props.history.replace(`/jobs/multi-shift/overview?timeframe=1`);
                }
                else if (jobType === 'Upcoming' && timeframeState.today == 0 && timeframeState.tomorrow == 0) {
                    props.history.replace(`/jobs/multi-shift/overview?timeframe=0`);
                }
                else {
                    props.history.replace(`/jobs/multi-shift/overview`)
                }
            }
        });
    };

    if (isLoading) {
        return <CircularProgress classes={{ root: classes.progress }} />;
    }

    if (!isInitialized) {
        return null;
    }
    const tabs = () => {
        return [
            <SubNavTab key="Detail" label={t("common_labels_detail")} />,
            <SubNavTab key="Workers" label={t("common_labels_workers")} />,
            ...(isBaseJob && jobDetail.status === JobStatusesMap.NotStarted
                ? [
                    <MuiButton
                        type="Button"
                        key="cancel1"
                        variant="text"
                        color="error"
                        onClick={showCancelDialog}
                    >
                        {t("common_labels_cancel_job")}
                    </MuiButton>,
                ]
                : []),
        ];
    };
    return (
        <React.Fragment>
            <MainContent className={classes.header}>
                <JobHeader />
            </MainContent>
            <SubNav value={value} onChange={handleChange} tabs={tabs()} />

            {value === 0 && (
                <MainContent className={classes.summary}>
                    <JobSummary />
                    {isFixedTermParentJob && <ScheduledJobs jobId={props?.match?.params?.id} />}
                </MainContent>
            )}
            {value === 1 && (
                <MainContent>
                    <JobPositions />
                </MainContent>
            )}

            <JobCancelTable jobDetail={jobDetail} cancelJob={cancelJob} isFixedTerm={jobDetail?.isFixedTerm} />

        </React.Fragment>
    );
}