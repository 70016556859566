export default {
  SET_EMPLOYER_PROFILE_DATA: "profile/SET_EMPLOYER_PROFILE_DATA",
  SET_EMPLOYER_DETAIL_DATA: "profile/SET_EMPLOYER_DETAIL_DATA",
  SET_LOADING_STATE: "profile/SET_LOADING_STATE",
  SET_DIALOG_STATE: "profile/SET_DIALOG_STATE",
  SET_TIME_BASE_PREMIUM_LIST: "profile/SET_TIME_BASE_PREMIUM_LIST",
  SET_TIME_BASE_PREMIUM_FORM: "profile/SET_TIME_BASE_PREMIUM_FORM",
  SET_EDIT_TIME_BASE_PREMIUM_FORM: "profile/SET_EDIT_TIME_BASE_PREMIUM_FORM",
  CLEAR_TIME_BASE_PREMIUM_FORM: "profile/CLEAR_TIME_BASE_PREMIUM_FORM",
  SET_SELECTED_TIMEBASE_PREMIUM: "profile/SET_SELECTED_TIMEBASE_PREMIUM",
  SET_DYANMIC_WORK_TYPES_DATA: "profile/SET_DYANMIC_WORK_TYPES_DATA",
};
