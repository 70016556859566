import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent } from "@mui/material";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";
import { actions as SettingsActions, selectors as SettingsSelectors } from "store/modules/settings";
import Button from "components/Button/Button";
import { useTranslation } from "react-i18next";
import i18n from "i18n";


function CompanyJobTemplate({ viewDetail }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const companyJobTemplateList = useSelector(SettingsSelectors.getCompanyJobTemplateListData);
  const isFrenchLanguage = i18n.language === "fr";

  useEffect(() => {
    dispatch(SettingsActions.fetchCompanyJobTemplate());
  }, []);

  const columns = [
    {
      Header: t("common_table_column_name"),
      id: "templateName",
      accessor: ({ templateName }) => templateName,
    },
    {
      Header: t("common_table_column_work_type"),
      id: "workType",
      accessor: ({ workType }) => workType?.[isFrenchLanguage ? "frenchName" : "label"],
    },
    {
      Header: t("common_table_column_address"),
      id: "street",
      accessor: ({ street }) => street,
      Cell: ({ row: { original: { street, city, region } } }) => (
        <>
          {(street || "") + ' ' + (city || "") + ' ' + (region || "")}
        </>
      ),
    },
    {
      Header: "",
      id: "action",
      disableFilters: true,
      disableSortBy: true,
      accessor: ({ street }) => street,
      styles: {
        textAlign: 'right'
      },
      Cell: ({ row: { original } }) => (
        <div>
          <Button
            label={t("common_labels_view")}
            size="small"
            alignIcon="left"
            onClick={() => viewDetail(original)}
          />
        </div>
      ),
    }
  ];

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <ReactTableSimple
            columns={columns}
            data={companyJobTemplateList}
          />
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default CompanyJobTemplate;
