import { combineReducers } from "redux";
import jobDetails from "store/modules/jobDetails";
import jobs from "store/modules/jobs";
import login from "store/modules/login";
import meta from "store/modules/meta";
import postJob from "store/modules/postJob";
import communication from "./modules/communication";
import managers from "./modules/managers";
import profile from "./modules/profile";
import settings from "./modules/settings";
import workers from "./modules/workers";
import reviewDocuments from "./modules/review-documents";

export default combineReducers({
  login,
  meta,
  jobs,
  jobDetails,
  postJob,
  workers,
  managers,
  profile,
  settings,
  communication,
  reviewDocuments
});
