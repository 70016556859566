import ApolloClient from "utils/apollo";
import { flattenGraphQLArray } from "utils/graphql";
import * as pagination from "utils/graphql-pagination";
import { getRelation } from "utils/redux";
import { getTimeZone } from "utils/time";
import { actions as MetaActions } from "store/modules/meta";

import { getMe } from "../login/selectors";
import { paginationSettings } from "./constants";
import FetchJobListStats from "./graphql/queries/fetch-job-list-stats";
import FetchJobTimeframeStats from "./graphql/queries/fetch-job-timeframe-stats";
import FetchJobsQuery from "./graphql/queries/fetch-jobs";
import {
  getDraftsPaginationData,
  getHistoryPaginationData,
  getListData,
  getQueuedPaginationData,
  getTodayActivePaginationData,
  getTodayCompletedPaginationData,
  getTodayFilledPaginationData,
  getTodayTotalPaginationData,
  getTodayUnfilledPaginationData,
  getTomorrowFilledPaginationData,
  getTomorrowTotalPaginationData,
  getTomorrowUnfilledPaginationData,
  getUpcomingFilledPaginationData,
  getUpcomingTotalPaginationData,
  getUpcomingUnfilledPaginationData,
} from "./selectors";
import types from "./types";
import i18n from "i18n";

export const fetchTimeframeStats = () => (dispatch, getState) => {
  const isFixedTerm = window?.location?.pathname?.includes("multi-shift");
  const { employerId } = getMe(getState());
  dispatch({ type: types.FETCH_TIMEFRAME_STATS_REQUEST });
  return ApolloClient.query({
    query: FetchJobTimeframeStats,
    variables: {
      employerId: employerId,
      timezone: getTimeZone(),
      isFixedTerm,
    },
  })
    .then(({ data }) => {
      const payload = {
        today: data.todayCount.totalCount,
        tomorrow: data.tomorrowCount.totalCount,
        upcoming: data.upcomingCount.totalCount,
        drafts: data.draftCount.totalCount,
        queued: data.queueCount.totalCount,
      };
      dispatch({ type: types.FETCH_TIMEFRAME_STATS_SUCCESS, payload });
    })
    .catch(e => {
      dispatch(MetaActions.errorToast(i18n.t("toast_error_job_overview_get_timeframe",{message:e.message})));
      dispatch({ type: types.FETCH_TIMEFRAME_STATS_FAILURE, payload: e });
    });
};

export const fetchListStats = timeframe => (dispatch, getState) => {
  const isFixedTerm = window?.location?.pathname?.includes("multi-shift");
  const { employerId } = getMe(getState());
  dispatch({ type: types.FETCH_LIST_STATS_REQUEST });
  return ApolloClient.query({
    query: FetchJobListStats,
    variables: {
      employerId: employerId,
      timeframe,
      timezone: getTimeZone(),
      isFixedTerm,
    },
  })
    .then(({ data }) => {
      dispatch({
        type: types.FETCH_LIST_STATS_SUCCESS,
        payload: { timeframe: timeframe.toLowerCase(), data },
      });
    })
    .catch(e => {
      dispatch(MetaActions.errorToast(i18n.t("toast_error_job_overview_get_stats_state",{message:e.message})));
      dispatch({ type: types.FETCH_LIST_STATS_FAILURE, payload: e });
    });
};

export const fetchJobsListFactory = config => pageIndex => (dispatch, getState) => {
  const isFixedTerm = window?.location?.pathname?.includes("multi-shift");
  const state = getState();
  const treeConfig = {
    timeframe: config.timeframe,
    status: config.status,
  };
  const pageInfo = config.getPageInfo(state);
  const pagingVars = dispatch(config.getPagingVars(pageInfo, pageIndex));
  const relations = {
    Employer: ["companyName"],
    WorkType: ["label"],
  };
  const extraVars = config.variables || {};
  const { employerId } = getMe(state);
  return ApolloClient.query({
    query: config.query,
    variables: {
      employerId: employerId,
      ...pagingVars,
      ...extraVars,
      isFixedTerm,
      order: getListData({ ...treeConfig, part: "sort" })(state).map(sort => {
        return {
          field: sort.id,
          direction: sort.desc ? "DESC" : "ASC",
          relation: getRelation(relations, sort.id),
        };
      }),
      like: getListData({ ...treeConfig, part: "filter" })(state).map(filter => {
        return {
          field: filter.id,
          value: filter.value,
          relation: getRelation(relations, filter.id),
        };
      }),
    },
  })
    .then(({ data }) => {
      const { jobs } = data;
      const paginationData = {
        ...jobs.pageInfo,
        totalCount: jobs.totalCount,
      };

      dispatch({
        type: types.SET_LIST_DATA,
        payload: {
          data: flattenGraphQLArray(jobs),
          timeframe: config.timeframe,
          status: config.status,
          part: "data",
        },
      });
      dispatch(pagination.updatePageInfo(config.paginationSetting, paginationData));
      dispatch(pagination.doneLoading(config.paginationSetting));
    })
    .catch(() => {
      dispatch(pagination.doneLoading(config.paginationSetting));
    });
};

export const setSortFactory = config => sort => dispatch => {
  dispatch({
    type: types.SET_LIST_DATA,
    payload: {
      data: sort,
      timeframe: config.timeframe,
      status: config.status,
      part: "sort",
    },
  });
  // return dispatch(config.refresh());
};

export const setFilterFactory = config => filter => dispatch => {
  return dispatch({
    type: types.SET_LIST_DATA,
    payload: {
      data: filter,
      timeframe: config.timeframe,
      status: config.status,
      part: "filter",
    },
  });
  // return dispatch(config.refresh());
};

export const setPageSizeFactory = config => pageSize => dispatch => {
  return dispatch(pagination.updatePageInfo(config.paginationSetting, { pageSize }));
  // return dispatch(config.refresh());
};

export const fetchTodayTotalJobs = fetchJobsListFactory({
  getPageInfo: getTodayTotalPaginationData,
  getPagingVars: pagination.pagingVarsFactory(paginationSettings.todayTotal),
  query: FetchJobsQuery,
  variables: {
    timeframe: "Today",
    isActive: true,
    timezone: getTimeZone(),
  },
  timeframe: "today",
  status: "total",
  paginationSetting: paginationSettings.todayTotal,
});

export const setTodayTotalSort = setSortFactory({
  timeframe: "today",
  status: "total",
  refresh: fetchTodayTotalJobs,
});

export const setTodayTotalFilter = setFilterFactory({
  timeframe: "today",
  status: "total",
  refresh: fetchTodayTotalJobs,
});

export const setTodayTotalPageSize = setPageSizeFactory({
  paginationSetting: paginationSettings.todayTotal,
  refresh: fetchTodayTotalJobs,
});

export const fetchTodayActiveJobs = fetchJobsListFactory({
  getPageInfo: getTodayActivePaginationData,
  getPagingVars: pagination.pagingVarsFactory(paginationSettings.todayActive),
  query: FetchJobsQuery,
  variables: {
    timeframe: "Today",
    status: "InProgress",
    isActive: true,
    timezone: getTimeZone(),
  },
  timeframe: "today",
  status: "active",
  paginationSetting: paginationSettings.todayActive,
});

export const setTodayActiveSort = setSortFactory({
  timeframe: "today",
  status: "active",
  refresh: fetchTodayActiveJobs,
});

export const setTodayActiveFilter = setFilterFactory({
  timeframe: "today",
  status: "active",
  refresh: fetchTodayActiveJobs,
});

export const setTodayActivePageSize = setPageSizeFactory({
  paginationSetting: paginationSettings.todayActive,
  refresh: fetchTodayActiveJobs,
});

export const fetchTodayUnfilledJobs = fetchJobsListFactory({
  getPageInfo: getTodayUnfilledPaginationData,
  getPagingVars: pagination.pagingVarsFactory(paginationSettings.todayUnfilled),
  query: FetchJobsQuery,
  variables: {
    timeframe: "Today",
    status: "NotStarted",
    filled: false,
    timezone: getTimeZone(),
  },
  timeframe: "today",
  status: "unfilled",
  paginationSetting: paginationSettings.todayUnfilled,
});

export const setTodayUnfilledSort = setSortFactory({
  timeframe: "today",
  status: "unfilled",
  refresh: fetchTodayUnfilledJobs,
});

export const setTodayUnfilledFilter = setFilterFactory({
  timeframe: "today",
  status: "unfilled",
  refresh: fetchTodayUnfilledJobs,
});

export const setTodayUnfilledPageSize = setPageSizeFactory({
  paginationSetting: paginationSettings.todayUnfilled,
  refresh: fetchTodayUnfilledJobs,
});

export const fetchTodayFilledJobs = fetchJobsListFactory({
  getPageInfo: getTodayFilledPaginationData,
  getPagingVars: pagination.pagingVarsFactory(paginationSettings.todayFilled),
  query: FetchJobsQuery,
  variables: {
    timeframe: "Today",
    status: "NotStarted",
    isActive: true,
    filled: true,
    timezone: getTimeZone(),
  },
  timeframe: "today",
  status: "filled",
  paginationSetting: paginationSettings.todayFilled,
});

export const setTodayFilledSort = setSortFactory({
  timeframe: "today",
  status: "filled",
  refresh: fetchTodayFilledJobs,
});

export const setTodayFilledFilter = setFilterFactory({
  timeframe: "today",
  status: "filled",
  refresh: fetchTodayFilledJobs,
});

export const setTodayFilledPageSize = setPageSizeFactory({
  paginationSetting: paginationSettings.todayFilled,
  refresh: fetchTodayFilledJobs,
});

export const fetchTodayCompletedJobs = fetchJobsListFactory({
  getPageInfo: getTodayCompletedPaginationData,
  getPagingVars: pagination.pagingVarsFactory(paginationSettings.todayCompleted),
  query: FetchJobsQuery,
  variables: {
    timeframe: "Today",
    status: "Completed",
    isActive: true,
    timezone: getTimeZone(),
  },
  timeframe: "today",
  status: "completed",
  paginationSetting: paginationSettings.todayCompleted,
});

export const setTodayCompletedSort = setSortFactory({
  timeframe: "today",
  status: "completed",
  refresh: fetchTodayCompletedJobs,
});

export const setTodayCompletedFilter = setFilterFactory({
  timeframe: "today",
  status: "completed",
  refresh: fetchTodayCompletedJobs,
});

export const setTodayCompletedPageSize = setPageSizeFactory({
  paginationSetting: paginationSettings.todayCompleted,
  refresh: fetchTodayCompletedJobs,
});

export const fetchTomorrowTotalJobs = fetchJobsListFactory({
  getPageInfo: getTomorrowTotalPaginationData,
  getPagingVars: pagination.pagingVarsFactory(paginationSettings.tomorrowTotal),
  query: FetchJobsQuery,
  variables: {
    timeframe: "Tomorrow",
    isActive: true,
    timezone: getTimeZone(),
  },
  timeframe: "tomorrow",
  status: "total",
  paginationSetting: paginationSettings.tomorrowTotal,
});

export const setTomorrowTotalSort = setSortFactory({
  timeframe: "tomorrow",
  status: "total",
  refresh: fetchTomorrowTotalJobs,
});

export const setTomorrowTotalFilter = setFilterFactory({
  timeframe: "tomorrow",
  status: "total",
  refresh: fetchTomorrowTotalJobs,
});

export const setTomorrowTotalPageSize = setPageSizeFactory({
  paginationSetting: paginationSettings.tomorrowTotal,
  refresh: fetchTomorrowTotalJobs,
});

export const fetchTomorrowUnfilledJobs = fetchJobsListFactory({
  getPageInfo: getTomorrowUnfilledPaginationData,
  getPagingVars: pagination.pagingVarsFactory(paginationSettings.tomorrowUnfilled),
  query: FetchJobsQuery,
  variables: {
    timeframe: "Tomorrow",
    status: "NotStarted",
    isActive: true,
    filled: false,
    timezone: getTimeZone(),
  },
  timeframe: "tomorrow",
  status: "unfilled",
  paginationSetting: paginationSettings.tomorrowUnfilled,
});

export const setTomorrowUnfilledSort = setSortFactory({
  timeframe: "tomorrow",
  status: "unfilled",
  refresh: fetchTomorrowUnfilledJobs,
});

export const setTomorrowUnfilledFilter = setFilterFactory({
  timeframe: "tomorrow",
  status: "unfilled",
  refresh: fetchTomorrowUnfilledJobs,
});

export const setTomorrowUnfilledPageSize = setPageSizeFactory({
  paginationSetting: paginationSettings.tomorrowUnfilled,
  refresh: fetchTomorrowUnfilledJobs,
});

export const fetchTomorrowFilledJobs = fetchJobsListFactory({
  getPageInfo: getTomorrowFilledPaginationData,
  getPagingVars: pagination.pagingVarsFactory(paginationSettings.tomorrowFilled),
  query: FetchJobsQuery,
  variables: {
    timeframe: "Tomorrow",
    status: "NotStarted",
    isActive: true,
    filled: true,
    timezone: getTimeZone(),
  },
  timeframe: "tomorrow",
  status: "filled",
  paginationSetting: paginationSettings.tomorrowFilled,
});

export const setTomorrowFilledSort = setSortFactory({
  timeframe: "tomorrow",
  status: "filled",
  refresh: fetchTomorrowFilledJobs,
});

export const setTomorrowFilledFilter = setFilterFactory({
  timeframe: "tomorrow",
  status: "filled",
  refresh: fetchTomorrowFilledJobs,
});

export const setTomorrowFilledPageSize = setPageSizeFactory({
  paginationSetting: paginationSettings.tomorrowFilled,
  refresh: fetchTomorrowFilledJobs,
});

export const fetchUpcomingTotalJobs = fetchJobsListFactory({
  getPageInfo: getUpcomingTotalPaginationData,
  getPagingVars: pagination.pagingVarsFactory(paginationSettings.upcomingTotal),
  query: FetchJobsQuery,
  variables: {
    timeframe: "Upcoming",
    status: "NotStarted",
    isActive: true,
    timezone: getTimeZone(),
  },
  timeframe: "upcoming",
  status: "total",
  paginationSetting: paginationSettings.upcomingTotal,
});

export const setUpcomingTotalSort = setSortFactory({
  timeframe: "upcoming",
  status: "total",
  refresh: fetchUpcomingTotalJobs,
});

export const setUpcomingTotalFilter = setFilterFactory({
  timeframe: "upcoming",
  status: "total",
  refresh: fetchUpcomingTotalJobs,
});

export const setUpcomingTotalPageSize = setPageSizeFactory({
  paginationSetting: paginationSettings.upcomingTotal,
  refresh: fetchUpcomingTotalJobs,
});

export const fetchUpcomingUnfilledJobs = fetchJobsListFactory({
  getPageInfo: getUpcomingUnfilledPaginationData,
  getPagingVars: pagination.pagingVarsFactory(paginationSettings.upcomingUnfilled),
  query: FetchJobsQuery,
  variables: {
    timeframe: "Upcoming",
    status: "NotStarted",
    isActive: true,
    filled: false,
    timezone: getTimeZone(),
  },
  timeframe: "upcoming",
  status: "unfilled",
  paginationSetting: paginationSettings.upcomingUnfilled,
});

export const setUpcomingUnfilledSort = setSortFactory({
  timeframe: "upcoming",
  status: "unfilled",
  refresh: fetchUpcomingUnfilledJobs,
});

export const setUpcomingUnfilledFilter = setFilterFactory({
  timeframe: "upcoming",
  status: "unfilled",
  refresh: fetchUpcomingUnfilledJobs,
});

export const setUpcomingUnfilledPageSize = setPageSizeFactory({
  paginationSetting: paginationSettings.upcomingUnfilled,
  refresh: fetchUpcomingUnfilledJobs,
});

export const fetchUpcomingFilledJobs = fetchJobsListFactory({
  getPageInfo: getUpcomingFilledPaginationData,
  getPagingVars: pagination.pagingVarsFactory(paginationSettings.upcomingFilled),
  query: FetchJobsQuery,
  variables: {
    timeframe: "Upcoming",
    status: "NotStarted",
    isActive: true,
    filled: true,
    timezone: getTimeZone(),
  },
  timeframe: "upcoming",
  status: "filled",
  paginationSetting: paginationSettings.upcomingFilled,
});

export const setUpcomingFilledSort = setSortFactory({
  timeframe: "upcoming",
  status: "filled",
  refresh: fetchUpcomingFilledJobs,
});

export const setUpcomingFilledFilter = setFilterFactory({
  timeframe: "upcoming",
  status: "filled",
  refresh: fetchUpcomingFilledJobs,
});

export const setUpcomingFilledPageSize = setPageSizeFactory({
  paginationSetting: paginationSettings.upcomingFilled,
  refresh: fetchUpcomingFilledJobs,
});

export const fetchHistoryJobs = fetchJobsListFactory({
  getPageInfo: getHistoryPaginationData,
  getPagingVars: pagination.pagingVarsFactory(paginationSettings.history),
  query: FetchJobsQuery,
  variables: {
    timeframe: "Past",
    status: "Completed",
    isActive: true,
    timezone: getTimeZone(),
  },
  timeframe: "history",
  paginationSetting: paginationSettings.history,
});

export const setHistorySort = setSortFactory({
  timeframe: "history",
  refresh: fetchHistoryJobs,
});

export const setHistoryFilter = setFilterFactory({
  timeframe: "history",
  refresh: fetchHistoryJobs,
});

export const setHistoryPageSize = setPageSizeFactory({
  paginationSetting: paginationSettings.history,
  refresh: fetchHistoryJobs,
});

export const fetchDraftsJobs = fetchJobsListFactory({
  getPageInfo: getDraftsPaginationData,
  getPagingVars: pagination.pagingVarsFactory(paginationSettings.drafts),
  query: FetchJobsQuery,
  variables: {
    isActive: false,
    timezone: getTimeZone(),
  },
  timeframe: "drafts",
  paginationSetting: paginationSettings.drafts,
});

export const setDraftsSort = setSortFactory({
  timeframe: "drafts",
  refresh: fetchDraftsJobs,
});

export const setDraftsFilter = setFilterFactory({
  timeframe: "drafts",
  refresh: fetchDraftsJobs,
});

export const setDraftsPageSize = setPageSizeFactory({
  paginationSetting: paginationSettings.drafts,
  refresh: fetchDraftsJobs,
});

export const fetchQueuedJobs = fetchJobsListFactory({
  getPageInfo: getQueuedPaginationData,
  getPagingVars: pagination.pagingVarsFactory(paginationSettings.queued),
  query: FetchJobsQuery,
  variables: {
    isActive: false,
    isQueued: true,
    timezone: getTimeZone(),
  },
  timeframe: "queued",
  paginationSetting: paginationSettings.queued,
});

export const setQueuedSort = setSortFactory({
  timeframe: "queued",
  refresh: fetchQueuedJobs,
});

export const setQueuedFilter = setFilterFactory({
  timeframe: "queued",
  refresh: fetchQueuedJobs,
});

export const setQueuedPageSize = setPageSizeFactory({
  paginationSetting: paginationSettings.queued,
  refresh: fetchQueuedJobs,
});
