import React from "react";
import { Grid } from "@mui/material";
import Dialog from "components/Dialog/Dialog";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

function FeesDialog(props) {
    const classes = useStyles();
    const { feesDialog, handleClickCloseFeesDialog } = props;
    const { t } = useTranslation();

    return (
        <Dialog
            open={feesDialog}
            onConfirm={handleClickCloseFeesDialog}
            confirmText={t("common_labels_ok")}
            alertType="info"
        >
            <div className={classes.total} style={{ textAlign: "left" }}>{t("component_fees_title")}</div>
            <div className={classes.feesTextAlign}>
                <Grid container xs={12}>
                    <Grid item xs={2} className={classes.feesTextItem}>17%</Grid>
                    <Grid item xs={10} className={classes.feesTextItem}>{t("component_fees_worker_deduction")}</Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={2} className={classes.feesTextItem}>60%</Grid>
                    <Grid item xs={10} className={classes.feesTextItem}>{t("component_fees_internal_payroll")}</Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={2} className={classes.feesTextItem}>9%</Grid>
                    <Grid item xs={10} className={classes.feesTextItem}>{t("component_fees_financing")}</Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={2} className={classes.feesTextItem}>5%</Grid>
                    <Grid item xs={10} className={classes.feesTextItem}>{t("component_fees_marketing")}</Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={2} className={classes.feesTextItem}>3.5%</Grid>
                    <Grid item xs={10} className={classes.feesTextItem}>{t("component_fees_software")}</Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={2} className={classes.feesTextItem}>5.5%</Grid>
                    <Grid item xs={10} className={classes.feesTextItem}>{t("component_fees_other_cost")}</Grid>
                </Grid>
            </div>
        </Dialog>
    )
}

export default FeesDialog