export const getDocumentsData = (state) => {
  return state.reviewDocuments.documents;
};

// Loading selectors
export const getLoadingStateFactory = loader => state => {
  return state.reviewDocuments.loading[loader] || false;
};

export const getIsPageLoading = getLoadingStateFactory("getDocument");
export const getIsAccepting = getLoadingStateFactory("isAccepting");

// dialog selectors
export const getDialogStateFactory = key => state => {
  return state.reviewDocuments.dialog[key] || false;
};

export const getIsAccepted = state => {
  return state.reviewDocuments.isAccepted;
};