import { Grow } from "@mui/material";
import React from "react";
import DownloadFile from "./components/DownloadFile";
import { allowedDocumentsRenderer } from "./helpers/constants";

const DocumentViewer = ({ document,setIsLoadingDoc }) => {
    const { Renderer } = allowedDocumentsRenderer?.find(p => p.mime === document?.agreementDocument?.documentType) || {};

    if (Renderer) return (
        <Renderer
            document={document}
            setIsLoadingDoc={setIsLoadingDoc}
        />
    );

    return (
        <Grow in={Boolean(document?.agreementDocument?.signedDocumentImageUrl)} timeout={1000}>
            <div style={{ height: "100%", width: "100%", justifyContent: "center", alignItems: "center", display: 'flex', flexDirection: "column" }}>
                <p>
                    Document type is not supported
                </p>
                <DownloadFile url={document?.agreementDocument?.signedDocumentImageUrl} />
            </div>
        </Grow>
    )
};

export default DocumentViewer;