import ApolloClient from "utils/apollo";
import FetchDocuments from "./graphql/queries/fetch-documents";
import AcceptDocuments from "./graphql/mutations/accept-documents";
import { actions as MetaActions } from "store/modules/meta";
import types from "./types";
import { getDocumentsData } from "./selectors";

const setDocuments = (data) => ({ type: types.GET_DOCUMENTS, payload: { data } })
export const setIsAccepted = (value) => ({ type: types.SET_IS_ACCEPTED, payload: { value } })

//set loading state
const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});
const setDocumentsLoading = setLoadingState("getDocument");
const setIsAccepting = setLoadingState("isAccepting");

//set dialog state
const setDialogState = key => value => ({
    type: types.SET_DIALOG_STATE,
    payload: { key, value },
});


export const fetchDocuments = (data) => (dispatch) => {
    dispatch(setDocumentsLoading(true));
    return ApolloClient.query({
        query: FetchDocuments,
        variables: {
            data
        },
    })
        .then((res) => {
            const { data, errors } = res;
            if(errors && errors.length) throw new Error(errors[0].message)
            dispatch(setDocuments(data.getPositionDocumentByPositionId));
            dispatch(setDocumentsLoading(false));
            return res;
        })
        .catch((er) => {
            dispatch(MetaActions.errorToast(`${er.message}`));
            dispatch(setDocumentsLoading(false));
            return er;
        });
};

export const acceptDocuments = (data = {}) => (dispatch, getState) => {
    const state = getState();
    const documents = getDocumentsData(state);
    dispatch(setIsAccepting(true));

    return ApolloClient.mutate({
        mutation: AcceptDocuments,
        variables: {
            data: {
                ...data,
                documents: documents.map(a => ({
                    documentId: a.documentId, documentStatus: "ACCEPT"
                }))
            }
        },
    })
        .then(({ data, errors }) => {
            if (errors && errors.length) {
                throw new Error(errors[0].message);
            };
            if (data) {
                dispatch(setIsAccepted(true));
                dispatch(setIsAccepting(false));
            }
        })
        .catch((er) => {
            dispatch(MetaActions.errorToast(`${er.message}`));
            dispatch(setIsAccepting(false));
        });
};