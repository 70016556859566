import React from "react";
import moment from "moment-timezone";
import {
  convertUTCToLocal,
  convertUTCToTimezoneWithOffset,
  displayLocalDateTime,
  formattedTimeWithOffset,
} from "utils/time";
import JobListItemMenu from "../components/JobListItemMenu";
import i18n from "i18n";

export const sharedColumns = ({ t, ...props }) => [
  {
    Header: t("common_table_column_id"),
    id: "id",
    accessor: "id",
    className: "-cursor-pointer",
    styles: {
      width: '100px'
    },
  },
  {
    Header: t("common_table_column_work_type"),
    id: "label",
    // id: "workType.label",
    accessor: i18n.language === "fr"?"workType.frenchName":"workType.label",
    className: "-cursor-pointer",
    Cell: ({ row: { original } }) => {
      // console.log("original", original.workType)
      return original.workType ? original.workType[i18n.language === "fr" ? "frenchName" : "label"] : ''
    }
  },
  {
    Header: t("common_table_column_start_date"),
    id: "startUtc",
    accessor: "startUtc",
    disableFilters: true,
    styles: {
      minWidth: '125px'
    },
    Cell: ({ row: { original } }) => {
      if (original?.isFixedTerm) {
        return (
          <div>
            {`${convertUTCToTimezoneWithOffset(original.startUtc, original.timezone, "YYYY/MM/DD", true)} - ${moment(original.jobRecurring.frequencyEndAt).format("YYYY/MM/DD")} ${convertUTCToTimezoneWithOffset(original.startUtc, original.timezone, " ")} ${displayLocalDateTime(original.startUtc, original.timezone, "YYYY/MM/DD", moment(original.jobRecurring.frequencyEndAt).format("YYYY/MM/DD"))}`}
          </div>
        )
      }
      return (
        <div>
          {convertUTCToTimezoneWithOffset(original.startUtc, original.timezone, "MMM Do, YYYY")}
          <br />
          {displayLocalDateTime(original.startUtc, original.timezone, "MMM Do, YYYY")}
        </div>
      );
    },
    sortable: false,
    className: "-cursor-pointer",
  },
  {
    Header: t("common_table_column_shift"),
    id: "Shift",
    disableSortBy: true,
    Cell: ({ row: { original } }) => {
      return formattedTimeWithOffset(original.startUtc, original.endUtc, original.timezone);
    },
    accessor: ({ startUtc, endUtc }) => {
      const startTime = moment(startUtc).format("h:mm a");
      const endTime = moment(endUtc).format("h:mm a");
      return `${startTime} - ${endTime}`;
    },
    disableFilters: true,
    className: "-cursor-pointer",
    styles: {
      minWidth: '150px'
    },
  },
  {
    Header: t("common_table_column_skills"),
    id: "skills",
    accessor: ({ skills }) => skills,
    maxWidth: 200,
    filteredRows: row => row,
    className: "-cursor-pointer",
    styles: {
      minWidth: '100px',
      textAlign: "left"
    },
    Cell: ({ row: { original } }) => {
      if (original.jobSkill && original.jobSkill.length) {
        return original.jobSkill.map(s => (
          <li key={s?.id}>{s?.skill?.[i18n.language === "fr" ? "frenchSkillName" : "skillName"]}</li>
        ))
      }
      return original.skills && original.skills.map(n => <li key={n}>{n}</li>)
    }
  },
  {
    Header: t("common_table_column_location"),
    id: "city",
    accessor: ({ city, region }) => `${city}, ${region}`,
    maxWidth: 200,
    filteredRows: row => row,
    className: "-cursor-pointer",
    styles: {
      minWidth: '100px'
    },
  },
  {
    Header: t("common_table_column_workers"),
    id: "peopleNeeded",
    disableFilters: true,
    sortable: false,
    width: 100,
    accessor: ({ peopleNeeded, positionsFilled, isFixedTerm }) => {
      if (isFixedTerm) {
        return `${peopleNeeded}`
      };
      return `${positionsFilled} / ${peopleNeeded}`
    },
    style: { cursor: "pointer", textAlign: "center" },
    headerStyle: { textAlign: "center" },
  },
];

export const createActionColumn = ({
  canDelete = false,
  canEnable = false,
} = {}) => {
  return {
    // Header: (
    //   <Tooltip title={`Toggle Filters`} placement="bottom">
    //     <FilterList
    //       style={{ float: "right", marginTop: 5 }}
    //       color={showFilters ? "secondary" : "primary"}
    //       onClick={() => toggleFilters()}
    //       fontSize="large"
    //     />
    //   </Tooltip>
    // ),
    id: "actions",
    disableSortBy: true,
    filterable: false,
    width: 70,
    // eslint-disable-next-line react/display-name, react/prop-types
    Cell: ({ row: { original } }) => {
      let ensureCanEnable = canEnable;
      if (convertUTCToLocal(original.startUtc).isBefore(moment())) {
        ensureCanEnable = false;
      }

      return (
        <JobListItemMenu jobId={original.id} canDelete={canDelete} canEnable={ensureCanEnable} />
      );
    },
  };
};

export const checkInOutColumn = (t) => ({
  Header: t("common_table_column_inOut"),
  id: "inAndOut",
  disableFilters: true,
  sortable: false,
  width: 100,
  accessor: ({ checkedInCount, checkedOutCount }) => `${checkedInCount} / ${checkedOutCount}`,
  className: "-cursor-pointer",
});

export const confirmedColoumn = {
  Header: "Confirmed",
  id: "confirmed",
  disableFilters: true,
  sortable: false,
  styles: {
    minWidth: '70px'
  },
  accessor: ({ positions, positionsFilled }) => {
    const confirmedCount = (positions || []).filter(({ isConfirmed }) => isConfirmed === true)
      .length;
    return `${confirmedCount}/${positionsFilled}`;
  },
  style: { cursor: "pointer", textAlign: "center" },
  headerStyle: { textAlign: "center" },
};

export const reconfirmedColoumn = {
  Header: "Re confirmed",
  id: "reconfirmed",
  disableFilters: true,
  sortable: false,
  styles: {
    minWidth: '100px'
  },
  accessor: ({ positions, positionsFilled }) => {
    const reconfirmedCount = (positions || []).filter(({ isReconfirmed }) => isReconfirmed === true)
      .length;
    return `${reconfirmedCount}/${positionsFilled}`;
  },
  style: { cursor: "pointer", textAlign: "center" },
  headerStyle: { textAlign: "center" },
};
