import {gql} from "@apollo/client";

export default gql`
  query gears {
    gears {
      id
      gearName
      isActive
      frenchGearName
    }
  }
`;