import {gql} from "@apollo/client";

export const updateProfileMutation = gql`
  mutation editEmployer($data: EditEmployerInput!) {
      editEmployer(data: $data) {
          id
      }
  }
`;

export const uploadEmployerFileToS3Mutation = gql`
  mutation uploadEmployerFileToS3($data: EmployerInput!, $isProfile: Boolean!, $file: Upload!) {
    uploadEmployerFileToS3(data: $data, isProfile: $isProfile, file: $file) {
      id
      profileImageUrl
      logoImageUrl
    }
  }
`;

export const uploadCustomFieldFileToS3Mutation = gql`
  mutation employerCustomFileUpload($employerId: String!, $file: Upload!) {
    employerCustomFileUpload(employerId: $employerId, file: $file){
        filepath
        fileSignedurl
    }
  }
`;

export const updateManagerProfileImageMutation = gql`
  mutation uploadManagerProfileImage($id: String!, $file: Upload!) {
    uploadManagerProfileImage(id: $id, file: $file) {
          id
          signedProfileImageUrl
      }
  }
`;

export const updateManagerProfileMutation = gql`
  mutation editManager($data: ManagerInput!) {
    editManager(data: $data) {
          id
          defaultEmployerId
      }
  }
`;

export default updateProfileMutation;