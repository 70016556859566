import React from 'react'
import Dialog from "components/Dialog/Dialog";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
function PostJobDialog(props) {
    const { handleClose, openPostJob, confirmJob, Time } = props;
    const { t } = useTranslation();

    return (
        <>
            <Dialog
                open={openPostJob}
                onClose={handleClose}
                alertType="form"
                confirmText={t("common_labels_post_job")}
                onConfirm={confirmJob}
                onCancel={handleClose}
            >
                <Grid container direction="column" alignItems="left">
                    <Typography variant="h4" align="left" mb={2}>
                    <div dangerouslySetInnerHTML={
                        { __html: t("page_post_job_review_dialog_shift_time", {houres:10, interpolation: { escapeValue: false } }) }
                    } />
                    </Typography>
                </Grid>
                <Grid container direction="column" alignItems="center">
                    <Typography variant="body1" align="left">
                        <div>
                            {t("page_post_job_review_dialog_shift_time_desc")}
                        </div>
                        <div>
                            {Time}
                        </div>
                    </Typography>
                </Grid>
            </Dialog>
        </>
    )
}
export default PostJobDialog