import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export default function NoOptionsMessage(props) {
    const { t } = useTranslation();
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {t(`common_dynamic_${props.children}`)}
        </Typography>
    );
}
