import React, { useRef, useState } from "react";
import Datetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import isEmpty from "lodash/isEmpty";
import Dialog from "components/Dialog/Dialog";
import { convertLocalToUTC, convertUTCToLocal, convertUTCToTimezone, jobRequiresBreak } from "utils/time";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import { useTranslation } from "react-i18next";

function CheckOutDialog(props) {
  const { open, onClose, onCancel, position } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [breakMins, setBreakMins] = useState(position.breakMins || 0);
  const [showSetBreak, setShowSetBreak] = useState(false);
  const checkoutPicker = useRef(null);
  const companyName = useSelector(JobDetailSelectors.getCompanyName);
  const jobStart = useSelector(JobDetailSelectors.getJobStartUtc);
  const jobEnd = useSelector(JobDetailSelectors.getJobEndUtc);
  const jobTimezone = useSelector(JobDetailSelectors.getJobTimezone);
  const [checkoutValue, setCheckoutValue] = useState(convertUTCToTimezone(position.endShiftUtc || jobEnd, jobTimezone) || null);
  const updatedPosition = useSelector(JobDetailSelectors.getUpdatedPosition(position.id));
  if (isEmpty(updatedPosition)) {
    return null;
  }

  const onChange = value => {
    setCheckoutValue(moment(value));
  };

  const onBreakChange = event => {
    setBreakMins(event.target.value);
  };

  const isValidDate = current => {
    return moment(current).isSameOrAfter(convertUTCToLocal(jobStart, "YYYY-MM-DD"));
  };

  const handleCheckout = () => {
    if (!jobRequiresBreak(position.startShiftUtc, checkoutValue)) {
      //   onConfirm();
      dispatch(JobDetailActions.updatePositionData("endShiftUtc")(position.id, convertLocalToUTC(checkoutValue)));
      dispatch(JobDetailActions.checkoutWorker(position.id));
    } else {
      setShowSetBreak(true);
    }
  };

  const handleBreak = () => {
    setShowSetBreak(false);
    // onConfirm();
    dispatch(JobDetailActions.updatePositionData("endShiftUtc")(position.id, convertLocalToUTC(checkoutValue)));
    dispatch(JobDetailActions.updatePositionData("breakMins")(position.id, breakMins || 0));
    dispatch(JobDetailActions.checkoutWorker(position.id));
  };

  return (
    <>
      <Dialog
        open={open && !showSetBreak}
        title={t("page_job_details_dialog_check_out_title", { name: position.fullName })}
        description={null}
        onClose={onClose}
        onConfirm={handleCheckout}
        onCancel={onCancel}
        alertType="question"
        disableBackdropClick
        icon="alarm_on">
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12}>
            <Typography align="center" variant="body1">
              {t("page_job_details_dialog_check_out_desc", { name: position.fullName, companyName })}
            </Typography>
          </Grid>
          <Grid container item xs={12} style={{ height: 50 }} justifyContent="center">
            <Datetime
              input
              viewMode="time"
              id="end"
              dateFormat="MMM Do"
              timeFormat="h:mm a"
              // timeConstraints={{ minutes: { step: 15 } }}
              onChange={onChange}
              style={{ position: "absolute" }}
              // value={endShift}
              value={checkoutValue}
              checkoutPicker
              inputProps={{
                placeholder: t("common_labels_set_all"),
                ref: checkoutPicker,
              }}
              isValidDate={isValidDate}
            />
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={open && showSetBreak}
        title={t("page_job_details_dialog_break_title", { name: position.fullName })}
        description={t("page_job_details_dialog_break_desc")}
        onClose={onClose}
        onConfirm={handleBreak}
        onCancel={onCancel}
        alertType="question"
        icon="alarm_off">
        <TextField select fullWidth value={breakMins} onChange={onBreakChange} margin="normal">
          {[0, 15, 30, 45, 60].map(mins => (
            <MenuItem key={`breakMins-${mins}`} value={mins}>
              {t("page_job_details_break", { minutes: mins })}
            </MenuItem>
          ))}
        </TextField>
      </Dialog>
    </>
  );
}

CheckOutDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  position: PropTypes.object,
};

export default CheckOutDialog;
