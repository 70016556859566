/* eslint-disable import-helpers/order-imports */

import React, { useEffect, useState } from 'react'
import SubNavTab from 'components/Navigation/SubNavTab';
import { Add, Campaign, History } from '@mui/icons-material';
import SubNav from 'components/Navigation/SubNav';
import MainContent from 'components/Containers/MainContent';
import Latest from './Latest';
import Archive from './Archive';
import Button from 'components/Button/Button';
import AnnouncementFormDialog from './AnnouncementFormDialog';
import { useDispatch, useSelector } from 'react-redux';
import { createAnnouncement } from 'store/modules/communication/actions';
import { actions as communicationActions, selectors as communicationSelectors } from "store/modules/communication";
import { useTranslation } from 'react-i18next';

export default function Communication(props) {
    const [activeTab, setActiveTab] = useState(0);
    const dispatch = useDispatch();
    const openAnnouncementDialog = useSelector(communicationSelectors.getCreateAnnouncementDialog)
    const { t } = useTranslation();

    useEffect(() => {
        if (props.history.location.search) {
            const params = new URLSearchParams(props.history.location.search);
            const tab = parseInt(params.get("tab"), 10);
            if (!isNaN(tab)) {
                setActiveTab(parseInt(tab, 10));
            }
        } else {
            props.history.push(`/communication?tab=${activeTab}`);
        }
    }, []);

    const announcementDialogToggler = () => {
        dispatch(communicationActions.setOpenAnnouncementCreateDialog(!openAnnouncementDialog));
    }

    const handleCreateAnnouncement = (announcement, job) => {
        dispatch(createAnnouncement(announcement, job))
    }

    const handleChange = (event, newValue) => {
        if (props.history.location.search) {
            const params = new URLSearchParams(props.history.location.search);
            params.append("tab", newValue);
            props.history.push(`/communication?${params.toString()}`);
        }
        props.history.push(`/communication?tab=${newValue}`);
        setActiveTab(newValue);
    };

    const tabs = () => {
        return [
            <SubNavTab icon={<Campaign />} key="Announcement" label={t("page_communication_tabs_announcement")} />,
            <SubNavTab icon={<History />} key="History" label={t("page_communication_tabs_archive")} />,
            <Button
                type="Button"
                key="create"
                style={{ alignSelf: "flex-end", marginBottom: "6px" }}
                label={t("page_communication_button_create_announcement")}
                icon={<Add />}
                alignIcon="left"
                size="small"
                onClick={announcementDialogToggler}
            />,
        ];
    };

    const content = () => {
        return [
            <div key="Announcement">
                <Latest history={props.history} />
            </div>,
            <div key="History">
                <Archive history={props.history} />
            </div>,
        ];
    };

    return (
        <React.Fragment>
            <SubNav value={activeTab} onChange={handleChange} tabs={tabs()} />
            <MainContent>
                {content().map((el, i) => {
                    if (i === activeTab) {
                        return el;
                    }
                })}
            </MainContent>
            <AnnouncementFormDialog
                open={openAnnouncementDialog}
                onConfirm={handleCreateAnnouncement}
                onCancel={announcementDialogToggler}
            />
        </React.Fragment>
    )
}
