import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import Button from "components/Button/Button";
import { actions as PostJobActions } from "store/modules/postJob";
import useStyles from "./styles";
import SubNav from "components/Navigation/SubNav";
import SubNavTab from "components/Navigation/SubNavTab";
import MyJobTemplate from "./MyJobTemplate";
import CompanyJobTemplate from "./CompanyJobTemplate";
import Dialog from "components/Dialog/Dialog";
import ViewTemplateDetail from "./ViewTemplateDetail";
import tz_lookup from "tz-lookup";
import { useTranslation } from "react-i18next";

function Template({ handleNext }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [detail, setDetail] = useState({});
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const viewDetail = (detail) => {
    setDetail(detail);
    setOpen(true);
  }
  const onConfirm = () => {
    setOpen(false);
    dispatch(PostJobActions.setWorkType({ value: detail.workTypeId }))
    dispatch(PostJobActions.setIsFixedTerm(false))
    dispatch(PostJobActions.setDescription(detail.description))
    dispatch(PostJobActions.setCity(detail.city))
    dispatch(PostJobActions.setStreet(detail.street))
    dispatch(PostJobActions.setRegion(detail.region))
    dispatch(PostJobActions.setCountry(detail.country))
    dispatch(PostJobActions.setFloor(detail.floor))
    dispatch(PostJobActions.setUnit(detail.unit))
    dispatch(PostJobActions.setLocationInstruction(detail.locationInstruction))
    dispatch(PostJobActions.setNoOfWorkers(detail.peopleNeeded))
    dispatch(PostJobActions.setRequiredGear(detail.jobTemplateGear?.map(a=>a.gear) || []))
    dispatch(PostJobActions.setSkills(detail.jobTemplateSkill?.map(a=>a.skill) || []))
    dispatch(PostJobActions.setIsPrivateJob(detail.isPrivate))
    dispatch(PostJobActions.setTags(detail.tags))
    dispatch(PostJobActions.setLatitude(detail.latitude));
    dispatch(PostJobActions.setLongitude(detail.longitude));
    dispatch(PostJobActions.setPostalCode(detail.postalCode));
    dispatch(PostJobActions.setAddress({
      label: detail.street + ' ' + detail.city + ' ' + detail.region
    }))
    dispatch(PostJobActions.setIsTempleteSelected(true));
    if (detail.latitude && detail.longitude) {
      const timezone = tz_lookup(detail.latitude, detail.longitude);
      dispatch(PostJobActions.setTimezone(timezone));
    }
    handleNext()
  }
  const onCancel = () => {
    setOpen(false);
  }
  const goToNext = () => {
    dispatch(PostJobActions.resetFormData());
    handleNext();
  }
  const tabs = () => {
    return [
      <SubNavTab key="my-template" label={t("component_template_title_my")} />,
      <SubNavTab key="company-template" label={t("component_template_title_company")} />,
    ];
  };
  return (
    <>
      <Grid item xs={12} sm={12} md={12} sx={{ my: 2 }}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <h3>{t("page_post_job_template_post_new_job")}</h3>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              className={classes.continueButton}
              size="small"
              onClick={goToNext}
              label={t("page_post_job_template_button_create")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <SubNav value={value} onChange={handleChangeTab} tabs={tabs()} />
        {value === 0 && <MyJobTemplate history={history} viewDetail={viewDetail} />}
        {value === 1 && <CompanyJobTemplate viewDetail={viewDetail} />}
      </Grid>
      <Dialog
        open={open}
        title={detail && detail.templateName}
        onClose={onCancel}
        onCancel={onCancel}
        alertType={'form'}
        hideActions
        size="sm"
        modalType="scrollable"
      >
        <ViewTemplateDetail detail={detail} onCancel={onCancel} onConfirm={onConfirm} />
      </Dialog>
    </>
  );
}

export default Template;
