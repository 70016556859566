export const getWorkTypeList = (state) => {
  return state.postJob.workTypeList;
};

export const getGearList = (state) => {
  return state.postJob.gearList;
};

export const getSkillList = (state) => {
  return state.postJob.skillList;
};

// Postjob selectors
export const getPostJobState = field => state => state.postJob[field];
export const getCanTemplateSelected = getPostJobState("canTemplateSelected");


// Loading selectors
export const getLoadingStateFactory = loader => state => {
  return state.postJob.loading[loader] || false;
};

export const getIsPageLoading = getLoadingStateFactory("page");
export const getIsPostJobLoading = getLoadingStateFactory("postJob");

// Dialog selectors
export const getDialogStateFactory = loader => state => {
  return state.postJob.dialog[loader] || false;
};

export const getIsProfileDialog = getDialogStateFactory("profileDialog");
export const getIsCompanyDialog = getDialogStateFactory("companyDialog");
export const getIsBillingInfoDialog = getDialogStateFactory("billingInfoDialog");
export const getIsPostJobDialog = getDialogStateFactory("postJobDialog");

// Form selectors
export const getFormStateFactory = field => state => {
  return state.postJob.form[field];
};

export const getWorkType = getFormStateFactory("workType");
export const getRequiredGear = getFormStateFactory("requiredGear");
export const getSkills = getFormStateFactory("skills");
export const getDescription = getFormStateFactory("description");
export const getAddress = getFormStateFactory("address");
export const getUnit = getFormStateFactory("unit");
export const getFloor = getFormStateFactory("floor");
export const getLocationInstruction = getFormStateFactory("locationInstruction");
export const getDate = getFormStateFactory("date");
export const getStartTime = getFormStateFactory("startTime");
export const getEndTime = getFormStateFactory("endTime");
export const getBreakDuration = getFormStateFactory("breakDuration");
export const getBreakType = getFormStateFactory("breakType");
export const getNoOfWorkers = getFormStateFactory("noOfWorkers");
export const getSiteManager = getFormStateFactory("siteManager");
export const getCheckInManager = getFormStateFactory("checkInManager");
export const getCheckOutManager = getFormStateFactory("checkOutManager");
export const getAnnouncement = getFormStateFactory("announcement");
export const getIsPrivateJob = getFormStateFactory("isPrivate");
export const getTags = getFormStateFactory("tags");
export const getIsFixedTerm = getFormStateFactory("isFixedTerm");

export const getPostJobFormData = (state) => {
  return state.postJob.form;
};

export const getCalculatedJobBill = (state) => {
  return state.postJob.calculatedJobBill;
};

