import ApolloClient from "utils/apollo";
import history from "utils/history";
import { mobileValidator, verifyEmail } from "utils/stringUtils";
import { actions as MetaActions } from "store/modules/meta";
import { fetchProfile } from "../profile/actions";
import CheckUserExistQuery from "./graphql/queries/check-user-exist";
import FetchMeQuery from "./graphql/queries/fetch-me";
import FetchTimezoneQuery from "./graphql/queries/fetch-timezone";
import types from "./types";
import i18n from "i18n";
import Session from 'supertokens-web-js/recipe/session';
import { UserRoleClaim, PermissionClaim } from 'supertokens-web-js/recipe/userroles'
import { consumeCode, createCode } from 'supertokens-web-js/recipe/passwordless';
import FetchGoogleMapApiQuery from "./graphql/queries/fetch-google-map-key";

const setLoadingState = key => value => ({
  type: types.SET_LOADING_STATE,
  payload: { key, value },
});
const setIsLoginLoading = setLoadingState("login");
const setIsOtpLoading = setLoadingState("otp");
const script = document.createElement("script");
const loadMap = (key,dispatch)=>{
  dispatch(setGoogleMapApiKey(key));
  script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`;
  document.head.appendChild(script);
  script.onload = ((e) => {
      document.head.removeChild(script);
  });
};

export const resetForm = () => ({ type: types.RESET });
export const forgetUser = () => ({ type: types.FORGET_USER });
export const clearCredentials = () => ({ type: types.CLEAR_CREDENTIALS });
export const setCredentials = auth => ({ type: types.SET_CREDENTIALS, payload: { auth } });
export const setMe = me => ({ type: types.SET_ME, payload: { me } });
export const setEmail = email => ({ type: types.SET_LOGIN_EMAIL, payload: { email } });
export const setIsLoggingOut = isLoggingOut => ({ type: types.SET_IS_LOGGING_OUT, payload: { isLoggingOut } });
export const setOTP = otp => ({ type: types.SET_OTP, payload: { otp } });
export const setIsUsernameVerify = isUsernameVerify => ({
  type: types.SET_IS_USERNAME_VERIFY,
  payload: { isUsernameVerify },
});
export const setGoogleMapApiKey = googleMapApiKey => ({ type: types.SET_GOOGLE_MAP_API_KEY, payload: { googleMapApiKey } });

export const setIsCheckingSession = isCheckingSession => ({
  type: types.IS_CHECKING_SESSION,
  payload: { isCheckingSession },
});

const handleAuth0Error = dispatch => e => {
  if (e.error === "login_required") {
    history.replace("/");
    return;
  }

  let message = i18n.t("toast_errors_login_error_with_desc", { error: e, description: "" });
  if (e.error && (e.errorDescription || e.error_description)) {
    const description = e.errorDescription ? e.errorDescription : e.error_description;
    message = i18n.t("toast_errors_login_error_with_desc", { error: e.error, description });;
  }
  if(e && e.error)
    dispatch(MetaActions.errorToast(message));
};

export const checkSession = () => dispatch => {
  return new Promise((resolve, reject) => {
    Session.attemptRefreshingSession().then(res => {
      resolve(res);
      return res;
    }).catch(er => reject(er));
  })
    .then(async res => {
      if (res) {
        const userId = await Session.getUserId();
        const roles = await Session.getClaimValue({ claim: UserRoleClaim });
        const permissions = await Session.getClaimValue({ claim: PermissionClaim });
        dispatch(setCredentials({ permissions, roles, userId }));
        return ApolloClient.query({
          query: FetchMeQuery,
          variables: { authId: userId },
        });
      }
    })
    .then(async (res) => {
      if (res) {
        const roles = await Session.getClaimValue({ claim: UserRoleClaim });
        const permissions = await Session.getClaimValue({ claim: PermissionClaim });
        if (!roles.length || !permissions.length) {
          await dispatch(logout());
          dispatch(MetaActions.errorToast(i18n.t("forced_logout_error")));
          return;
        }
        const { data } = res;
        if (data && data.me && data.me.accountId && (data.me.accountType === "employer" || data.me.accountType === "employermanager")) {
          if (!script.src) {
            try {
              const res = await ApolloClient.query({ query: FetchGoogleMapApiQuery, variables: { platFormWeb: "HIREWEB" } });
              if (res && res.data) {
                loadMap(res.data.getGoogleMapKey,dispatch)
              }else{
                loadMap(process.env.REACT_APP_MAP_KEY,dispatch)
              }
            } catch (er) {
              console.log(er, "er")
            }
          }
          dispatch(setMe(data.me));
          dispatch(fetchProfile());
        } else {
          history.replace("/");
          // dispatch(MetaActions.errorToast(i18n.t("toast_errors_login_notauthorized")));
          dispatch(logout())
        }
      }
    })
    .catch(handleAuth0Error(dispatch));
};

export const handleAuthentication = () => dispatch => {
  return new Promise((resolve, reject) => {
    Session.attemptRefreshingSession().then(res => {
      resolve(res);
      return res;
    }).catch(er => reject(er));
  })
    .then(async res => {
      if (res) {
        const userId = await Session.getUserId();
        const roles = await Session.getClaimValue({ claim: UserRoleClaim });
        const permissions = await Session.getClaimValue({ claim: PermissionClaim });
        dispatch(setCredentials({ permissions, roles, userId }));

        return ApolloClient.query({
          query: FetchMeQuery,
          variables: { authId: userId },
        });
      }
      else {
        return dispatch(MetaActions.infoToast("Not Authorized!"));
      }
    })
    .then(async ({ data: { me } }) => {
      if (me) {
        const roles = await Session.getClaimValue({ claim: UserRoleClaim });
        const permissions = await Session.getClaimValue({ claim: PermissionClaim });
        if (!roles.length || !permissions.length) {
          await dispatch(logout());
          dispatch(MetaActions.errorToast(i18n.t("forced_logout_error")));
          return;
        }
      }
      if (me.accountId && (me.accountType === "employer" || me.accountType === "employermanager")) {
        history.replace("/");
        dispatch(checkSession());
      } else {
        history.replace("/");
        dispatch(MetaActions.errorToast(i18n.t("toast_errors_login_notauthorized")));
        dispatch(logout())
      }
    })
    .catch(e => {
      let message = i18n.t("toast_errors_login_error_with_desc", { error: e, description: "" });
      if (e.error && (e.errorDescription || e.error_description)) {
        const description = e.errorDescription ? e.errorDescription : e.error_description;
        message = i18n.t("toast_errors_login_error_with_desc", { error: e.error, description });
      }
      history.replace("/");
      dispatch(MetaActions.errorToast(message));
    });
};

export const login = (isResend = false) => (dispatch, getState) => {
  const { email } = getState().login;
  const actionPayload = verifyEmail(email) ? { email } : { phoneNumber: email };
  dispatch(setIsLoginLoading(true));

  if (verifyEmail(email)) {
    return ApolloClient.query({
      query: CheckUserExistQuery,
      variables: {
        email,
      },
    })
      .then(({ data, errors }) => {
        if ((!data || !data.employerOrManagerIsExists) && errors.length > 0) {
          throw new Error(errors[0].message);
        }
        if (data.employerOrManagerIsExists && data.employerOrManagerIsExists.isRemoved && data.employerOrManagerIsExists.userExist) {
          dispatch(MetaActions.errorToast(i18n.t("toast_errors_login_removed_account")));
          dispatch(setIsLoginLoading(false));
          dispatch(setEmail(""))
        } else if (data.employerOrManagerIsExists && data.employerOrManagerIsExists.isActive === false) {
          dispatch(MetaActions.errorToast(i18n.t("toast_errors_login_disable_or_not_activated")));
          dispatch(setIsLoginLoading(false));
          dispatch(setEmail(""))
        } else {
          if (data.employerOrManagerIsExists && data.employerOrManagerIsExists.userExist) {
            createCode(actionPayload).then(res => {
              if (res.status === "OK") {
                dispatch(setIsUsernameVerify(true));
                if (isResend) {
                  dispatch(MetaActions.successToast(i18n.t("toast_succecss_login_code_resend")));
                }
                dispatch(setIsLoginLoading(false));
              } else {
                dispatch(MetaActions.errorToast(i18n.t("toast_errors_login_invalid_email")));
              }
            }).catch(err => {
              if (err.isSuperTokensGeneralError === true) {
                dispatch(MetaActions.errorToast(err.message));
              } else {
                dispatch(MetaActions.errorToast(i18n.t("something_wrong_error")));
              }
            })
          } else {
            dispatch(MetaActions.errorToast(i18n.t("toast_errors_login_email_not_registred")));
            dispatch(setIsLoginLoading(false));
          }
        }
      })
      .catch(async e => {
        await dispatch(logout());
        dispatch(MetaActions.errorToast(i18n.t("forced_logout_error")));
        dispatch(setIsLoginLoading(false));
      });
  } else if (mobileValidator(email)) {
    return ApolloClient.query({
      query: CheckUserExistQuery,
      variables: {
        mobilePhone: email,
        countryCode: '+1'
      },
    })
      .then(({ data, errors }) => {
        if ((!data || !data.employerOrManagerIsExists) && errors.length > 0) {
          throw new Error(errors[0].message);
        }
        if (data.employerOrManagerIsExists && data.employerOrManagerIsExists.isRemoved && data.employerOrManagerIsExists.userExist) {
          dispatch(MetaActions.errorToast(i18n.t("toast_errors_login_removed_account")));
          dispatch(setIsLoginLoading(false));
          dispatch(setEmail(""))
        } else if (data.employerOrManagerIsExists && data.employerOrManagerIsExists.isActive === false) {
          dispatch(MetaActions.errorToast(i18n.t("toast_errors_login_disable_or_not_activated")));
          dispatch(setIsLoginLoading(false));
          dispatch(setEmail(""))
        } else {
          if (data.employerOrManagerIsExists && data.employerOrManagerIsExists.userExist) {
            createCode(actionPayload).then(res => {
              if (res.status === "OK") {
                dispatch(setIsUsernameVerify(true));
                if (isResend) {
                  dispatch(MetaActions.successToast(i18n.t("toast_succecss_login_code_resend")));
                }
                dispatch(setIsLoginLoading(false));
              } else {
                dispatch(MetaActions.errorToast(i18n.t("toast_errors_login_invalid_phone")));
              }
            }).catch(err => {
              if (err.isSuperTokensGeneralError === true) {
                dispatch(MetaActions.errorToast(err.message));
              } else {
                dispatch(MetaActions.errorToast(i18n.t("something_wrong_error")));
              }
            })
          } else {
            dispatch(MetaActions.errorToast(i18n.t("toast_errors_login_phone_not_registred")));
            dispatch(setIsLoginLoading(false));
          }
        }
      })
      .catch(async e => {
        await dispatch(logout());
        dispatch(MetaActions.errorToast(i18n.t("forced_logout_error")));
        dispatch(setIsLoginLoading(false));
      });
  } else {
    dispatch(MetaActions.errorToast(i18n.t("toast_errors_login_invalid_email_phone")));
    dispatch(setIsLoginLoading(false));
    return;
  }
};

export const submitOTP = () => (dispatch, getState) => {
  const { otp } = getState().login;
  dispatch(setIsOtpLoading(true));
  consumeCode({ userInputCode: otp }).then(response => {
    if (response.status === "OK") {
      if (response.createdNewUser) {
        // user sign up success
        window.location.href = "/";
      } else {
        // user sign in success
        window.location.href = "/";
      }
    } else if (response.status === "INCORRECT_USER_INPUT_CODE_ERROR") {
      dispatch(setOTP(''));
      dispatch(MetaActions.errorToast(i18n.t("wrong_otp", { remaining: response.maximumCodeInputAttempts - response.failedCodeInputAttemptCount })));
    } else if (response.status === "EXPIRED_USER_INPUT_CODE_ERROR") {
      dispatch(setOTP(''));
      dispatch(MetaActions.errorToast(i18n.t("old_otp_error")));
    } else {
      dispatch(setOTP(''));
      dispatch(MetaActions.errorToast(i18n.t("something_wrong_error")));
    }
    dispatch(setIsOtpLoading(false));
  }).catch(err => {
    if (err.isSuperTokensGeneralError === true) {
      dispatch(MetaActions.errorToast(err.message));
    } else {
      dispatch(MetaActions.errorToast(i18n.t("something_wrong_error")));
    }
    dispatch(setOTP(''));
    dispatch(setIsOtpLoading(false));
  });
};

export const logout = () => async dispatch => {
  dispatch(setIsLoggingOut(true));
  await Session.signOut();
  var c = document.cookie.split("; ");
  for (let i in c)
    document.cookie = /^[^=]+/.exec(c[i])[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  window.localStorage.setItem("logout", Date.now());
  window.localStorage.removeItem("persist:root");
  window.localStorage.removeItem("persist:subscriptions");
  sessionStorage.clear();
  setTimeout(() => {
    dispatch(resetForm());
    dispatch(setIsLoggingOut(false));
  }, 5000);
};

export const setTimezoneList = () => dispatch =>
  ApolloClient.query({ query: FetchTimezoneQuery })
    .then(({ data }) => dispatch({ type: types.SET_TIMEZONE_LIST, payload: data.timezones }))
    .catch(e => console.log(`getTimezoneList error: ${e}`));
