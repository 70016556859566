import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "components/Dialog/Dialog";
import { useTranslation } from "react-i18next";

function DisableDialog(props) {
  const { open, onClose, onCancel, onConfirm, fullName } = props;
  const [disableReason, setDisableReason] = useState();
  const { t } = useTranslation();
  const onChange = event => {
    if (event.target.value.length <= 100) {
      setDisableReason(event.target.value);
    }
  };

  const handleDisable = () => {
    onConfirm(disableReason);
    setDisableReason("");
  };

  return (
    <Dialog
      open={open}
      title={t(`common_labels_disable_worker`, { name: fullName })}
      onClose={onClose}
      onConfirm={handleDisable}
      onCancel={onCancel}
      alertType="warning"
      icon="not_interested"
      disabled={!disableReason}>
      <TextField
        fullWidth
        placeholder={t("common_labels_reason_placeholder")}
        value={disableReason}
        onChange={onChange}
        margin="normal" />
    </Dialog>
  );
}

export default DisableDialog;
