import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Alarm, AlarmOn, MoreVert, Stars, PlusOne } from "@mui/icons-material";
import { Grid, IconButton, Menu, MenuItem } from "@mui/material";
import Icon from "@mui/material/Icon";
import { makeStyles } from "@mui/styles";
import noop from "lodash/noop";
import Button from "components/Button/Button";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import { useTranslation } from "react-i18next";

// import ExposurePlus1 from '@mui/icons-material/ExposurePlus1'
// import ExposureNeg1 from '@mui/icons-material/ExposureNeg1'

const useStyles = makeStyles(({ spacing, typography }) => ({
  icon: {
    fontSize: typography.pxToRem(20),
    marginRight: spacing(2),
    color: '#6e7a8a'
  },
}));

function PositionTableMenu(props) {
  const {
    isEditing,
    // triggerRateAll,
    triggerCheckInAll,
    triggerCheckOutAll,
    triggerIncreasePositions,
    triggerDecreasePositions,
    onEditCancel,
    onEditSave,
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const menuId = "batch-position-action-menu";

  const openMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const menuItemClickFactory = (triggerFn = noop) => () => {
    closeMenu();
    triggerFn();
  };

  // const canRateAll = useSelector(JobDetailSelectors.getCanRateAll);
  const canCheckInAll = useSelector(JobDetailSelectors.getCanCheckInAll);
  const canCheckOutAll = useSelector(JobDetailSelectors.getCanCheckOutAll);
  const canIncreasePositions = useSelector(JobDetailSelectors.getCanIncreasePositions);
  const canDecreasePositions = useSelector(JobDetailSelectors.getCanDecreasePositions);
  const isFixedTerm = useSelector(JobDetailSelectors.getIsFixedTermJob);

  // return null if there are no actions available
  if (
    [
      // canRateAll,
      canCheckInAll,
      canCheckOutAll,
      canIncreasePositions,
      canDecreasePositions,
    ].every(v => !v)
  ) {
    return null;
  }

  // const handleRateAll = menuItemClickFactory(triggerRateAll);
  const handleCheckInAll = menuItemClickFactory(triggerCheckInAll);
  const handleCheckOutAll = menuItemClickFactory(triggerCheckOutAll);
  const handleIncreasePositions = menuItemClickFactory(triggerIncreasePositions);
  const handleDecreasePositions = menuItemClickFactory(triggerDecreasePositions);

  return (
    <>
      {isEditing ? (
        <>
          <Grid container direction="row" justifyContent={'end'}>
            <Grid item>
              <Button label={t("common_labels_cancel")} size={'small'} onClick={onEditCancel} color="transparent" />
            </Grid>
            <Grid item>
              <Button label={t("common_labels_save")} size={'small'} onClick={onEditSave} />
            </Grid>
          </Grid>
        </>
      ) : (
        (!isFixedTerm || (canCheckInAll || canCheckOutAll)) && <IconButton aria-controls={menuId} aria-haspopup="true" onClick={openMenu} disableRipple>
          <MoreVert color="primary" />
        </IconButton>
      )}
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}>
        {/* {isBaseJob && canRateAll && (
          <MenuItem onClick={handleRateAll}>
            <Stars className={classes.icon} color="action" />
            {t("page_job_details_all_rate")}
          </MenuItem>
        )} */}
        {canCheckInAll && (
          <MenuItem onClick={handleCheckInAll}>
            <Alarm className={classes.icon} color="action" />
            {t("page_job_details_all_check_in")}
          </MenuItem>
        )}
        {canCheckOutAll && (
          <MenuItem onClick={handleCheckOutAll}>
            <AlarmOn className={classes.icon} color="action" />
            {t("page_job_details_all_check_out")}
          </MenuItem>
        )}
        {!isFixedTerm && canIncreasePositions && (
          <MenuItem onClick={handleIncreasePositions}>
            <PlusOne className={classes.icon} color="action" />
            {t("page_job_details_all_positions_increase")}
          </MenuItem>
        )}
        {!isFixedTerm && canDecreasePositions && (
          <MenuItem onClick={handleDecreasePositions}>
            <Icon className={classes.icon}>exposure_neg_1</Icon>
            {t("page_job_details_all_positions_decrease")}
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

PositionTableMenu.propTypes = {
  // triggerRateAll: PropTypes.func.isRequired,
  triggerCheckInAll: PropTypes.func.isRequired,
  triggerCheckOutAll: PropTypes.func.isRequired,
  triggerIncreasePositions: PropTypes.func.isRequired,
  triggerDecreasePositions: PropTypes.func.isRequired,
};

export default PositionTableMenu;
