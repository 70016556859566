import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Dialog from "components/Dialog/Dialog";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import { useTranslation } from "react-i18next";

function PreferDialog(props) {
    const { open, onClose, onCancel, onConfirm, position } = props;
    const companyName = useSelector(JobDetailSelectors.getCompanyName);
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            title={t("page_job_details_dialog_prefer_title",{name:position.fullName})}
            description={t("page_job_details_dialog_prefer_desc",{name:position.fullName,companyName})}
            onClose={onClose}
            onConfirm={onConfirm}
            onCancel={onCancel}
            alertType="question"
            icon="person_add"
        />
    );
}

PreferDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    position: PropTypes.object,
};

export default PreferDialog;
