import React from "react";
import Typography from "@mui/material/Typography";
import Line from "components/Placeholders/Line";
import DisableCellOverlay from "../DisableCellOverlay";
import { useTranslation } from "react-i18next";

export default function useName(disabled, isRatingAll = false) {
  const { t } = useTranslation();

  return {
    Header: t("common_table_column_worker"),
    id: "fullName",
    styles: {
      minWidth: '80px'
    },
    sortable: !isRatingAll,
    Cell: ({ row: { original: { fullName } } }) => {
      return (
        <>
          {fullName ? (
            <Typography variant="body1">{fullName}</Typography>
          ) : (
            <Line />
          )}
          <DisableCellOverlay visible={disabled} />
        </>
      );
    },
  };
}
