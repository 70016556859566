import { Campaign, Block, FavoriteSharp, Group, Work, Settings, Chat } from "@mui/icons-material";
import ChatSupport from "views/ChatSupport";
import Communication from "views/Communication";
import JobDetails from "views/Job/JobDetails/JobDetails";
import JobOverview from "views/Job/JobOverview/JobOverview";
import PostJob from "views/Job/PostJob";
import Logout from "views/Login/Logout";
import Managers from "views/Managers";
import Profile from "views/Profile";
import Setting from "views/Settings";
import EditJobTemplate from "views/Settings/EditJobTemplate";
import BanWorkers from "views/Worker/BanWorkers";
import PreferredWorkers from "views/Worker/PreferredWorkers";
import MultiShiftJobDetails from "views/Job/MultiShiftJob/MultiShiftJobDetails";

const newDashRoutes = [
  //   {
  //     path: "/finance/payments/batch/:id",
  //     name: "Batch Details",
  //     component: BatchDetails,
  //     hidden: true,
  //     backButton: true,
  //     permission: "finance:view:base",
  //   },
  {
    path: "/profile",
    name: "Profile",
    hidden: true,
    component: Profile,
    translationKey: "profile"
  },
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: Home,
  //   component: Dashboard,
  // },
  {
    path: "/jobs",
    name: "Jobs",
    icon: Work,
    // component: JobOverview,
    // permission: "job:read:list",
    translationKey: "jobs",
    collapse: true,
    views: [
      {
        name: "Gig",
        path: "/jobs/gig/overview",
        // permission: "job:read:list",
        translationKey: "dayWork",
        component: JobOverview,
      },
      {
        name: "Multi Shift",
        path: "/jobs/multi-shift/overview",
        // permission: "job:read:list",
        translationKey: "fixedTerm",
        component: JobOverview,
      },
    ],
  },
  {
    path: "/jobs/post",
    name: "Post Job",
    state: "postJob",
    component: PostJob,
    hidden: true,
    backButton: true,
    translationKey: "postJob"
    // permission: "job:create:new",
  },
  {
    path: "/jobs/:id/dashboard",
    name: "Multi Shift Details",
    state: "viewJobDetails",
    component: MultiShiftJobDetails,
    hidden: true,
    backButton: true,
    translationKey: "jobDetails_multi_shift"
  },
  {
    path: "/jobs/:id",
    name: "Job Details",
    state: "viewJobDetails",
    component: JobDetails,
    hidden: true,
    backButton: true,
    translationKey: "jobDetails"
    // permission: "job:view:detail",
  },
  {
    path: "/communication",
    name: "Communication",
    icon: Campaign,
    component: Communication,
    translationKey: "communication",
  },
  {
    path: "/worker-banned",
    name: "Banned Worker",
    icon: Block,
    component: BanWorkers,
    translationKey: "bannedWorker",
  },
  {
    path: "/worker-preferred",
    name: "Preferred Worker",
    icon: FavoriteSharp,
    component: PreferredWorkers,
    translationKey: "preferredWorker",
  },
  {
    path: "/managers",
    name: "Managers",
    icon: Group,
    component: Managers,
    permission: "manager:view:list",
    translationKey: "managers",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: Settings,
    component: Setting,
    translationKey: "settings",
    // permission: "settings:view:base",
  },
  {
    path: "/edit-template",
    name: "Edit Template",
    hidden: true,
    backButton: true,
    component: EditJobTemplate,
    translationKey: "editTemplate",
    // permission: "settings:view:base",
  },
  {
    path: "/duplicate-template",
    name: "Duplicate Template",
    hidden: true,
    backButton: true,
    component: EditJobTemplate,
    permission: "settings:view:base",
    translationKey: "duplicateTemplate",
  },
  {
    path: "/logout",
    name: "Logout",
    state: "logout",
    hidden: true,
    component: Logout,
    translationKey: "logout",
  },
  // {
  //   path: "/chat-with-us",
  //   name: "Chat with us",
  //   icon: Chat,
  //   component: ChatSupport,
  //   translationKey: "chatWithUs",
  // },
  { redirect: true, path: "/", pathTo: "/jobs/gig/overview", name: "Dashboard", translationKey: "dashboard" },
];

export default newDashRoutes;