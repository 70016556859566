import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Dialog from "components/Dialog/Dialog";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import { useTranslation } from "react-i18next";

function NoShowDialog(props) {
    const { open, onClose, onCancel, onConfirm, position } = props;
    const [showBanOption, setShowBanOption] = useState(false);
    const { t } = useTranslation();
    const companyName = useSelector(JobDetailSelectors.getCompanyName);
    const jobDetail = useSelector(JobDetailSelectors.getJobDetails);

    const handleNoShow = () => {
        setShowBanOption(true);
    };

    const handleNoShowOnly = () => {
        setShowBanOption(false);
        onConfirm(false);
    };

    const handleNoShowBan = () => {
        setShowBanOption(false);
        onConfirm(true);
    };

    return (
        <>
            <Dialog
                open={open && !showBanOption}
                title={t("page_job_details_dialog_no_show_title", { name: position.fullName })}
                description={t(jobDetail.isFixedTerm ? "page_job_details_dialog_no_show_desc" : "page_job_details_dialog_no_show_desc_with_suspand")}
                onClose={onClose}
                onConfirm={handleNoShow}
                onCancel={onCancel}
                alertType="warning"
                icon="event_busy"
            />
            <Dialog
                open={open && showBanOption}
                title={t("page_job_details_dialog_no_show_ban_title")}
                description={t("page_job_details_dialog_no_show_ban_desc", { name: position.fullName, companyName })}
                onClose={onClose}
                onConfirm={handleNoShowBan}
                onCancel={handleNoShowOnly}
                cancelText={t("page_job_details_dialog_no_show_ban_cancel")}
                alertType="warning"
                icon="not_interested"
            />
        </>
    );
}

NoShowDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    position: PropTypes.object,
};

export default NoShowDialog;
