import * as pagination from "utils/graphql-pagination";
import { createReducer } from "utils/redux";
import { paginationSettings } from "./constants";

import types from "./types";
const initialListState = {
  sort: [{id: 'firstName', desc: false}],
  filter: [],
  data: [],
};
const initialState = {
  bannedWorker: {
    ...initialListState
  },
  preferredWorker: {
    ...initialListState
  },
  ...pagination.initialState(paginationSettings),
};

const actionsMap = {

  [types.SET_BANNED_WORKER_LIST_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      bannedWorker: {
        ...state.bannedWorker,
        data,
      }
    };
  },

  [types.UPDATE_BANNED_WORKER_PAGE_SORT]: (state, { payload }) => {
    const { sort } = payload;
    return {
      ...state,
      bannedWorker: {
        ...state.bannedWorker,
        sort,
      },
    };
  },

  [types.UPDATE_BANNED_WORKER_PAGE_FILTER]: (state, { payload }) => {
    const { filter } = payload;
    return {
      ...state,
      bannedWorker: {
        ...state.bannedWorker,
        filter,
      },
    };
  },

  [types.SET_PREFERRED_WORKER_LIST_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      preferredWorker: {
        ...state.preferredWorker,
        data,
      }
    };
  },

  [types.UPDATE_PREFERRED_WORKER_PAGE_SORT]: (state, { payload }) => {
    const { sort } = payload;
    return {
      ...state,
      preferredWorker: {
        ...state.preferredWorker,
        sort,
      },
    };
  },

  [types.UPDATE_PREFERRED_WORKER_PAGE_FILTER]: (state, { payload }) => {
    const { filter } = payload;
    return {
      ...state,
      preferredWorker: {
        ...state.preferredWorker,
        filter,
      },
    };
  },

  ...pagination.createPaginationActions(paginationSettings),
};

export default createReducer(initialState, actionsMap);
