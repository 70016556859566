import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import { tabStyles } from "../../styles";
import TabDetail from "../TabDetail";
import FilledPage from "./FilledPage";
import TotalPage from "./TotalPage";
import UnFilledPage from "./UnFilledPage";
import { useTranslation } from "react-i18next";

export default function UpcomingTab(props) {
    const classes = tabStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tabClass = {
        root: classes.tabRoot,
        selected: classes.tabSelected,
    };
    const upcomingState = useSelector(JobSelectors.getListStats("upcoming"))

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        if (props.history.location.search) {
          const params = new URLSearchParams(props.history.location.search);
          params.set("timeframe", 2);
          params.set("listType", newValue);
          props.history.push(`${props.history.location.pathname}?${params.toString()}`);
        }
        setValue(newValue);
    };

    useEffect(() => {
        if (props.history.location.search) {
            const params = new URLSearchParams(props.history.location.search);
            const timeframe = parseInt(params.get("timeframe"), 10);
            const listType = parseInt(params.get("listType"), 10);
            if (!isNaN(listType) && timeframe === 2) {
                setValue(listType);
                handleChange('', listType);
            }
        } else {
            handleChange('', value);
        }
        dispatch(JobActions.fetchListStats("Upcoming"));

    }, [])

    return (
        <React.Fragment>
            <div style={{ display: "flex", marginBottom: '15px' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    classes={{ indicator: classes.tabIndicator }}
                    variant="scrollable"
                >
                    <Tab
                        label={
                            <TabDetail
                                title={t("page_job_overview_sub_tabs_total")}
                                headerClass={classes.tabHeader}
                                labelClass={classes.tabLabel}
                                {...upcomingState.total}
                            />
                        }
                        classes={tabClass}
                    />
                    <Tab
                        label={
                            <TabDetail
                                title={t("page_job_overview_sub_tabs_unfilled")}
                                headerClass={classes.tabHeader}
                                labelClass={classes.tabLabel}
                                {...upcomingState.unfilled}
                            />
                        }
                        classes={tabClass}
                    />
                    <Tab
                        label={
                            <TabDetail
                                title={t("page_job_overview_sub_tabs_filled")}
                                headerClass={classes.tabHeader}
                                labelClass={classes.tabLabel}
                                {...upcomingState.filled}
                            />
                        }
                        classes={tabClass}
                    />
                </Tabs>
            </div>
            <div className={classes.contentContainer}>
                <div>
                    {value === 0 && <TotalPage history={props.history} />}
                </div>
                <div>
                    {value === 1 && <UnFilledPage history={props.history} />}
                </div>
                <div>
                    {value === 2 && <FilledPage history={props.history} />}
                </div>
            </div>
        </React.Fragment>

    )
}
